import { Injectable } from '@angular/core';
import { ProductBase } from '../../shared/models/product-library/product-base.model';
import { environment } from 'src/environments/environment';
import { ProductDocument } from 'src/app/shared/models/product-document.model';
import { LocalStoreManager } from 'src/app/shared/services/local-store-manager.service';
import { ProductColumn } from 'src/app/product-library/models/product-column.model';
import { ProductVariant } from '../models/product-variant.model';

@Injectable({ providedIn: 'root' })
export class ProductHelperService {
  baseUrl = environment.apiUrl + 'Product';

  constructor(private localStorage: LocalStoreManager) {}

  getProductId(product: ProductBase): string {
    return product?.id;
  }

  getProductNo(product: ProductBase): string {
    return product?.freePLProductCodeNumber;
  }

  getProductName(product: ProductBase): string {
    return product?.productInformation?.productName;
  }

  getProductCategory(product: ProductBase): string {
    return product?.productInformation?.productCategory;
  }

  getArticleNumber(product: ProductBase): string {
    return product?.productInformation?.articleNumber;
  }

  getSerialNumber(product: ProductBase): string {
    return product?.productInformation?.serialNumber;
  }

  getISBN(product: ProductBase): string {
    return product?.productInformation?.isban;
  }

  getUPC(product: ProductBase): string {
    return product?.productInformation?.upc;
  }

  getGTIN(product: ProductBase): string {
    return product?.productInformation?.gtin;
  }

  getHSCode(product: ProductBase): string {
    return product?.productInformation?.hsCode;
  }

  getCOO(product: ProductBase): string {
    return product?.productInformation?.actualManufacturer?.countryName;
  }

  getLegalManufacturer(product: ProductBase): string {
    return product?.productInformation?.legalManufacturer?.companyName;
  }

  getActualManufacturer(product: ProductBase): string {
    return product?.productInformation?.actualManufacturer?.companyName;
  }

  getDangerousGoods(product: ProductBase): string {
    return product?.productInformation?.isRequiresDGHandling ? 'Yes' : 'No';
  }

  getImportTax(product: ProductBase): string {
    return product?.complianceInformation?.importTax;
  }

  getVAT(product: ProductBase): string {
    return product?.complianceInformation?.vat;
  }

  getCategoryClassification(product: ProductBase): string {
    return product?.complianceInformation?.categoryClassification;
  }

  getEntity(product: ProductBase): string {
    return product?.complianceInformation?.entity;
  }

  getSterility(product: ProductBase): string {
    return product?.complianceInformation?.sterility;
  }

  getRegulations(product: ProductBase): string {
    return product?.complianceInformation?.regulation;
  }

  getClassificationType(product: ProductBase): string {
    return product?.complianceInformation?.classificationType;
  }

  getCertificationType(product: ProductBase): string {
    return product?.complianceInformation?.certificationType;
  }

  getRequiredRegistration(product: ProductBase): string {
    return product?.complianceInformation?.requiresRegistration;
  }

  getRequiredApprovals(product: ProductBase): string {
    return product?.complianceInformation?.isRequiredApprovals
      ? 'EDA Approvals'
      : 'No';
  }

  getRequiredInspections(product: ProductBase): string {
    let allProductInspections: string[] =
      product?.complianceInformation?.requiredInspections;
    return allProductInspections?.join(', ');
  }

  getStatus(product: ProductBase): string {
    return product?.productStatus?.name;
  }

  getStatusColor(product: ProductBase): string {
    return product?.productStatus?.colorCode;
  }

  getProductDocuments(product: ProductBase): ProductDocument[] {
    return product?.productDocuments;
  }

  getEmployeeSelectedColumns(): ProductColumn[] {
    return this.localStorage.getData('productSelectedColumns');
  }

  setEmployeeSelectedColumns(employeeSelectedColumns: ProductColumn[]): void {
    this.localStorage.saveSyncedSessionData(
      employeeSelectedColumns,
      'productSelectedColumns'
    );
  }

  clearEmployeeSelectedColumns(): void {
    this.localStorage.deleteData('productSelectedColumns');
  }

  getProductDescription(product: ProductBase): string {
    return product?.productInformation?.productDescription;
  }

  getProductVariantsData(product: ProductBase): ProductVariant[] {
    return product?.productInformation?.variants;
  }

  getProductVariants(product: ProductBase): string {
    return product?.productInformation?.variants != null &&
      product?.productInformation?.variants?.length > 0
      ? 'Yes'
      : 'No';
  }
  getCreatedByFullName(product: ProductBase): string {
    return product?.createdBy?.fullName;
  }
}
