import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Supplier } from '../../models/supplier.model';
import { HelperService } from 'src/app/shared/services/helper.service';
import {
  CountryMaster,
  StateMaster
} from 'src/app/shared/models/country.model';
import {
  AlertService,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { Utilities } from 'src/app/shared/services/utilities';
import { BaseNamedEntity } from 'src/app/shared/models/shipment/base-named-entity.model';
import { SupplierStatus } from '../../models/supplier-status.model';
import { StatusesEnum } from '../../enums/statuses.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { SupplierService } from 'src/app/supplier/services/supplier.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SupplierServicesEnum } from '../../enums/supplier-services.enum';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';
import { ServiceTypes } from 'src/app/shared/models/shipment/service-types.enum';
import { DocumentService } from 'src/app/shared/services/document.service';
import { switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-add-or-edit-supplier',
  templateUrl: './add-or-edit-supplier.component.html',
  styleUrls: ['./add-or-edit-supplier.component.scss']
})
export class AddOrEditSupplierComponent implements OnInit {
  supplier: Supplier;
  supplierForm: UntypedFormGroup;
  countries: CountryMaster[] = [];
  cities: StateMaster[] = [];
  selectedCountry: CountryMaster;
  selectedCity: StateMaster;
  isSaving: boolean = false;
  types: BaseNamedEntity[] = [];
  services: BaseNamedEntity[] = [];
  statuses: SupplierStatus[] = [];
  servicesMaxLength: number = 20;
  logo: File = null;
  isLogoUploaded: boolean = false;
  selectedImage: string | ArrayBuffer;

  constructor(
    private fb: UntypedFormBuilder,
    private helperService: HelperService,
    private alertService: AlertService,
    private router: Router,
    private supplierService: SupplierService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private formsHelperService: FormsHelperService,
    private documentService: DocumentService
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(data => {
      this.supplier = data['supplier'];
    });

    this.initForm();
    this.getSupplierLookups();
    this.getCountries();

    this.supplier = this.supplier || ({} as Supplier);

    if (this.supplier.logo) {
      this.selectedImage = this.supplier.logo;
    }
  }

  initForm() {
    this.supplierForm = this.fb.group({
      name: [
        this.supplier?.name,
        [Validators.required, Validators.minLength(2), Validators.maxLength(30)]
      ],
      officialName: [
        this.supplier?.officialName,
        [Validators.required, Validators.maxLength(30)]
      ],
      registrationNumber: [
        this.supplier?.registrationNumber,
        [Validators.maxLength(30)]
      ],
      vatNumber: [this.supplier?.vatNumber, [Validators.maxLength(30)]],
      address: [this.supplier?.address, [Validators.maxLength(100)]],
      country: [this.supplier?.country],
      city: [this.supplier?.city],
      phoneNumber: [
        this.supplier?.phoneNumber,
        [Validators.pattern('^[0-9]+$'), Validators.maxLength(15)]
      ],
      email: [
        this.supplier?.email,
        [Validators.email, Validators.maxLength(30)]
      ],
      logo: [this.supplier?.logo],
      website: [this.supplier?.website],
      type: [this.supplier?.type, [Validators.required]],
      services: [this.supplier?.services, [Validators.required]],
      status: [this.supplier?.status, [Validators.required]]
    });
  }

  getCountries() {
    this.helperService.getCountries().subscribe(
      data => {
        this.countries = data;
        if (this.isEditRoute) {
          this.onChangeCountry();
        }
      },
      error => {
        this.alertService.showStickyMessage(
          'Error',
          `An error occured whilst retreiving a list of countries.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  onChangeCountry() {
    let savedCountry = this.supplierForm.get('country').value;
    this.selectedCountry = this.countries?.find(
      c => c.name === savedCountry?.name
    );

    if (this.selectedCountry == null) {
      this.supplierForm.get('country').reset();
      this.supplierForm.get('city').reset();
    } else {
      this.cities = this.selectedCountry.states;
      let savedCity = this.supplierForm?.get('city')?.value;
      let isCityIncluded =
        this.cities?.findIndex(c => c.name === savedCity?.name) != -1;

      if (!isCityIncluded) {
        this.supplierForm.get('city').setValue(null);
      }
    }
  }

  onChangeCity() {
    this.selectedCity = this.supplierForm.get('city').value;

    if (this.selectedCity == null) {
      this.supplierForm.get('city').reset();
    }
  }

  onCountrySearch(term: string, item: any): CountryMaster[] {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().includes(term);
  }

  onCitySearch(term: string, item: any): StateMaster[] {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().includes(term);
  }

  save(): void {
    this.supplierForm.markAllAsTouched();

    if (this.supplierForm.invalid || this.isSaving) {
      return;
    }

    this.isSaving = true;
    const formData = this.supplierForm.value;

    if (this.isEditRoute) {
      this.supplierService
        .updateSupplier(this.supplier?.id, formData)
        .pipe(
          switchMap(res => {
            this.supplier = res;
            this.supplierService.supplier = res;
            return this.uploadLogoAndGetUrl();
          })
        )
        .subscribe(
          () => {
            this.handleSaveResponse();
          },
          error => {
            this.isSaving = false;
          }
        );
    } else {
      this.supplierService
        .submitSupplierDetails(formData)
        .pipe(
          switchMap(res => {
            this.supplier = res;
            this.supplierService.supplier = res;
            return this.uploadLogoAndGetUrl();
          })
        )
        .subscribe(
          () => {
            this.handleSaveResponse();
          },
          error => {
            this.isSaving = false;
          }
        );
    }
  }

  private handleSaveResponse(): void {
    this.isSaving = false;
    this.alertService.success(
      this.isEditRoute
        ? 'Supplier updated successfully!'
        : 'Supplier created successfully!'
    );
    this.router.navigate(['/suppliers/' + this.supplier?.id + '/details']);
  }

  private uploadLogoAndGetUrl(): Observable<void> {
    if (!this.logo) {
      return of(null);
    }

    const formData = this.prepareFormData(this.logo);
    this.isSaving = true;

    return this.documentService.uploadSupplierLogo(formData).pipe(
      tap(data => {
        this.supplier.logo = data.documentPath;
        this.isLogoUploaded = true;
        this.isSaving = false;
      })
    );
  }

  getSupplierLookups(): void {
    this.supplierService.getSupplierLookups().subscribe(res => {
      this.types = res?.types;
      this.statuses = res?.statuses?.filter(
        item => item?.name != StatusesEnum.Deleted
      );
      this.services = res?.services
        ?.filter(item => item?.name !== ServiceTypes.AccountManagement)
        ?.map(service => {
          if (service.name === ServiceTypes.IOR) {
            return { ...service, name: SupplierServicesEnum.ImportOfRecord };
          } else if (service.name === ServiceTypes.EOR) {
            return { ...service, name: SupplierServicesEnum.ExportOfRecord };
          } else if (service.name === ServiceTypes.Trucking) {
            return { ...service, name: SupplierServicesEnum.PortTrucking };
          }
          return service;
        });
    });
  }
  get isEditRoute(): boolean {
    return this.router.url.includes('edit');
  }

  onUploadClick() {
    document.getElementById('logo').click();
  }

  onChangeLogo(event) {
    this.logo = event.target.files[0];

    if (!this.logo.type.match(`image.*|image/png|TIFF|PSD|SVG|PJP`)) {
      this.alertService.error(
        'File type not supported. Supported formats are: jpg, jpeg, png, tiff, psd, svg, bmp, webp, pjp.'
      );
      return;
    }

    if (this.logo.size / 1024 > 30) {
      this.alertService.error(
        'File size not supported. Supported size is up to 30KB.'
      );
      return;
    }

    this.formsHelperService.clearValidator(this.supplierForm, 'logo');
    this.isLogoUploaded = true;

    const reader = new FileReader();
    reader.onload = () => {
      this.selectedImage = reader.result;
    };
    reader.readAsDataURL(this.logo);
  }

  get currentCompany() {
    return parseInt(this.authService.currentUser.defaultCustomerCompany);
  }

  getSelectedServices(services: BaseNamedEntity[]): string {
    return services.map(service => service.name).join(', ');
  }

  isFormControlSelected(input: any, formControlName: string): boolean {
    return this.supplierForm.get(formControlName).value?.name === input;
  }

  prepareFormData(file: File): FormData {
    const formData = new FormData();
    formData.append('submittedFile', file);
    formData.append('supplierId', this.supplier?.id);
    return formData;
  }

  cancel() {
    if (this.isEditRoute) {
      this.router.navigate(['/suppliers/' + this.supplier.id + '/details']);
    } else {
      this.router.navigate(['/suppliers/']);
    }
  }
}
