import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApprovalProductTabColumns } from '../../../approvals/enums/approval-product-tab-columns';
import { ConfirmationDeletionComponent } from 'src/app/shared/components/confirmation-deletion/confirmation-deletion.component';
import { MatDialog } from '@angular/material/dialog';
import { ApprovalService } from 'src/app/create-approval/services/approval.service';
import { ApprovalHelperService } from '../../../approvals/services/approval-helper.service';
import { ApprovalProduct } from 'src/app/shared/models/approval-product.model';
import { ModuleName } from 'src/app/shared/models/enums/module-name.enum';
import { ProductsTableColumns } from 'src/app/product-registration/enums/products-table-columns.enum';
import { ProductDetails } from 'src/app/create-product-registration/models/product-details.model';
import { ProductRegistrationHelperService } from 'src/app/create-product-registration/service/product-registration-helper.service';
import { ProductRegistrationService } from 'src/app/product-registration/services/product-registration.service';

@Component({
  selector: 'app-products-list-tab',
  templateUrl: './products-list-tab.component.html',
  styleUrls: ['./products-list-tab.component.scss']
})
export class ProductsListTabComponent implements OnInit {
  @Input() approvalProducts: ApprovalProduct[];
  @Input() parentId: string;
  @Output() onDeleteProductChange: EventEmitter<string> = new EventEmitter<
    string
  >();
  @Input() isDisabled: boolean;
  @Input() moduleName: string;
  @Input() products: ProductDetails[];

  approvalProductTabColumns = ApprovalProductTabColumns;
  productsTableColumns = ProductsTableColumns;
  notesUpdate: {
    showInput: boolean;
    insertedNotes: string;
    productId: string;
  }[] = [];

  constructor(
    private dialog: MatDialog,
    private approvalService: ApprovalService,
    private approvalHelperService: ApprovalHelperService,
    private productRegistrationHelperService: ProductRegistrationHelperService,
    private productRegistrationService: ProductRegistrationService
  ) {}

  ngOnInit(): void {
    if (this.isProductRegistration) {
      this.initializeNotesUpdate();
    }
  }

  initializeNotesUpdate() {
    this.notesUpdate = this.products?.map(prod => ({
      showInput: false,
      insertedNotes: prod.notes,
      productId: prod.productId
    }));
  }

  getApprovalColumnContent(column: string, product: ApprovalProduct): string {
    switch (column) {
      case ApprovalProductTabColumns.ProductName:
        return this.approvalHelperService.getApprovalProductName(product);

      case ApprovalProductTabColumns.ProductDescription:
        return this.approvalHelperService.getApprovalProductDescription(
          product
        );

      case ApprovalProductTabColumns.Status:
        return this.approvalHelperService.getApprovalProductStatus(product)
          .name;

      default:
        break;
    }
  }

  getColumnContent(column: string, product): string {
    if (this.isApproval) {
      return this.getApprovalColumnContent(column, product);
    }
    return this.getProductRegistrationColumnContent(column, product);
  }

  getProductRegistrationColumnContent(
    column: string,
    product: ProductDetails
  ): string {
    switch (column) {
      case ProductsTableColumns.PartNumber:
        return this.productRegistrationHelperService.getProductRegistraitonProductPartNumber(
          product
        );
      case ProductsTableColumns.ProductDescription:
        return this.productRegistrationHelperService.getProductRegistrationProductDescription(
          product
        );
      case ProductsTableColumns.Documents:
        return this.productRegistrationHelperService.getProductRegistrationProductDocuments(
          product
        );
      case ProductsTableColumns.Notes:
        return this.productRegistrationHelperService.getProductRegistrationProductNotes(
          product
        );
      default:
        break;
    }
  }

  isTargetColumn(column: string, targetColumn): boolean {
    return column === targetColumn;
  }

  getApprovalProductStatusColor(product: ApprovalProduct): string {
    return this.approvalHelperService.getApprovalProductStatus(product)
      ?.colorCode;
  }

  deleteProduct(productId: string): void {
    this.dialog
      .open(ConfirmationDeletionComponent, {
        hasBackdrop: true,
        width: '600px',
        maxHeight: '680px',
        panelClass: 'custom-confirmation-action-pop',
        data: {
          moduleName: ModuleName.ProductLibrary,
          popUpTitle: 'Product Removal',
          popUpDescription: `You are about to delete this product from the approval Request. 
          Are you sure you want to continue?`,
          cancelButtonTitle: 'Keep Product'
        }
      })
      .afterClosed()
      .subscribe(isDelete => {
        if (isDelete) {
          this.approvalService
            .deleteProduct(this.parentId, productId)
            .subscribe(_ => {
              this.removeProductFromList(productId);
            });
        }
      });
  }

  removeProductFromList(productId: string): void {
    this.onDeleteProductChange.emit(productId);
  }

  toggleShowInput(index: number) {
    let isIndexExist = this.notesUpdate[index] !== undefined;

    if (isIndexExist) {
      this.notesUpdate[index].showInput = !this.notesUpdate[index].showInput;
    }
  }

  updateNotes(productId: string, index: number) {
    let productNotes = this.notesUpdate[index];
    if (productNotes === undefined) {
      return;
    }
    let product = this.products.find(prod => prod.productId === productId);
    let isNotesChanged = productNotes.insertedNotes !== product?.notes;
    if (
      (productNotes.insertedNotes !== '' ||
        productNotes.insertedNotes !== null) &&
      isNotesChanged
    ) {
      this.productRegistrationService
        .updateProductNotes(
          this.parentId,
          productId,
          this.notesUpdate[index].insertedNotes
        )
        .subscribe(_ => {
          product.notes = this.notesUpdate[index].insertedNotes;
          this.notesUpdate[index].showInput = false;
        });
    }
  }

  isVariantColumn(column): boolean {
    return (
      this.isProductRegistration &&
      !this.productRegistrationColumns.includes(column)
    );
  }

  get columns(): string[] {
    if (this.moduleName === ModuleName.Approval) {
      return this.approvalColumns;
    }
    return this.productRegistrationColumns;
  }

  get productRegistrationColumns(): string[] {
    return [
      ProductsTableColumns.PartNumber,
      ProductsTableColumns.ProductDescription,
      ProductsTableColumns.Documents,
      ProductsTableColumns.Notes,
      ProductsTableColumns.Actions
    ];
  }

  get approvalColumns(): string[] {
    return [
      ApprovalProductTabColumns.ProductName,
      ApprovalProductTabColumns.ProductDescription,
      ApprovalProductTabColumns.Status,
      ApprovalProductTabColumns.Actions
    ];
  }

  get isApproval(): boolean {
    return this.moduleName === ModuleName.Approval;
  }

  get isProductRegistration(): boolean {
    return this.moduleName === ModuleName.Registration;
  }

  get productsList() {
    if (this.isApproval) {
      return this.approvalProducts;
    }
    return this.products;
  }
}
