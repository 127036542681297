import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AccountService } from '../../services/account.service';
import { NotificationService } from '../../services/notification.service';
import { ProductRegistrationHelperService } from 'src/app/create-product-registration/service/product-registration-helper.service';
import { ShipmentHelpersService } from 'src/app/shipments/services/shipment-helpers.service';
import { ProductHelperService } from 'src/app/create-product/services/product-helper.service';
import { CompanyService } from 'src/app/company/services/company.service';
import { Company } from 'src/app/company/models/company.model';
import { CreateQuoteHelperService } from 'src/app/create-quote/services/create-quote-helper.service';

@Component({
  selector: 'app-white-nav-bar',
  templateUrl: './white-nav-bar.component.html',
  styleUrls: ['./white-nav-bar.component.scss']
})
export class WhiteNavBarComponent implements OnInit {
  defaultCompany: string;
  companies: Company[];
  defaultCompanyName: string;

  totalNumberOfCompanies = 0;
  pageIndex = 0;
  pageSize = 6;
  isCompaniesLoading = false;

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private shipmentHelpersService: ShipmentHelpersService,
    private productRegistrationHelperService: ProductRegistrationHelperService,
    private accountService: AccountService,
    private productHelperService: ProductHelperService,
    private companyService: CompanyService,
    private quoteHelperService: CreateQuoteHelperService
  ) {}

  ngOnInit(): void {
    if (!this.isAdmin) {
      this.defaultCompany = this.authService.currentUser.defaultCustomerCompany;

      if (this.canManageMedicalCompanies || this.canManageNonMedicalCompanies) {
        this.getFreePLEmployeeCustomerCompanies();
      }

      this.companyService.customerCompanySwitched.subscribe(data => {
        this.defaultCompany = data['companyId'];
        this.defaultCompanyName = this.companies.find(
          c => c.id.toString() == this.defaultCompany
        )?.name;

        if (!this.defaultCompanyName) {
          this.getCompanyById();
        }
      });
    }
  }

  getCompanyById() {
    this.companyService.getCompany(+this.defaultCompany).subscribe(company => {
      this.companies = [company, ...this.companies];
      this.defaultCompanyName = this.companies.find(
        c => c.id.toString() == this.defaultCompany
      )?.name;
    });
  }

  getFreePLEmployeeCustomerCompanies() {
    this.companyService
      .getFreePLEmployeeCustomerCompanies(
        this.authService.currentUser.employeeId,
        this.pageSize,
        this.pageIndex
      )
      .subscribe(data => {
        if (this.companies?.length > 0) {
          this.isCompaniesLoading = false;
          let items = data?.items?.filter(
            item => !this.companies.some(c => c.id == item.id)
          );
          this.companies = this.companies.concat(items);
          if (data?.items.length > 0) {
            this.addToCustomerCompanies(data?.items);
          }
        } else {
          this.companies = data?.items;
          this.totalNumberOfCompanies = data?.totalItems;
          this.defaultCompanyName = this.companies.find(
            c => c.id.toString() == this.defaultCompany
          )?.name;
          if (!this.defaultCompanyName) {
            this.getCompanyById();
          }
        }
      });
  }

  logout(): void {
    this.authService.logout();
    this.authService.redirectLogoutUser();
    this.notificationService.removeCachedNotifications();
    this.removeStoredFiltersData();
  }

  removeStoredFiltersData() {
    this.shipmentHelpersService.clearShipmentsStoredFiltersInBrowser();
    this.productRegistrationHelperService.clearEmployeeSelectedColumns();
    this.productHelperService.clearEmployeeSelectedColumns();
    this.quoteHelperService.clearEmployeeSelectedColumns();
  }

  onChangeDefaultCustomerCompany() {
    const companyId: number = this.currenctCompanyId;

    if (companyId && companyId > 0) {
      this.companyService
        .setDefaultCustomerCompanyForFreePLEmployee(
          this.authService.currentUser.employeeId,
          companyId.toString()
        )
        .subscribe(_ => {
          this.defaultCompany = companyId.toString();
          this.authService.updateDefaultCompanyDataForCurrentUser(
            this.defaultCompany,
            this.defaultIndustryName,
            true
          );
        });
    }
  }

  onScroll(event: any) {
    if (
      event?.target?.offsetHeight + event?.target?.scrollTop >=
        event?.target?.scrollHeight - 1 &&
      !this.hasGotAllCompanies &&
      !this.isCompaniesLoading
    ) {
      this.loadCompanies();
    }
  }

  loadCompanies() {
    this.isCompaniesLoading = true;
    this.pageIndex++;
    this.getFreePLEmployeeCustomerCompanies();
  }

  addToCustomerCompanies(customerCompanies: any[]) {
    let customerCompaniesIds = customerCompanies.map(c => c.id);
    this.authService.updateUserCustomerCompanies(customerCompaniesIds);
  }

  get hasGotAllCompanies(): boolean {
    let totalCompaniesRetrieved = (this.pageIndex + 1) * this.pageSize;
    return totalCompaniesRetrieved >= this.totalNumberOfCompanies;
  }

  get isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  get canManageMedicalCompanies(): boolean {
    return this.authService.canManageMedicalCompanies;
  }

  get canManageNonMedicalCompanies(): boolean {
    return this.authService.canManageNonMedicalCompanies;
  }

  get currenctCompanyId(): number {
    return this.companies?.find(i => i.name == this.defaultCompanyName)?.id;
  }

  get defaultIndustryName(): string {
    return this.companies?.find(i => i.name == this.defaultCompanyName)
      ?.industry?.name;
  }

  get companyLogo(): string {
    return this.companies?.find(i => i.name == this.defaultCompanyName)?.logo;
  }

  get isUserLoggedIn(): boolean {
    return this.authService.isLoggedIn;
  }

  get profilePictureUrl(): string {
    return this.accountService.currentUser.profilePictureUrl;
  }

  get employeeId(): string {
    return this.authService.currentUser.employeeId;
  }

  get canManageRegistrationAndApprovalServices(): boolean {
    return this.authService.canManageRegistrationAndApprovalServices;
  }

  get _notificatinService(): NotificationService {
    return this.notificationService;
  }

  get fullName(): string {
    return this.authService?.currentUser?.fullName || '';
  }
}
