import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Employee } from '../../company/models/employee.model';
import { FreePlService } from '../models/free-pl-service.model';
import { CountryMaster } from '../models/country.model';
import { RouteLookups } from '../../company/models/route-lookups.model';
import { ChargeType } from '../models/shipment/charge-type.model';
import { HSCode } from '../models/shipment/hsCodes.model';
import { CustomsClearanceRateLookups } from '../models/customs-clearance-rate-lookups.model';
import { ShipmentFilters } from '../models/shipment/shipment-filters.model';
import { Currency } from '../models/currency.model';
import { ProductClassification } from '../models/product-classification.model';
import { CommercialTransactionParty } from '../../company/models/commercial-transaction-party.model';
import { ContainerType } from '../models/container-type.model';
import { ServiceBundleLookups } from '../../company/models/service-bundle-lookups.model';
import { CompanyIdAndName } from '../models/company-id-and-name.model';
import { FreePLEmployee } from '../models/freePL-employee.model';
import { CargoType } from 'src/app/shared/models/cargo-type.model';
import { SelectedLoadType } from 'src/app/admin-portal-configs/models/selected-load-type';
import { CertificationType } from '../models/certification-type.model';
import { ClassificationType } from '../models/classification-type.model';
import { ProductRegistrationSystemStatus } from '../models/product-registration-system-status.model';
import { ProgressPoint } from '../models/progress-point.model';
import { ProductRegistrationStatus } from '../models/product-registration-status.model';
import { ProducFilters } from 'src/app/product-registration/models/produc-filters.model';
import { DomesticTruckingLookups } from '../models/domestic-trucking-lookups.model';
import { TruckType } from '../models/truck-type.model';
import { QuoteFilters } from '../models/quote-filters.model';
import { ProductLookups } from 'src/app/create-product/models/product-lookups.model';
import { BaseNamedEntity } from '../models/shipment/base-named-entity.model';
import { Incoterm } from '../models/incoterm.model';
import { ApprovalType } from 'src/app/approvals/models/approval-type.model';
import { ProductRegistrationLookups } from 'src/app/company2/models/product-registration-lookup.model';
import { Paginated } from '../models/shipment/paginated.model';
import { ChargeTypeLookups } from '../../admin-portal-configs/models/charge-type-lookups.model';
import { ProductDetailsRegistrationsAndApprovals } from '../../product-library/models/product-details-registrations-and-approvals';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  baseURL = environment.apiUrl + 'helper';

  constructor(private http: HttpClient) {}

  getServiceProviders(serviceId: any): Observable<any> {
    return this.http.get<CommercialTransactionParty[]>(
      `${this.baseURL}/ServiceProviders/${serviceId}`
    );
  }

  getServices(): Observable<any> {
    return this.http.get<FreePlService[]>(this.baseURL + '/services');
  }

  getAccountManagers(): Observable<any> {
    return this.http.get<Employee[]>(this.baseURL + '/AccountManagers');
  }

  getAccountManagersForAddingToComapny(companyId: number): Observable<any> {
    return this.http.get<Employee[]>(
      this.baseURL + `/AccountManagers/${companyId}`
    );
  }

  getPaginatedAccountManagersForAddingToComapny(
    companyId: number,
    searchTerm: string,
    pageIndex: number,
    pageSize: number
  ): Observable<Paginated> {
    let url = '';
    if (searchTerm) {
      url += `searchTerm=${searchTerm}&`;
    }
    url += `pageIndex=${pageIndex}&pageSize=${pageSize}`;

    return this.http.get<Paginated>(
      this.baseURL + `/PaginatedAccountManagers/${companyId}?${url}`
    );
  }

  getStates(id): Observable<any> {
    return this.http.get<CountryMaster[]>(this.baseURL + `/States/${id}`);
  }

  getRouteLookups(
    originCountryId?: number,
    destinationCountryId?: number,
    originCityId?: number,
    destinationCityId?: number
  ): Observable<RouteLookups> {
    return this.http.get<RouteLookups>(
      this.baseURL +
        `/Route/Lookups/${originCountryId}/${destinationCountryId}/${originCityId}/${destinationCityId}`
    );
  }
  getCustomsClearanceRateLookups(): Observable<CustomsClearanceRateLookups> {
    return this.http.get<CustomsClearanceRateLookups>(
      this.baseURL + '/CustomsClearanceRate/Lookups'
    );
  }

  getChargeTypes(): Observable<ChargeType[]> {
    return this.http.get<ChargeType[]>(this.baseURL + `/ChargeTypes`);
  }

  getCurrencies(): Observable<Currency[]> {
    return this.http.get<Currency[]>(this.baseURL + `/Currencies`);
  }

  getHSCodes(searchKeyword: string): Observable<HSCode[]> {
    return this.http.get<HSCode[]>(
      this.baseURL + `/SearchHSCodes?searchKeyword=${searchKeyword}`
    );
  }

  getShipmentFilters(): Observable<ShipmentFilters> {
    return this.http.get<ShipmentFilters>(this.baseURL + `/ShipmentFilters`);
  }

  getProductFilters(): Observable<ProducFilters> {
    return this.http.get<ProducFilters>(this.baseURL + `/ProductFilters`);
  }

  getProductClassifications(): Observable<ProductClassification[]> {
    return this.http.get<ProductClassification[]>(
      this.baseURL + `/product-classifications`
    );
  }

  getContainerTypes(): Observable<ContainerType[]> {
    return this.http.get<ContainerType[]>(this.baseURL + '/container-types');
  }

  getServiceBundleLookups(): Observable<ServiceBundleLookups> {
    return this.http.get<ServiceBundleLookups>(
      this.baseURL + '/ServiceBundle/Lookups'
    );
  }
  getRunners(companyId: number): Observable<Employee[]> {
    return this.http.get<Employee[]>(this.baseURL + `/runners/${companyId}`);
  }

  getCompanyContract(
    companyId: number,
    shipmentId: string,
    quoteId?: string
  ): Observable<any> {
    let url = this.baseURL + `/CompanyContract/${companyId}`;

    if (shipmentId) {
      url += `?shipmentId=${shipmentId}`;
    }

    if (quoteId && !shipmentId) {
      url += `?`;
    }
    if (quoteId) {
      url += `quoteId=${quoteId}`;
    }
    return this.http.get<any>(url);
  }

  getCompaniesByNameOrEmail(
    companyNameOrEmail: string
  ): Observable<CompanyIdAndName[]> {
    return this.http.get<CompanyIdAndName[]>(
      this.baseURL + `/CompanyNameOrEmail/${companyNameOrEmail}`
    );
  }

  getAccountManagersByNameOrEmail(
    accountManagerNameOrEmail: string,
    companyIds?: number[]
  ): Observable<FreePLEmployee[]> {
    let requestUrl = `/AccountManagerNameOrEmail/${accountManagerNameOrEmail}`;

    for (let index = 0; index < companyIds?.length; index++) {
      if (index === 0) {
        requestUrl += `?companyIds=`;
      } else {
        requestUrl += `&companyIds=`;
      }

      requestUrl += `${companyIds[index]}`;
    }

    return this.http.get<FreePLEmployee[]>(this.baseURL + requestUrl);
  }

  getShipmentSubmitterByNameOrEmail(
    shipmentSubmitterNameOrEmail: string,
    companyIds?: number[]
  ): Observable<FreePLEmployee[]> {
    let requestUrl = `/ShipmentSubmitterNameOrEmail/${shipmentSubmitterNameOrEmail}`;

    for (let index = 0; index < companyIds?.length; index++) {
      if (index == 0) {
        requestUrl += `?companyIds=`;
      } else {
        requestUrl += `&companyIds=`;
      }

      requestUrl += `${companyIds[index]}`;
    }

    return this.http.get<FreePLEmployee[]>(this.baseURL + requestUrl);
  }
  getLoadTypes(): Observable<SelectedLoadType[]> {
    return this.http.get<SelectedLoadType[]>(this.baseURL + '/LoadType');
  }

  getCargoTypes(): Observable<CargoType[]> {
    return this.http.get<CargoType[]>(this.baseURL + '/CargoType');
  }

  getClassificationAndCertificationTypes(): Observable<{
    classificationTypes: ClassificationType[];
    certificationTypes: CertificationType[];
  }> {
    return this.http.get<{
      classificationTypes: ClassificationType[];
      certificationTypes: CertificationType[];
    }>(this.baseURL + '/ClassificationAndCertificationType');
  }

  getProductRegistrationSystemStatuses(): Observable<
    ProductRegistrationSystemStatus[]
  > {
    return this.http.get<ProductRegistrationSystemStatus[]>(
      this.baseURL + '/ProductRegistrationSystemStatus'
    );
  }

  getProductRegistrationProgressPoints(): Observable<ProgressPoint[]> {
    return this.http.get<ProgressPoint[]>(this.baseURL + '/ProgressPoint');
  }

  getProductRegistrationStatuses(): Observable<ProductRegistrationStatus[]> {
    return this.http.get<ProductRegistrationStatus[]>(
      this.baseURL + '/ProductRegistrationStatus'
    );
  }

  getDomesticTruckingLookups(
    isLandcompatible: boolean
  ): Observable<DomesticTruckingLookups> {
    return this.http.get<DomesticTruckingLookups>(
      this.baseURL + `/DomesticTrucking/${isLandcompatible}/Lookups`
    );
  }
  getCompatibleTruckTypes(
    loadTypeId: number,
    isLandcompatible: boolean
  ): Observable<TruckType[]> {
    return this.http.get<TruckType[]>(
      this.baseURL + `/loadType/${loadTypeId}/TruckType/${isLandcompatible}`
    );
  }

  getQuoteFilters(): Observable<QuoteFilters> {
    return this.http.get<QuoteFilters>(this.baseURL + `/QuoteFilter`);
  }
  getShipmentStatuses(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + '/ShipmentStatue');
  }

  getProductLookups(): Observable<ProductLookups> {
    const url = `${this.baseURL}/ProductLookups`;
    return this.http.get<ProductLookups>(url);
  }

  getNotificationTypeCategories(): Observable<BaseNamedEntity[]> {
    return this.http.get<BaseNamedEntity[]>(
      this.baseURL + `/NotificationTypeCategory`
    );
  }

  getIncoTerms(isOcean: boolean, isAir: boolean): Observable<Incoterm[]> {
    return this.http.get<Incoterm[]>(
      this.baseURL + `/IncoTerm?isOcean=${isOcean}&isAir=${isAir}`
    );
  }

  getApprovalLookups(): Observable<ApprovalType[]> {
    return this.http.get<ApprovalType[]>(this.baseURL + `/ApprovalLookups`);
  }

  getProductRegistrationLookups(): Observable<ProductRegistrationLookups[]> {
    return this.http.get<ProductRegistrationLookups[]>(
      this.baseURL + `/ProductRegistrationLookups`
    );
  }

  getChargeTypeLookups(): Observable<ChargeTypeLookups> {
    return this.http.get<ChargeTypeLookups>(
      this.baseURL + `/ChargeTypeLookups`
    );
  }

  getProductReferences(
    productId: string
  ): Observable<ProductDetailsRegistrationsAndApprovals> {
    return this.http.get<ProductDetailsRegistrationsAndApprovals>(
      this.baseURL + `/ProductReference/${productId}`
    );
  }
}
