import { Component, OnInit } from '@angular/core';
import { NonAdminSidebarEnum } from '../../models/enums/non-admin-sidebar.enum';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-non-admin-sidebar',
  templateUrl: './non-admin-sidebar.component.html',
  styleUrls: ['./non-admin-sidebar.component.scss']
})
export class NonAdminSidebarComponent implements OnInit {
  nonAdminSidebarEnum = NonAdminSidebarEnum;

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit() {}

  isSelectedTab(tab): boolean {
    return this.router.url.includes(tab);
  }

  get canCreateShipment(): boolean {
    return this.authService.canCreateShipment;
  }

  get companyId(): string {
    return this.authService.currentUser.defaultCustomerCompany;
  }

  get canViewQuotes(): boolean {
    return this.authService.canViewQuotes;
  }

  get canCreateQuotes(): boolean {
    return this.authService.canCreateQuote;
  }

  get isMedicalOrPharmaceuticalIndustry(): boolean {
    return this.authService.isMedicalOrPharmaceuticalIndustry;
  }

  get canViewShipments(): boolean {
    return this.authService.canViewShipments;
  }

  get canViewShipmentsForSelectedCustomer(): boolean {
    return this.authService.canViewShipmentsForSelectedCustomer;
  }

  get currentShipmentRoute(): string {
    if (this.canViewShipmentsForSelectedCustomer) {
      return 'company/' + this.companyId + '/' + NonAdminSidebarEnum.Shipments;
    } else if (this.canViewShipments) {
      return '/' + NonAdminSidebarEnum.Shipments;
    }
  }
  get canManageForm4Requests(): boolean {
    return this.authService.canManageForm4Requests;
  }
}
