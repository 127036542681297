import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ShipmentsService } from 'src/app/shipments/shipments.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { MatDialog } from '@angular/material/dialog';
import { ShipmentStatusEnum } from '../../models/enums/shipment-status';
import { AuthService } from '../../services/auth.service';
import { SortFilterComponent } from '../sort-filter/sort-filter.component';
import { ShipmentsListFiltersComponent } from 'src/app/shipments/sub-components/shipments-list-filters/shipments-list-filters.component';
import { SearchCriteria } from '../../models/shipment/search-criteria.model';
import { CardViewEnum } from 'src/app/shipments/enums/card-view.enum';
import { TableViewColumnsFilterComponent } from 'src/app/shipments/sub-components/table-view-columns-filter/table-view-columns-filter.component';
import { ShipmentActorsViewFilterData } from 'src/app/shipments/models/shipment-actors-view-filter-data.model';
import { ModuleName } from '../../models/enums/module-name.enum';
import { ListFiltersComponent } from '../list-filters/list-filters.component';
import { ListFilters } from '../../models/list-filters.model';
import { ProductRegistrationService } from 'src/app/product-registration/services/product-registration.service';
import { BulkActionComponent } from '../bulk-action/bulk-action.component';
import { QuoteSearchCriteria } from 'src/app/quote/models/quote-search-criteria.model';
import { QuoteService } from 'src/app/quote/services/quote.service';
import { ViewColumn } from '../../models/view-column.model';
import { EntityType } from 'src/app/createShipment/models/enums/entity-type.enum';
import { ProductService } from 'src/app/product-library/services/product.service';
import { ProductRegistrationSearchCriteria } from 'src/app/product-registration/models/product-registration-search-criteria.model';
import { ApprovalService } from 'src/app/create-approval/services/approval.service';
import { CreateQuoteHelperService } from 'src/app/create-quote/services/create-quote-helper.service';

@Component({
  selector: 'app-g-search-bar',
  templateUrl: './g-search-bar.component.html',
  styleUrls: ['./g-search-bar.component.scss']
})
export class GSearchBarComponent implements OnInit {
  searchCriteria: any;
  isDialogFilterOpen: boolean = false;
  isDialogSortByOpen: boolean = false;
  isDialogManageColumnOpen: boolean = false;
  isDialogActionOpen: boolean = false;
  @Input() selectedCardView: CardViewEnum;
  cardViewEnum = CardViewEnum;
  shipmentActorsViewFilterData: ShipmentActorsViewFilterData;
  isCardsSelected: boolean = false;
  isAllSelected: boolean = false;
  isEmptyList: boolean = true;

  @Input() targerModule: string;
  @Input() columnsToSortBy: string[];
  @Input() isShowingDraftShipments: boolean = false;
  @Input() defaultColumnFilters: string[];
  @Input() columnsFilter: string[];
  @Input() hasItems: boolean;
  @Input() tableViewColumns: string[] = [];
  @Input() viewColumns: ViewColumn[];
  @Input() listFilters: ListFilters[];
  @ViewChild('filtersButton') filtersButton;
  @ViewChild('columnsToSortByButton') columnsToSortByButton;
  @ViewChild('columnsFilterButton') columnsFilterButton;
  @ViewChild('actionsButton') actionsButton;
  @Input() selectedViewColumns: ViewColumn[];
  @Output() listViewChange = new EventEmitter<CardViewEnum>();
  @Output() onSelectAllChange = new EventEmitter<boolean>();

  moduleName = ModuleName;
  entityType = EntityType;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private localStorage: LocalStoreManager,
    private shipmentsService: ShipmentsService,
    private productRegistrationService: ProductRegistrationService,
    private quoteService: QuoteService,
    private productService: ProductService,
    private approvalService: ApprovalService,
    private createQuoteHelperService: CreateQuoteHelperService
  ) {}

  ngOnInit(): void {
    this.listenToChanges();
    if (this.isCurrentModule(ModuleName.Shipment)) {
      this.shipmentsService.onSearchCriteriaChange.subscribe(searchCriteria => {
        this.searchCriteria = { ...searchCriteria };
      });

      this.shipmentActorsViewFilterData = this.localStorage.getData(
        'shipmentActorsViewFilterData'
      );

      this.shipmentsService.listOfShipmentsNumberChanged.subscribe(
        (selectedShipmentIds: number[]) => {
          this.isCardsSelected =
            selectedShipmentIds.length > 0 || this.isAllSelected;
          this.isEmptyList = selectedShipmentIds.length == 0;
        }
      );
    }

    if (this.isCurrentModule(ModuleName.Registration)) {
      this.productRegistrationService.productRegistrationSearch.subscribe(
        productSearchCriteria => {
          this.searchCriteria = productSearchCriteria;
        }
      );

      this.productRegistrationService.listOfProductsSelectedNumbersChanged.subscribe(
        (selectedProductsIds: number[]) => {
          this.isCardsSelected =
            selectedProductsIds?.length > 0 || this.isAllSelected;
          this.isEmptyList = selectedProductsIds?.length == 0;
        }
      );
    }

    if (this.isCurrentModule(ModuleName.ProductLibrary)) {
      this.productService.onSearchCriteriaChange.subscribe(
        productSearchCriteria => {
          this.searchCriteria = productSearchCriteria;
        }
      );
    }

    if (this.isCurrentModule(ModuleName.Quote)) {
      this.quoteService.quoteSearch.subscribe(quoteSearchCriteria => {
        this.searchCriteria = quoteSearchCriteria;
      });
    }

    this.initSearchCriteriaObject();
  }

  changeListView(selectedView: CardViewEnum) {
    this.selectedCardView = selectedView;
    this.listViewChange.emit(selectedView);
  }

  initSearchCriteriaObject() {
    switch (this.targerModule) {
      case ModuleName.Shipment: {
        return (this.searchCriteria = {
          searchTerm: ''
        } as SearchCriteria);
      }
      case ModuleName.Registration: {
        return (this.searchCriteria = {
          productSearchKeyword: ''
        } as ProductRegistrationSearchCriteria);
      }
      case ModuleName.Quote: {
        return (this.searchCriteria = {
          searchKeyword: ''
        } as QuoteSearchCriteria);
      }
      default: {
        return (this.searchCriteria = {
          searchTerm: ''
        });
      }
    }
  }

  onSearchChange(event) {
    if (event.key === 'Enter') {
      this.onSearch(event.target.value);
    }
  }

  onSearch(searchTerm: string) {
    this.searchCriteria.pageIndex = 0;
    this.emitTargetModule(searchTerm);
  }

  emitTargetModule(searchTerm: string = null) {
    switch (this.targerModule) {
      case ModuleName.Shipment: {
        this.searchCriteria.searchTerm = searchTerm ?? null;
        this.shipmentsService.onSearchCriteriaChange.emit(this.searchCriteria);
        break;
      }
      case ModuleName.Registration: {
        this.searchCriteria.productSearchKeyword = searchTerm;
        this.productRegistrationService.productRegistrationSearch.emit(
          this.searchCriteria
        );
        break;
      }
      case ModuleName.Quote: {
        this.searchCriteria.searchKeyword = searchTerm;
        this.quoteService.quoteSearch.emit(this.searchCriteria);
        break;
      }
      default: {
        break;
      }
    }
  }

  isCurrentModule(moduleName: string): boolean {
    return (
      this.targerModule.toLocaleLowerCase() == moduleName.toLocaleLowerCase()
    );
  }

  openFilters() {
    let buttonCoordinates = this.filtersButton.nativeElement.getBoundingClientRect();
    let top = buttonCoordinates.bottom + 7;
    let left;

    if (this.isCurrentModule(ModuleName.Shipment)) {
      left = window.innerWidth - buttonCoordinates.left - 578;
    } else if (
      this.isCurrentModule(ModuleName.Registration) ||
      this.isCurrentModule(ModuleName.Quote)
    ) {
      left = window.innerWidth - buttonCoordinates.left - 730;
    } else {
      left = window.innerWidth - buttonCoordinates.left - 952;
    }

    if (!this.isShowDraftButton) {
      left -= 161;
    }

    this.isDialogFilterOpen = !this.isDialogFilterOpen;

    let dialogRef;

    switch (this.targerModule) {
      case ModuleName.Shipment: {
        dialogRef = this.dialog.open(ShipmentsListFiltersComponent, {
          hasBackdrop: true,
          position: { top: top + 'px', left: left + 'px' },
          backdropClass: 'undimmed',
          panelClass: ['custom-filter-modal', 'custom-mat-dialog-container'],
          data: {
            searchCriteria: this.searchCriteria,
            shipmentActorsViewFilterData: this.shipmentActorsViewFilterData
          }
        });
        break;
      }
      case ModuleName.Registration:
      case ModuleName.Quote: {
        dialogRef = this.dialog.open(ListFiltersComponent, {
          hasBackdrop: true,
          position: { top: top + 'px', left: left + 'px' },
          backdropClass: 'undimmed',
          panelClass: ['custom-filter-modal', 'custom-mat-dialog-container'],
          data: {
            searchCriteria: this.searchCriteria,
            listFilters: this.listFilters,
            isQuoteFilters: this.targerModule == ModuleName.Quote
          }
        });
        break;
      }

      default: {
        break;
      }
    }

    if (this.isDialogActionOpen) {
      dialogRef.afterClosed().subscribe(() => {
        this.isDialogFilterOpen = !this.isDialogFilterOpen;
      });
    }
  }

  openColumnsToSortBy() {
    if (
      this.isCurrentModule(ModuleName.Approval) ||
      this.isCurrentModule(ModuleName.ProductLibrary)
    ) {
      // To be removed when the popup window is implemented for approvals/product library
      return;
    }

    const buttonCoordinates = this.columnsToSortByButton.nativeElement.getBoundingClientRect();
    let top = buttonCoordinates.bottom + 8;
    let left;

    if (this.isCurrentModule(ModuleName.Shipment)) {
      left = window.innerWidth - buttonCoordinates.left - 806;
    } else if (this.isCurrentModule(ModuleName.Quote)) {
      left = window.innerWidth - buttonCoordinates.left - 950;
    } else {
      left = window.innerWidth - buttonCoordinates.left - 750;
    }

    if (!this.isShowDraftButton) {
      left -= 161;
    }

    this.isDialogSortByOpen = !this.isDialogSortByOpen;
    const dialogRef = this.dialog.open(SortFilterComponent, {
      hasBackdrop: true,
      width: '313px',
      position: { top: top + 'px', left: left + 'px' },
      backdropClass: 'undimmed',
      data: {
        searchCriteria: this.searchCriteria,
        sortFilters: this.columnsToSortBy,
        componentType: this.targerModule
      },
      panelClass: ['custom-mat-dialog-container', 'custom-filter-modal']
    });

    dialogRef.afterClosed().subscribe(() => {
      this.isDialogSortByOpen = !this.isDialogSortByOpen;
    });
  }

  showDraftShipments(show: boolean) {
    this.isShowingDraftShipments = show;
    this.searchCriteria = {
      status: show ? [ShipmentStatusEnum.UserDraft] : []
    };
    this.emitTargetModule();
  }

  get isAccountManager() {
    return this.authService.isAccountManager;
  }

  get isCustomer() {
    return this.authService.isCustomer;
  }

  get numberOfSelectedFilters() {
    switch (this.targerModule) {
      case ModuleName.Shipment: {
        return this.numberOfSelectedFiltersForShipment;
      }
      case ModuleName.Registration: {
        return this.numberOfSelectedFiltersForProduct;
      }
      case ModuleName.Quote: {
        return this.numberOfSelectedFiltersForQuote;
      }
    }
  }

  get numberOfSelectedFiltersForShipment(): number {
    let selectedFilters: SearchCriteria = this.localStorage.getData('filters');
    this.shipmentActorsViewFilterData = this.localStorage.getData(
      'shipmentActorsViewFilterData'
    );

    let selectedStates = selectedFilters?.status?.length ?? 0;
    let selectedStatuses = selectedFilters?.lastActivities?.length ?? 0;
    let selectedTransportModes = selectedFilters?.transportMode?.length ?? 0;
    let selectedTypes = selectedFilters?.transactionType?.length ?? 0;
    let selectedProgresses = selectedFilters?.progress?.length ?? 0;
    let selectedMarkers = selectedFilters?.markers?.length ?? 0;

    return (
      selectedStates +
      selectedStatuses +
      selectedTransportModes +
      selectedTypes +
      selectedProgresses +
      selectedMarkers +
      (this.shipmentActorsViewFilterData?.totalNumberOfSelectedFilters ?? 0)
    );
  }

  get numberOfSelectedFiltersForProduct(): number {
    let selectedStatuses = this.searchCriteria?.statuses?.length ?? 0;
    let selectedStates = this.searchCriteria?.systemStatuses?.length ?? 0;
    let selectedProgresses = this.searchCriteria?.progresses?.length ?? 0;
    let selectedCategoryClassifications =
      this.searchCriteria?.categoryClassifications?.length ?? 0;
    let selectedRegulations = this.searchCriteria?.regulations?.length ?? 0;
    let selectedSterilities = this.searchCriteria?.sterilities?.length ?? 0;
    let selectedCountryTypes = this.searchCriteria?.countryTypes?.length ?? 0;

    return (
      selectedStates +
      selectedStatuses +
      selectedProgresses +
      selectedCategoryClassifications +
      selectedRegulations +
      selectedSterilities +
      selectedCountryTypes
    );
  }

  get numberOfSelectedFiltersForQuote(): number {
    let selectedTypes = this.searchCriteria?.types?.length ?? 0;
    let selectedModesOfTransport =
      this.searchCriteria?.modesOfTransport?.length ?? 0;
    let selectedServicess = this.searchCriteria?.services?.length ?? 0;

    return selectedTypes + selectedModesOfTransport + selectedServicess;
  }

  openColumnsFilter() {
    let buttonCoordinates = this.columnsFilterButton.nativeElement.getBoundingClientRect();
    let right = window.innerWidth - buttonCoordinates.left - 277;
    let top = buttonCoordinates.bottom + 7;
    this.isDialogManageColumnOpen = !this.isDialogManageColumnOpen;

    const dialogRef = this.dialog.open(TableViewColumnsFilterComponent, {
      hasBackdrop: true,
      backdropClass: 'undimmed',
      position: { top: top + 'px', right: right + 'px' },
      panelClass: 'custom-mat-dialog-container',
      data: {
        moduleName: this.targerModule,
        columnNames: this.tableViewColumns,
        columnsFilter: this.columnsFilter,
        defaultColumns: this.hasItems ? this.defaultColumnFilters : [],
        minColumns: this.minColumns,
        viewColumns: this.viewColumns,
        selectedViewColumns: this.selectedViewColumns
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.isDialogManageColumnOpen = !this.isDialogManageColumnOpen;
      if (result) {
        this.selectedViewColumns = result;
      }
    });
  }

  onSelectAll(event) {
    this.onSelectAllChange.emit(event.checked);
    this.isCardsSelected = event.checked;
    this.isAllSelected = event.checked;
  }

  openActions() {
    let buttonCoordinates = this.actionsButton.nativeElement.getBoundingClientRect();
    let top = buttonCoordinates.bottom + 7;
    let left;
    if (
      this.isCurrentModule(ModuleName.Registration) ||
      this.isCurrentModule(ModuleName.ProductLibrary)
    ) {
      left = window.innerWidth - buttonCoordinates.left - 535;
    } else {
      left = window.innerWidth - buttonCoordinates.left - 370;
    }

    if (!this.isShowDraftButton) {
      left -= 161;
    }

    this.isDialogActionOpen = !this.isDialogActionOpen;

    const dialogRef = this.dialog.open(BulkActionComponent, {
      hasBackdrop: true,
      position: { top: top + 'px', left: left + 'px' },
      backdropClass: 'undimmed',
      panelClass: [
        'custom-filter-modal',
        'custom-mat-dialog-container',
        'custom-bulk-modal'
      ],
      data: {
        moduleName: this.targerModule,
        isAllSelected: this.isAllSelected,
        searchCriteria: this.searchCriteria,
        companyId: this.authService?.currentUser?.defaultCustomerCompany
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.isDialogActionOpen = !this.isDialogActionOpen;
    });
  }

  get searchBoxPlaceHolder(): string {
    if (this.isCurrentModule(ModuleName.Registration)) {
      return 'Search for Registration';
    }
    if (this.isCurrentModule(ModuleName.ProductLibrary)) {
      return 'Search for Product';
    }
    return `Search for ${this.targerModule}`;
  }

  get minColumns(): number {
    if (
      this.isCurrentModule(ModuleName.Shipment) ||
      this.isCurrentModule(ModuleName.Quote)
    ) {
      return 5;
    }

    return 7;
  }

  get noSelectionMessage() {
    if (this.targerModule == ModuleName.Shipment) {
      return 'No Shipments selected';
    } else if (this.targerModule == ModuleName.Registration) {
      return 'No Registrations selected';
    } else if (this.targerModule == ModuleName.ProductLibrary) {
      return 'No Products selected';
    }
  }

  get isDisplaySelectAllCheckBox(): boolean {
    return (
      (!this.isShowingDraftShipments &&
        this.isCurrentModule(ModuleName.Shipment)) ||
      this.isCurrentModule(ModuleName.Registration) ||
      this.isCurrentModule(ModuleName.ProductLibrary) ||
      this.isCurrentModule(ModuleName.Approval) ||
      this.isCurrentModule(ModuleName.Quote)
    );
  }

  listenToChanges() {
    if (this.isCurrentModule(ModuleName.ProductLibrary)) {
      this.productService.selectedProductsNumbersChanged.subscribe(
        (selectedCodeNumbers: number[]) => {
          this.checkIsCardsSelected(selectedCodeNumbers);
        }
      );
    } else if (this.isCurrentModule(ModuleName.Approval)) {
      this.approvalService.selectedApprovalsNumbersChanged.subscribe(
        (selectedNumbers: string[]) => {
          this.checkIsCardsSelected(selectedNumbers);
        }
      );
    } else if (this.isCurrentModule(ModuleName.Quote)) {
      this.createQuoteHelperService.selectedQuotesChanged.subscribe(
        (selectedQuotesId: string[]) => {
          this.checkIsCardsSelected(selectedQuotesId);
        }
      );
    }
  }
  checkIsCardsSelected(selectedNumbers: (string | number)[]): void {
    this.isCardsSelected = selectedNumbers?.length > 0 || this.isAllSelected;
    this.isEmptyList = selectedNumbers?.length == 0;
  }

  get isDisableListViewButtons(): boolean {
    return (
      this.isCurrentModule(EntityType.Supplier) ||
      this.isCurrentModule(ModuleName.ProductLibrary) ||
      this.isCurrentModule(EntityType.TruckingPricelist) ||
      this.isCurrentModule(EntityType.DomesticTruckingPricelist) ||
      this.isCurrentModule(EntityType.CustomsClearancePricelist) ||
      this.isCurrentModule(EntityType.Registration)
    );
  }

  get canOpenActionsList(): boolean {
    return (
      (!this.isShowingDraftShipments &&
        this.isCurrentModule(ModuleName.Shipment)) ||
      this.isCurrentModule(ModuleName.Registration) ||
      this.isCurrentModule(ModuleName.ProductLibrary) ||
      this.isCurrentModule(ModuleName.Approval)
    );
  }

  get isShowDraftButton(): boolean {
    return (
      !this.isShowingDraftShipments &&
      (this.isAccountManager || this.isCustomer) &&
      this.isCurrentModule(ModuleName.Shipment)
    );
  }
}
