<div class="page-details-container">
  <div class="page-body">
    <div class="row m-0 d-flex m-0">
      <app-page-header [title]="title" class="col-8"></app-page-header>
      <div class="note col-4 pr-0">
        (<span class="required-text">*</span>) means that this field is
        mandatory
      </div>
    </div>
    <ng-content *ngIf="isShowErrorAlert" select="[errorMessageAlert]"></ng-content>
    <ng-content select="[pageBody]"></ng-content>
  </div>

  <div class="row m-0 btn-operation">
    <div class="col-12 text-right">
      <button class="btn btn-cancel" (click)="onSubmitButtonClicked(false)">
        Cancel
      </button>
      <button class="btn btn-save" (click)="onSubmitButtonClicked(true)">
        Save
      </button>
    </div>
  </div>
</div>
