<div class="admin-head-bar" [class]="isAdmin ? 'admin-role-nav-bar-style' : ''">
  <img
    src="assets/images/PNG/Navy Logo PNG 1.png"
    class="logo-navy"
    alt="logo-navy"
  />

  <ul class="app-component nav nav-pills flex-column flex-lg-row info-part">
    <button
      class="btn notification-bell"
      [matMenuTriggerFor]="notificationMenu"
    >
      <div title="Notifications" class="notifications">
        <div
          class="unread-notifications"
          *ngIf="_notificatinService.hasUnreadNotifications"
        ></div>
        <svg-icon name="black-notification"></svg-icon>
      </div>
    </button>
    <mat-menu #notificationMenu="matMenu" class="notifications-viewer">
      <app-notifications-viewer
        class="notification-viewer-without-companies"
      ></app-notifications-viewer>
    </mat-menu>
    <div class="separator"></div>
    <div
      class="companies-container"
      *ngIf="
        !isAdmin && (canManageNonMedicalCompanies || canManageMedicalCompanies)
      "
    >
      <ng-select
        [clearable]="false"
        [(ngModel)]="defaultCompanyName"
        (change)="onChangeDefaultCustomerCompany()"
        [searchable]="false"
        class="companies"
        [ngClass]="{ 'single-company-ddl': companies?.length == 1 }"
        [virtualScroll]="true"
        (scroll)="onScroll($event)"
      >
        <ng-template ng-label-tmp let-item="item">
          <div class="pb-1 company-name-label">
            <img class="company-logo" *ngIf="companyLogo" [src]="companyLogo" />
            {{ item.length > 6 ? (item | slice: 0:6) + "..." : item }}
          </div>
        </ng-template>
        <ng-option [value]="company.name" *ngFor="let company of companies">
          <div class="drop-down-element">
            <img
              class="company-logo"
              *ngIf="company.logo"
              [src]="company.logo"
            />
            {{
              company.name?.length > 12
                ? (company.name | slice: 0:12) + "..."
                : company.name
            }}
          </div>
        </ng-option>
      </ng-select>
    </div>
    <button
      *ngIf="isUserLoggedIn"
      class="btn avatar-btn"
      [matMenuTriggerFor]="avatarMenu"
    >
      <div class="avatar">
        <span *ngIf="!profilePictureUrl" class="default-user-picture">
          {{ fullName | userNameInitials }}
        </span>
        <img *ngIf="profilePictureUrl" [src]="profilePictureUrl" alt="" />
        <svg-icon name="black-arrow-down"></svg-icon>
      </div>
    </button>
    <mat-menu #avatarMenu="matMenu" class="user-profile-ddl">
      <ul class="app-component nav nav-pills ml-lg-2 flex-column">
        <li class="nav-item settings-card" routerLinkActive="active">
          <a
            class="nav-link px-0 pt-0"
            [routerLink]="'/my-profile/' + employeeId"
          >
            <svg-icon name="my-profile" class="pr-2"></svg-icon>
            {{ "mainMenu.Profile" | translate }}
          </a>
        </li>
        <li
          class="nav-item settings-card"
          routerLinkActive="active"
          *ngIf="canManageRegistrationAndApprovalServices"
        >
          <a class="nav-link  px-0 pt-0" routerLink="/customer-company">
            <svg-icon name="company-profile" class="pr-2"></svg-icon>
            {{ "mainMenu.CompanyProfile" | translate }}</a
          >
        </li>
        <li class="nav-item settings-card" (click)="logout()">
          <a class="nav-link p-0" href="javascript:;">
            <svg-icon name="logout" class="pr-2"></svg-icon>
            {{ "mainMenu.Logout" | translate }}</a
          >
        </li>
      </ul>
    </mat-menu>
  </ul>
</div>
