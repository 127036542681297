import { Component, OnInit, Input, Inject } from '@angular/core';
import { ShipmentsService } from 'src/app/shipments/shipments.service';
import { SearchCriteria } from '../../models/shipment/search-criteria.model';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { AlertService } from '../../services/alert.service';
import { HelperService } from '../../services/helper.service';
import { NumberOfUpdatedEntitiesModalComponent } from '../number-of-updated-entities-modal/number-of-updated-entities-modal.component';
import { ModuleName } from '../../models/enums/module-name.enum';
import { ProductRegistrationService } from 'src/app/product-registration/services/product-registration.service';
import { ProductService } from 'src/app/product-library/services/product.service';

@Component({
  selector: 'app-bulk-action',
  templateUrl: './bulk-action.component.html',
  styleUrls: ['./bulk-action.component.scss']
})
export class BulkActionComponent implements OnInit {
  constructor(
    private shipmentsService: ShipmentsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    private dialogRef: MatDialogRef<BulkActionComponent>,
    private helperService: HelperService,
    private productRegistrationService: ProductRegistrationService,
    private shipmentService: ShipmentsService,
    private dialog: MatDialog,
    private productService: ProductService
  ) {}

  @Input() isAllSelected: boolean;
  @Input() searchCriteria: SearchCriteria;
  @Input() companyId: number;
  statusName: string;
  @Input() moduleName: string;
  isSaving: boolean = false;
  isExport: boolean = false;

  statuses: string[] = [];

  modules = ModuleName;

  ngOnInit(): void {
    if (!this.isAllSelected) {
      this.isAllSelected = this.data['isAllSelected'];
    }

    if (!this.searchCriteria) {
      this.searchCriteria = this.data['searchCriteria'];
    }

    if (!this.companyId) {
      this.companyId = this.data['companyId'];
    }

    if (!this.moduleName) {
      this.moduleName = this.data['moduleName'];
    }

    this.getStatus();
  }

  exportToExcel() {
    let exportMessage;
    if (this.isCurrentModule(this.modules.Shipment)) {
      exportMessage = 'Shipments';
      this.shipmentsService
        .exportSelectedShipments(this.searchCriteria, this.isAllSelected)
        .subscribe(response => {
          this.exportExcelSuccessful(response, exportMessage);
        });
    } else if (this.isCurrentModule(this.modules.Registration)) {
      exportMessage = 'Registrations';
      this.productRegistrationService
        .exportProductRegistrationToExcel(
          this.searchCriteria,
          this.isAllSelected
        )
        .subscribe(response => {
          this.exportExcelSuccessful(response, exportMessage);
        });
    } else if (this.isCurrentModule(this.modules.ProductLibrary)) {
      exportMessage = 'Products';
      this.productService
        .exportProductsToExcel(this.searchCriteria, this.isAllSelected)
        .subscribe(response => {
          this.exportExcelSuccessful(response, exportMessage);
        });
    }
  }

  exportExcelSuccessful(response: any, module: string) {
    var fileName = response.headers.get('filename');
    this.downloadExcel(response.body, fileName);
    this.alertService.success(module + ' successfully exported.');
  }

  downloadExcel(response, fileName) {
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(response);
    link.download = fileName;
    link.click();
  }

  save() {
    this.isSaving = true;

    if (this.isExport) {
      this.exportToExcel();
      this.cancel();
      return;
    }

    this.shipmentsService
      .bulkUpdateShipmentsStatus(
        this.searchCriteria,
        this.statusName,
        this.companyId,
        this.isAllSelected
      )
      .subscribe(result => {
        this.cancel();
        const totalNumber = this.isAllSelected
          ? this.searchCriteria.pageSize
          : this.shipmentsService.getListOfShipmentsNumberCount();

        this.dialog.open(NumberOfUpdatedEntitiesModalComponent, {
          hasBackdrop: true,
          disableClose: true,
          width: '450px',
          height: '406px',
          data: {
            type: ModuleName.Shipment + 's',
            totalNumber: totalNumber,
            updatedEntitiesNumber: result,
            companyId: this.companyId
          }
        });
        this.shipmentService.shipmentsBulkUpdated.emit(result);
        this.isSaving = false;
      });
  }

  toggleExportSelection() {
    this.isExport = !this.isExport;
  }

  cancel() {
    this.dialogRef.close();
  }

  statusSelected(value) {
    this.statusName = value;
  }

  isCurrentModule(module: ModuleName): boolean {
    return this.moduleName === module;
  }

  isSelectedShipmentsExceedsLimit(): boolean {
    return this.shipmentsService.getListOfShipmentsNumberCount() > 20;
  }

  isSelectedProductsExceedsLimit(): boolean {
    return (
      this.productRegistrationService.getListOfProductsSelectedNumberCount() >
      20
    );
  }

  private getStatus() {
    ///if u will use the same  component with quote or any others models u can use switch
    this.helperService.getShipmentStatuses().subscribe(response => {
      if (response) {
        this.statuses = response;
      }
    });
  }

  get isDisableApplyButton(): boolean {
    if (this.isExport) {
      return false;
    }
    return (
      this.isSaving ||
      this.isAllSelected ||
      (this.isCurrentModule(ModuleName.Shipment) &&
        this.isSelectedShipmentsExceedsLimit()) ||
      (this.isCurrentModule(ModuleName.Registration) &&
        this.isSelectedProductsExceedsLimit()) ||
      !this.statusName
    );
  }
}
