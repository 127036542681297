/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars
 */
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  forwardRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => CheckboxComponent),
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() checked = false;
  @Input() value: unknown;
  @Input() classes!: string;
  @Input() disabled!: boolean;
  @Output() public valueEmiter = new EventEmitter<unknown>();

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  _onChange = (_value: any): void => {};
  _onTouched = (): any => {};

  writeValue(obj: unknown): void {}
  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  registerOnTouched(fn: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this._onTouched = fn;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  checkboxChange(event: Event): void {
    this._onChange(
      (<HTMLInputElement>event.target).checked
      // value: (<HTMLInputElement>event.target).value,
      // isChecked: (<HTMLInputElement>event.target).checked,
    );
    this.valueEmiter.emit(this.value);
  }
}
