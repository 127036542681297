<table
  class="products-table"
  [ngClass]="{ 'product-registration-table': isProductRegistration }"
>
  <tr>
    <th *ngFor="let column of columns" class="column-title">
      <div>{{ column }}</div>
    </th>
  </tr>

  <tbody>
    <tr
      *ngFor="let product of productsList; let i = index"
      class="products-row-container"
    >
      <td
        *ngFor="let column of columns"
        class="column-content border-left-0"
        [ngClass]="{
          'product-name':
            isTargetColumn(column, approvalProductTabColumns.ProductName) &&
            isApproval
        }"
        [ngStyle]="{
          color:
            isTargetColumn(column, approvalProductTabColumns.Status) &&
            isApproval
              ? getApprovalProductStatusColor(product)
              : ''
        }"
      >
        <div
          [ngbPopover]="getColumnContent(column, product)"
          placement="bottom-left"
          triggers="mouseenter:mouseleave"
          [disablePopover]="getColumnContent(column, product)?.length < 40"
          *ngIf="
            !isTargetColumn(column, approvalProductTabColumns.Actions) &&
            !isTargetColumn(column, productsTableColumns.Notes)
          "
        >
          {{ getColumnContent(column, product) | slice: 0:40 }}
          <span *ngIf="getColumnContent(column, product)?.length > 40"
            >...</span
          >
        </div>

        <div
          *ngIf="isTargetColumn(column, productsTableColumns.Notes)"
          (click)="toggleShowInput(i)"
        >
          <span
            [ngClass]="{ notes: product?.notes == null }"
            *ngIf="!notesUpdate[i]?.showInput"
          >
            {{ product.notes || "No notes added." }}
          </span>
          <input
            *ngIf="notesUpdate[i]?.showInput"
            maxlength="180"
            (click)="$event.stopPropagation()"
            (keyup.enter)="updateNotes(product.productId, i)"
            (keyup.escape)="toggleShowInput(i)"
            [(ngModel)]="notesUpdate[i].insertedNotes"
          />
        </div>

        <div
          *ngIf="isTargetColumn(column, approvalProductTabColumns.Actions)"
          class="actions"
        >
          <div
            class="action-icon edit-action cursor-pointer"
            [routerLink]="
              './products-library/' +
              product?.productId +
              '/approval-product-details'
            "
            *ngIf="!isDisabled"
          >
            <svg-icon name="editQuote"></svg-icon>
          </div>

          <div
            class="action-icon delete-action cursor-pointer"
            (click)="deleteProduct(product?.productId)"
            *ngIf="!isDisabled"
          >
            <svg-icon name="delete"></svg-icon>
          </div>
        </div>

        <div *ngIf="isVariantColumn(column)">
          <div class="arrow-container">
            <button class="action-btn d-flex" (click)="panel.toggle()">
              <svg-icon name="arrow-down"></svg-icon>
            </button>
            <mat-expansion-panel #panel></mat-expansion-panel>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>
