import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ProductRegistration } from 'src/app/create-product-registration/models/product-registration.model';
import { ProductRegistrationHelperService } from 'src/app/create-product-registration/service/product-registration-helper.service';
import { CancelEntityComponent } from 'src/app/shared/components/cancel-entity/cancel-entity.component';
import { CancelationDetails } from 'src/app/shared/models/cancelation-details';
import { PauseDetails } from 'src/app/shared/models/pause-details';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PauseProductRegistrationPopupComponent } from '../../components/pause-product-registration-popup/pause-product-registration-popup.component';
import { ResumeProductRegistrationPopupComponent } from '../../components/resume-product-registration-popup/resume-product-registration-popup.component';
import { ProductRegistrationDetails } from '../../models/product-registration-details.model';
import { ProductRegistrationService } from '../../services/product-registration.service';
import { ProductRegistrationCommentWithActionActors } from '../../models/product-registration-comment-with-action-actors.model';
import { ProductRegistrationCommentService } from '../../services/product-registration-comment.service';
import { ProductRegistrationsActivityCategoriesEnum } from 'src/app/admin-portal-configs/enums/product-registrations-activity-categories.enum';
import { EntityType } from 'src/app/createShipment/models/enums/entity-type.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { StatusChangeRequest } from 'src/app/shared/models/status-change-request.model';
import { ModuleName } from 'src/app/shared/models/enums/module-name.enum';
import { ProductRegistrationActivity } from '../../enums/product-registration-activity.enum';

@Component({
  selector: 'app-product-registration-details',
  templateUrl: './product-registration-details.component.html',
  styleUrls: ['./product-registration-details.component.scss']
})
export class ProductRegistrationDetailsComponent implements OnInit, OnDestroy {
  productRegistrationDetails: ProductRegistrationDetails;
  productRegistrationCommentWithActionActors: ProductRegistrationCommentWithActionActors[] = [];
  newProductRegistrationCommentWithActionActor: ProductRegistrationCommentWithActionActors;
  productRegistrationModuleName = ModuleName.Registration;

  constructor(
    private route: ActivatedRoute,
    private productRegistrationService: ProductRegistrationService,
    private productRegistrationHelperService: ProductRegistrationHelperService,
    private authService: AuthService,
    private dialog: MatDialog,
    private productRegistrationCommentService: ProductRegistrationCommentService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.productRegistrationDetails = data['details'];
      this.productRegistrationCommentWithActionActors = data['comments'];
    });

    this.productRegistrationService.productRegistrationRequestUpdated.subscribe(
      () => {
        this.getProductRegistrationDetails();
      }
    );

    this.productRegistrationService.productLeadTimeNotesUpdated.subscribe(x => {
      this.updateLeadTimeNotes(x?.leadTimeId, x?.leadTimeNotes);
    });

    this.productRegistrationCommentService.onCommentsUpdate.subscribe(
      data => {
        if (data) {
          this.newProductRegistrationCommentWithActionActor = data;
        } else {
          this.getCommentsByProductId();
        }
      },
      error => {}
    );

    this.productRegistrationService.emitIsInRegistretionDetailsPageEvent(this.productRegistrationDetails);
  }

  ngOnDestroy() {
    this.productRegistrationService.emitIsInRegistretionDetailsPageEvent();
  }

  getProductRegistrationDetails() {
    this.productRegistrationService
      .getProductRegistrationDetails(
        this.productRegistrationDetails?.productRegistration?.id
      )
      .subscribe(res => {
        this.productRegistrationDetails = res;
        this.productRegistrationService.emitIsInRegistretionDetailsPageEvent(this.productRegistrationDetails);
      });
  }

  get isAccountManager(): boolean {
    return this.authService.isAccountManager;
  }
  get isCustomer(): boolean {
    return this.authService.isCustomer;
  }
  get productId(): string {
    return this.productRegistrationHelperService.getProductId(
      this.productRegistrationDetails.productRegistration
    );
  }

  get isNewSystemStatus(): boolean {
    return this.productRegistrationHelperService?.isNewSystemStatus(
      this.productRegistration
    );
  }

  get isCancelledStatus(): boolean {
    return this.productRegistrationHelperService?.isCancelledStatus(
      this.productRegistration
    );
  }

  get productRegistration(): ProductRegistration {
    return this.productRegistrationDetails?.productRegistration;
  }

  get cancelationDetails(): CancelationDetails {
    return this.productRegistrationHelperService.getCancelationDetails(
      this.productRegistration
    );
  }

  get statusName(): string {
    return this.productRegistrationHelperService.getStatusName(
      this.productRegistration
    );
  }

  openCancelPopup() {
    this.dialog
      .open(CancelEntityComponent, {
        hasBackdrop: true,
        width: '600px',
        maxHeight: '650px',
        data: {
          entityName: EntityType.Registration
        }
      })
      .afterClosed()
      .subscribe(reason => {
        if (reason && reason?.trim()?.length > 5) {
          let statusChangeRequest: StatusChangeRequest = {
            id: this.productRegistration?.id,
            reason: reason
          };
          this.cancelProductRegistration(statusChangeRequest);
        }
      });
  }

  cancelProductRegistration(statusChangeRequest: StatusChangeRequest) {
    this.productRegistrationService
      .cancelProductRegistration(statusChangeRequest)
      .subscribe(registration => {
        this.alertService.success('Request successfully cancelled.');
        this.productRegistrationDetails.productRegistration = registration;
      });
  }

  get isProductPaused(): boolean {
    return this.productRegistrationHelperService.isPausedStatus(
      this.productRegistration
    );
  }

  pauseResumeProduct() {
    if (!this.isProductPaused) {
      this.dialog.open(PauseProductRegistrationPopupComponent, {
        width: '650px',
        height: '540px',
        data: {
          productId: this.productRegistration?.id
        }
      });
    } else {
      this.dialog.open(ResumeProductRegistrationPopupComponent, {
        width: '600px',
        maxHeight: '650px',
        data: {
          productId: this.productRegistration?.id
        }
      });
    }
  }

  get pauseDetails(): PauseDetails {
    return this.productRegistrationHelperService.getPauseDetails(
      this.productRegistration
    );
  }

  get currentProgressPointIndex(): number {
    return this.productRegistrationDetails?.progressPoints?.findIndex(
      item => item.name === this.currentProgressPointName
    );
  }

  get currentProgressPointName(): string {
    return this.productRegistrationHelperService.getCurrentProgressPointName(
      this.productRegistrationDetails?.productRegistration
    );
  }

  get currentActivityName(): string {
    const currentActivity = this.productRegistrationHelperService.getLastActivity(
      this.productRegistrationDetails?.productRegistration
    );

    if (
      currentActivity?.activityCategoryName ==
      ProductRegistrationsActivityCategoriesEnum.Resumed
    ) {
      return this.productRegistrationHelperService.getLastActiveActivityName(
        this.productRegistrationDetails?.productRegistration
      );
    } else {
      return currentActivity?.activityName;
    }
  }

  get hasPendingActions(): boolean {
    return this.productRegistrationHelperService.getHasPendingActions(
      this.productRegistration
    );
  }
  updateLeadTimeNotes(leadTimeId: number, leadTimeNotes: string) {
    let oldLeadTime = this.productRegistrationDetails?.productLeadTimeLogs?.find(
      l => l.id === leadTimeId
    );
    if (oldLeadTime) {
      oldLeadTime.notes = leadTimeNotes;
    }
  }

  private getCommentsByProductId() {
    this.productRegistrationCommentService
      .getProductRegistrationComment(this.productId)
      .subscribe(response => {
        this.productRegistrationCommentWithActionActors = response;
      });
  }

  get isEditDetailsDisabled(): boolean {
    let isStatusReached =
      this.productRegistrationDetails?.progressAndActivityLogs?.findIndex(
        p =>
          p.activityLogs.findIndex(
            a =>
              a?.registrationActivity?.name ===
                ProductRegistrationActivity.SubmittedToEDA ||
              a?.registrationActivity?.name ===
                ProductRegistrationActivity.SCApprovalRequired
          ) != -1
      ) != -1;

    return isStatusReached && this.isCustomer;
  }
}
