<label class="wrapper d-flex align-items-center">
  <input
    [disabled]="disabled"
    type="checkbox"
    [value]="value"
    (change)="checkboxChange($event)"
    [checked]="checked"
    [classList]="'checkmark ' + classes + ' ' + (disabled ? ' disabled ' : ' ')"
  />
</label>
