<div class="non-admin-sidebar-container text-center">
  <div class="nav-item menu-icon">
    <svg-icon name="menu-sidebar"></svg-icon>
  </div>

  <div class="nav-item cursor-pointer" ngbDropdown>
    <div ngbDropdownToggle>
      <svg-icon name="plus-sidebar"></svg-icon>
    </div>
    <div ngbDropdownMenu class="creation-popup">
      <div
        class="dropdown-item"
        *ngIf="canCreateQuotes"
        [routerLink]="[
          '/company/' + companyId + '/quotes/createquote/chooseservices'
        ]"
      >
        Quote
      </div>
      <div
        class="dropdown-item"
        *ngIf="canCreateShipment"
        [routerLink]="['/company/' + companyId + '/shipments/createshipment']"
      >
        Shipment
      </div>
      <div
        class="dropdown-item"
        [routerLink]="[
          '/company/' + companyId + '/products-library/create-product/details'
        ]"
      >
        Product
      </div>
      <div
        class="dropdown-item"
        *ngIf="isMedicalOrPharmaceuticalIndustry"
        [routerLink]="[
          '/company/' +
            companyId +
            '/registrations/create-product-registration/product-registration-details'
        ]"
      >
        Registration
      </div>
      <div
        class="dropdown-item"
        *ngIf="isMedicalOrPharmaceuticalIndustry"
        [routerLink]="['/company/' + companyId + '/approvals/create-approval']"
      >
        Approval
      </div>
    </div>
  </div>
  <div
    class="nav-item cursor-pointer"
    [routerLink]="[
      '/company/' + companyId + '/' + nonAdminSidebarEnum.Dashboard
    ]"
    [ngClass]="{ selected: isSelectedTab(nonAdminSidebarEnum.Dashboard) }"
  >
    <svg-icon name="dashboard-sidebar"></svg-icon>
  </div>

  <div
    class="nav-item cursor-pointer"
    *ngIf="canViewShipments || canViewShipmentsForSelectedCustomer"
    [routerLink]="[currentShipmentRoute]"
    [ngClass]="{ selected: isSelectedTab(nonAdminSidebarEnum.Shipments) }"
  >
    <svg-icon name="shipments-sidebar"></svg-icon>
  </div>

  <div
    class="nav-item cursor-pointer"
    *ngIf="canViewQuotes"
    [routerLink]="['/company/' + companyId + '/' + nonAdminSidebarEnum.Quotes]"
    [ngClass]="{ selected: isSelectedTab(nonAdminSidebarEnum.Quotes) }"
  >
    <svg-icon name="quotes-sidebar"></svg-icon>
  </div>

  <div
    class="nav-item"
    ngbDropdown
    [ngClass]="{
      selected:
        isSelectedTab(nonAdminSidebarEnum.Products) ||
        isSelectedTab(nonAdminSidebarEnum.Registrations) ||
        isSelectedTab(nonAdminSidebarEnum.Approvals)
    }"
  >
    <button
      class="btn btn-link dropdown-button"
      id="productsDropdownMenu"
      ngbDropdownToggle
    >
      <svg-icon name="products-sidebar"></svg-icon>
    </button>
    <div ngbDropdownMenu class="products-popup">
      <div
        class="dropdown-item"
        [routerLink]="['/company/' + companyId + '/products-library']"
      >
        Product Library
      </div>
      <div
        class="dropdown-item"
        *ngIf="isMedicalOrPharmaceuticalIndustry"
        [routerLink]="['/company/' + companyId + '/registrations']"
      >
        Registrations
      </div>
      <div
        class="dropdown-item"
        *ngIf="isMedicalOrPharmaceuticalIndustry"
        [routerLink]="['/company/' + companyId + '/approvals']"
      >
        Approvals
      </div>
    </div>
  </div>

  <div
    class="nav-item cursor-pointer"
    *ngIf="canManageForm4Requests"
    [routerLink]="['/form4requests']"
    [queryParams]="{ view: nonAdminSidebarEnum.Form4Requests }"
    [ngClass]="{ selected: isSelectedTab(nonAdminSidebarEnum.Form4Requests) }"
  >
    <svg-icon name="form4-sidebar"></svg-icon>
  </div>
</div>
