<nav
  class="header app-component navbar navbar-expand-lg navbar-dark bg-primary fixed-top"
>
  <a
    ngPreserveWhitespaces
    class="app-component navbar-brand"
    routerLink="{{ authService.defaultPageUrl }}"
  >
    <!-- Hide FreePL Brand -->
    <img
      src="assets/images/PNG/FPL_Logo.png"
      class="d-inline-block align-top"
      alt="logo"
    />

    <!-- <span class="app-component appTitle">{{appTitle}}</span> -->
  </a>
  <button
    type="button"
    class="app-component navbar-toggler"
    data-toggle="collapse"
    data-target=".menu-items-container.app-component"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div
    *ngIf="isUserLoggedIn"
    class="app-component collapse navbar-collapse menu-items-container"
  >
    <ul class="app-component nav nav-pills flex-column flex-lg-row">
      <li class="nav-item" routerLinkActive="active">
        <a
          class="nav-link"
          routerLink="company/{{ defaultCompany }}/dashboard"
          >{{ "mainMenu.Dashboard" | translate }}</a
        >
      </li>

      <li
        class="nav-item"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="isAdmin"
      >
        <a class="nav-link" routerLink="/company">{{
          "mainMenu.Companies" | translate
        }}</a>
      </li>

      <li
        class="nav-item"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="isAdmin"
      >
        <a class="nav-link" routerLink="/customers">{{
          "mainMenu.Customers" | translate
        }}</a>
      </li>

      <li
        class="nav-item services-ddl "
        [class]="isDdlActive('suppliers') ? 'active-services' : ''"
        ngbDropdown
        #servicesLink="ngbDropdown"
        *ngIf="isAdmin"
      >
        <div
          class="btn ddl-nav-button me-2"
          id="dropdown-manual"
          ngbDropdownToggle
          (focus)="servicesLink.open()"
        >
          {{ "mainMenu.Network" | translate }}

          <svg-icon
            [name]="isDdlActive('suppliers') ? 'blueArrowDown' : 'arrowDown'"
          ></svg-icon>
        </div>
        <div
          ngbDropdownMenu
          aria-labelledby="dropdown-manual"
          class="services-ddl-container mt-3 products-ddl"
        >
          <a
            class="nav-link ddl-nav-item cursor-pointer"
            [routerLink]="'/suppliers'"
            ngbDropdownItem
            >{{ "mainMenu.Suppliers" | translate }}</a
          >
        </div>
      </li>

      <li class="nav-item" routerLinkActive="active" *ngIf="canViewQuotes">
        <a
          class="nav-link"
          [routerLink]="'company/' + defaultCompany + '/quotes'"
          >{{ "mainMenu.Quotes" | translate }}</a
        >
      </li>

      <li
        class="nav-item"
        name="nav-shipments"
        *ngIf="canViewShipments || canViewShipmentsForSelectedCustomer"
        [ngClass]="
          isCurrentRoute(currentShipmentRoute) &&
          !hasCurrentQueryParams(shipmentListViewEnum.OperationsOversight)
            ? 'active'
            : ''
        "
      >
        <a
          class="nav-link cursor-pointer"
          (click)="onChangeShipmentsListView(currentShipmentRoute)"
          >{{ "mainMenu.Shipments" | translate }}</a
        >
      </li>
      <li
        class="nav-item"
        *ngIf="canManageForm4Requests"
        [ngClass]="isCurrentRoute('/form4requests') ? 'active' : ''"
      >
        <a
          class="nav-link cursor-pointer"
          (click)="
            onChangeShipmentsListView(
              '/form4requests',
              shipmentListViewEnum.Form4Requests
            )
          "
          >{{ "mainMenu.Form4Requests" | translate }}</a
        >
      </li>

      <li
        class="nav-item services-ddl "
        [class]="isDdlActive('registrations') ? 'active-services' : ''"
        ngbDropdown
        #servicesLink="ngbDropdown"
      >
        <div
          class="btn ddl-nav-button me-2"
          id="dropdown-manual"
          ngbDropdownToggle
          (focus)="servicesLink.open()"
        >
          {{ "mainMenu.Products" | translate }}

          <svg-icon
            [name]="
              isDdlActive('registrations') ? 'blueArrowDown' : 'arrowDown'
            "
          ></svg-icon>
        </div>
        <div
          ngbDropdownMenu
          aria-labelledby="dropdown-manual"
          class="services-ddl-container mt-3 products-ddl"
        >
          <a
            class="nav-link ddl-nav-item"
            ngbDropdownItem
            [routerLink]="'company/' + defaultCompany + '/products-library'"
          >
            <svg-icon
              class="services-ddl-icon"
              name="product-registration"
            ></svg-icon>
            {{ "mainMenu.Library" | translate }}
          </a>
          <div *ngIf="isMedicalOrPharmaceuticalIndustry">
            <a
              class="nav-link ddl-nav-item cursor-pointer"
              [routerLink]="'company/' + defaultCompany + '/registrations'"
              ngbDropdownItem
            >
              <svg-icon
                class="services-ddl-icon"
                name="importation-plan"
              ></svg-icon>
              {{ "mainMenu.Registrations" | translate }}
            </a>
            <a
              class="nav-link ddl-nav-item"
              [routerLink]="'company/' + defaultCompany + '/approvals'"
              ngbDropdownItem
            >
              <svg-icon
                class="services-ddl-icon"
                name="importation-approval"
              ></svg-icon>
              {{ "mainMenu.Approvals" | translate }}
            </a>
          </div>
        </div>
      </li>
      <li class="nav-item" routerLinkActive="active" *ngIf="canViewReports">
        <a class="nav-link" routerLink="/reports">{{
          "mainMenu.Reports" | translate
        }}</a>
      </li>
      <li
        class="nav-item"
        routerLinkActive="active"
        *ngIf="
          !hasOperationsOversightManagementPermission &&
          !canManageRegistrationAndApprovalServices
        "
      >
        <a class="nav-link" routerLink="/customer-company">{{
          "mainMenu.CompanyProfile" | translate
        }}</a>
      </li>
      <li
        class="nav-item"
        ngbDropdown
        #moreLinks="ngbDropdown"
        *ngIf="hasOperationsOversightManagementPermission"
      >
        <div
          class="btn ddl-nav-button me-2"
          id="dropdown-manual"
          ngbDropdownToggle
          (focus)="moreLinks.open()"
        >
          {{ "mainMenu.More" | translate }}

          <svg-icon name="arrowDown"></svg-icon>
        </div>
        <div ngbDropdownMenu aria-labelledby="dropdown-manual">
          <a
            class="nav-link ddl-nav-item cursor-pointer"
            [ngClass]="
              isCurrentRoute('/shipments') &&
              hasCurrentQueryParams(shipmentListViewEnum.OperationsOversight)
                ? 'active'
                : ''
            "
            ngbDropdownItem
            (click)="
              onChangeShipmentsListView(
                '/shipments',
                shipmentListViewEnum.OperationsOversight
              )
            "
          >
            {{ "mainMenu.OperationsOversight" | translate }}
          </a>
          <a
            class="nav-link ddl-nav-item"
            routerLinkActive="active"
            ngbDropdownItem
            routerLink="/customer-company"
            *ngIf="!canManageRegistrationAndApprovalServices"
          >
            {{ "mainMenu.CompanyProfile" | translate }}
          </a>
        </div>
      </li>
    </ul>
    <ul
      class="app-component nav nav-pills flex-column flex-lg-row mr-md-2 info-part"
    >
      <button
        class="btn notification-bell"
        [matMenuTriggerFor]="notificationMenu"
        aria-label="Example icon-button with a menu"
      >
        <div title="Notifications" class="notifications">
          <div
            class="unread-notifications"
            *ngIf="notificationService.hasUnreadNotifications"
          ></div>
          <svg-icon name="notification"></svg-icon>
        </div>
      </button>
      <mat-menu
        #notificationMenu="matMenu"
        class="notifications-viewer"
        [class]="isCustomer ? 'customer-view' : ''"
      >
        <app-notifications-viewer
          [class]="
            !canManageNonMedicalCompanies && !canManageMedicalCompanies
              ? 'notification-viewer-without-companies'
              : 'notification-viewer-with-companies'
          "
        ></app-notifications-viewer>
      </mat-menu>

      <div
        class="companies-container"
        *ngIf="canManageNonMedicalCompanies || canManageMedicalCompanies"
      >
        <ng-select
          [clearable]="false"
          [(ngModel)]="defaultCompanyName"
          (change)="onChangeDefaultCustomerCompany()"
          [searchable]="false"
          class="companies"
          [ngClass]="{ 'single-company-ddl': companies?.length == 1 }"
          [virtualScroll]="true"
          (scroll)="onScroll($event)"
          (scrollToEnd)="onScrollToEnd()"
        >
          <ng-template ng-label-tmp let-item="item">
            <div class="pb-1 company-name-label">
              <img
                class="company-logo"
                *ngIf="companyLogo"
                [src]="companyLogo"
              />
              {{ item.length > 6 ? (item | slice: 0:6) + "..." : item }}
            </div>
          </ng-template>
          <ng-option [value]="company.name" *ngFor="let company of companies">
            <div class="drop-down-element">
              <img
                class="company-logo"
                *ngIf="company.logo"
                [src]="company.logo"
              />
              {{
                company.name?.length > 12
                  ? (company.name | slice: 0:12) + "..."
                  : company.name
              }}
            </div>
          </ng-option>
        </ng-select>
      </div>
      <button
        *ngIf="isUserLoggedIn"
        class="btn pr-0"
        [matMenuTriggerFor]="avatarMenu"
        aria-label="Example icon-button with a menu"
      >
        <div class="avatar">
          <span *ngIf="!profilePictureUrl" class="default-user-picture">
            {{ fullName | userNameInitials }}
          </span>
          <img *ngIf="profilePictureUrl" [src]="profilePictureUrl" alt="" />
          <svg-icon name="arrowDown"></svg-icon>
        </div>
      </button>
      <mat-menu #avatarMenu="matMenu" class="user-profile-ddl">
        <ul class="app-component nav nav-pills ml-lg-2 flex-column">
          <li class="nav-item settings-card" routerLinkActive="active">
            <a
              class="nav-link px-0 pt-0"
              [routerLink]="'/my-profile/' + employeeId"
            >
              <svg-icon name="my-profile" class="pr-2"></svg-icon>
              {{ "mainMenu.Profile" | translate }}
            </a>
          </li>
          <li
            class="nav-item settings-card"
            routerLinkActive="active"
            *ngIf="canManageRegistrationAndApprovalServices"
          >
            <a class="nav-link  px-0 pt-0" routerLink="/customer-company">
              <svg-icon name="company-profile" class="pr-2"></svg-icon>
              {{ "mainMenu.CompanyProfile" | translate }}</a
            >
          </li>
          <li class="nav-item settings-card" (click)="logout()">
            <a class="nav-link p-0" href="javascript:;">
              <svg-icon name="logout" class="pr-2"></svg-icon>
              {{ "mainMenu.Logout" | translate }}</a
            >
          </li>
        </ul>
      </mat-menu>
    </ul>
  </div>
  <div
    *ngIf="!isUserLoggedIn && router.isActive('/about', false)"
    class="app-component collapse navbar-collapse menuItemsContainer"
  >
    <ul class="app-component nav nav-pills flex-column flex-lg-row ml-auto">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/login"
          ><i class="fa fa-sign-in"></i
        ></a>
      </li>
    </ul>
  </div>
  <div
    *ngIf="(!isUserLoggedIn && !router.isActive('/about', false)) || false"
    class="app-component collapse navbar-collapse menuItemsContainer"
  >
    <ul class="app-component nav nav-pills flex-column flex-lg-row ml-auto">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/about"
          ><i class="fa fa-info-circle"></i
        ></a>
      </li>
    </ul>
  </div>
</nav>
