export type PermissionNames =
  | 'View Users'
  | 'Manage Users'
  | 'View Roles'
  | 'Manage Roles'
  | 'Assign Roles'
  | 'Manage Accounts'
  | 'Use Accounts'
  | 'Manage Shipments'
  | 'Manage Administrations'
  | 'Manage Invoicing'
  | 'Manage Operations Oversight'
  | 'Manage Registration and Approval services'
  | 'View Config'
  | 'Manage Config';

export type PermissionValues =
  | 'users.view'
  | 'users.manage'
  | 'roles.view'
  | 'roles.manage'
  | 'roles.assign'
  | 'accounts.manage'
  | 'accounts.use'
  | 'shipments.manage'
  | 'accounts.admin'
  | 'charges.manage'
  | 'registrationAndApprovalServices.manage'
  | 'config.view'
  | 'config.manage'
  ;

export class Permission {
  public static readonly viewRolesPermission: PermissionValues = 'roles.view'; // TODO to be removed
  public static readonly manageRolesPermission: PermissionValues =
    'roles.manage'; // TODO to be removed

  public static readonly viewConfigPermission: PermissionValues = 'config.view';
  public static readonly manageConfigPermission: PermissionValues =
    'config.manage';

  public static readonly assignRolesPermission: PermissionValues =
    'roles.assign';

  public static readonly AcountManagementPermission: PermissionValues =
    'accounts.manage';
  public static readonly ClientPermission: PermissionValues = 'accounts.use';
  public static readonly ShipmentManagementPermission: PermissionValues =
    'shipments.manage';
  public static readonly AdministrationsPermission: PermissionValues =
    'accounts.admin';

  public static readonly chargesManagementPermission: PermissionValues =
    'charges.manage';

  public static readonly registrationAndApprovalServicesManagementPermission: PermissionValues =
    'registrationAndApprovalServices.manage';

  constructor(
    name?: PermissionNames,
    value?: PermissionValues,
    groupName?: string,
    description?: string
  ) {
    this.name = name;
    this.value = value;
    this.groupName = groupName;
    this.description = description;
  }

  public name: PermissionNames;
  public value: PermissionValues;
  public groupName: string;
  public description: string;
  public permissionCategoryId: number;
}
