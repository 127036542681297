<div class="role-container">
  <div class="d-flex justify-content-between">
    <app-page-header
      *ngIf="!isEditRoute"
      [title]="'roles.management.AddRole' | translate"
    ></app-page-header>
    <app-page-header
      *ngIf="isEditRoute"
      [title]="'roles.management.EditRoleOnly' | translate"
    ></app-page-header>
  </div>
  <div class="mt-4">
    <app-error-badge
      [message]="errorMesage"
      *ngIf="isFailedToSave"
    ></app-error-badge>
  </div>
  <form [formGroup]="permissionForm" (ngSubmit)="save()">
    <div class="role-info-container mt-5 ml-3">
      <div class="row">
        <div class="col-2 pop-over-label p-0 header role-info">Role Info</div>
        <div class="col-3 pop-over-field p-0">
          <input
            formControlName="name"
            class="role-name-input"
            placeholder="Role Name"
            id="name"
            type="text"
            [ngClass]="{
              error: isInvalidFormControl('name')
            }"
          />
        </div>
      </div>
      <div class="row mt-4 p-0">
        <div class="col-2 pop-over-label p-0"></div>
        <div class="col-5 pop-over-label mb-4 p-0 role-description-field">
          <input
            formControlName="description"
            class="role-description-input"
            placeholder="Role Description"
            id="description"
            type="text"
            [ngClass]="{
              error: isInvalidFormControl('description')
            }"
          />
        </div>
      </div>
    </div>

    <hr />

    <div class="permission-info mt-5 ml-3">
      <div class="row" formArrayName="permissionGroups">
        <div class="col-2 pop-over-label p-0 permission">Permissions</div>
        <div class="col-10 row w-100 pr-0">
          <div
            class="mr-1 mb-5 pop-over-label p-0"
            [ngClass]="{
              'permission-category': i > 2,
              'col-3': (i + 1) % 3 == 0,
              'col-4': (i + 1) % 3 != 0
            }"
            *ngFor="
              let categoryPermission of categoryPermissions;
              let i = index
            "
          >
            <ng-select
              [formControl]="getPermissionGroupControl(i)"
              [placeholder]="categoryPermission?.permissionCategory?.name"
              [items]="categoryPermission?.permissions"
              [multiple]="true"
              [clearable]="false"
              [searchable]="true"
              [closeOnSelect]="false"
              [bindLabel]="'name'"
              [clearSearchOnAdd]="true"
              (focus)="onPermissionsFieldFocus()"
              (blur)="onPermissionsFieldBlur()"
            >
              <ng-template
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
                *ngIf="isSearching"
              >
              </ng-template>
              <ng-template
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
                *ngIf="!isSearching"
              >
                {{ categoryPermission?.permissionCategory?.name }}

                <div class="permission-counter">
                  {{ selectedPermissionsCount[i] }}
                </div>
              </ng-template>

              <ng-template
                ng-option-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
                <div class="d-flex align-items-center">
                  <app-checkbox
                    id="item-{{ index }}"
                    [checked]="isPermissionSelected(item)"
                  ></app-checkbox>
                  <span class="mx-1"></span>
                  <span class="title"> {{ item?.name }}</span>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 mb-2 d-flex justify-content-end">
      <button class="cancel-btn" [routerLink]="['/settings']" fragment="roles">
        <span>Cancel</span>
      </button>

      <button class="save-edit-btn">
        <span>Save</span>
      </button>
    </div>
  </form>
</div>
