export enum ColumnNames {
  MoT = 'MoT',
  ShipmentNo = 'Shipment #',
  RefNo = 'Ref. #',
  DateCreated = 'Created',
  TransactionType = 'Type',
  Progress = 'Progress',
  Status = 'Status',
  Origin = 'Origin',
  PickupDate = 'Pickup Date',
  Destination = 'Destination',
  DeliveryDate = 'Delivery Date',
  InvoiceNo = 'Invoice #',
  MasterAWBBOLNo = 'MAWB #/MBL #',
  PackingListNo = 'Packing List #',
  Form4No = 'Form 4 #',
  Form4IssuingBank = 'Form 4 Issuer',
  PONo = 'PO #',
  POIssuanceDate = 'PO Issuance Date',
  OriginPort = 'Origin Port',
  DestinationPort = 'Destination Port',
  LatestShipmentEvent = 'Latest Event',
  Shipper = 'Shipper',
  Consignee = 'Consignee',
  Supplier = 'Supplier',
  TotalShipmentValue = 'Shipment Value',
  ProductClassification = 'Product Class',
  ProformaInvoice = 'Proforma Invoice',
  CommercialInvoice = 'Commercial Invoice',
  HouseAWBBLNo = 'HAWB #/HBL #',
  RoadBillOfLadingNo = 'Road BL #',
  ACID = 'ACID',
  CustomsBroker = 'Customs Broker',
  CustomsCertificateNo = 'Customs Cert. #',
  EDASubmissionNo = 'EDA Subm. #',
  EDASubmissionDate = 'EDA Subm. Date',
  EDAImportationApprovalNo = 'EDA IA #',
  EDAImportationApprovalDate = 'EDA IA Date',
  RUINo = 'RUI #',
  RUIDate = 'RUI Date',
  Incoterm = 'Incoterm',
  FreightForwarder = 'Freight Forwarder',
  Carrier = 'Carrier',
  Vessel = 'Vessel',
  Flight = 'Flight',
  BusinessUnit = 'Business Unit'
}
