<div class="section-container">
  <div class="title-section">
    <div class="title-container">
      <svg-icon name="shipmentProducts"></svg-icon>
      <span class="title">PRODUCT DETAILS</span>
    </div>
    <button
      *ngIf="!isEditRoute"
      class="edit-section-button"
      routerLink="../details"
    >
      <svg-icon name="editQuote"></svg-icon>
      Edit
    </button>
    <button
      *ngIf="isEditRoute"
      class="edit-section-button"
      (click)="handleRouting()"
    >
      <svg-icon name="editQuote"></svg-icon>
      Edit
    </button>
  </div>

  <div class="summary-container column">
    <div class="d-flex">
      <div class="section-title ">Product Information</div>
      <div class="col-9 row">
        <div
          *ngFor="let key of getEnumKeys(productInformationSummary)"
          class="product-data col-4"
        >
          <div class="product-title">
            {{ getEnumValue(productInformationSummary, key) }}
          </div>
          <div class="product-content">
            {{
              getProductInformationSummaryContent(
                getEnumValue(productInformationSummary, key)
              ) || "-"
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <div class="d-flex">
      <div class="section-title ">Compliance Information</div>
      <div class="col-9 row">
        <div
          *ngFor="let key of getEnumKeysForCompliance()"
          class="product-data col-4"
        >
          <div class="product-title">
            {{
              isMedicalIndsutry
                ? getEnumValue(medicalComplianceInformationSummary, key)
                : getEnumValue(nonMedicalComplianceInformationSummary, key)
            }}
          </div>
          <h1 class="product-content">
            {{
              getComplianceInformationSummaryContent(
                isMedicalIndsutry
                  ? getEnumValue(medicalComplianceInformationSummary, key)
                  : getEnumValue(nonMedicalComplianceInformationSummary, key)
              ) || "-"
            }}
          </h1>
        </div>
      </div>
    </div>
  </div>
</div>
