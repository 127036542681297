import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-collapsible-permission-category',
  templateUrl: './collapsible-permission-category.component.html',
  styleUrls: ['./collapsible-permission-category.component.scss']
})
export class CollapsiblePermissionCategoryComponent implements OnInit {
  @Input() title: string;
  @Input() permissionsItems: any;
  isChecked: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  changeValue(element: any) {
    element.isSelected = !element.isSelected;
  }


}
