import { Component, OnInit } from '@angular/core';
import { Paginated } from 'src/app/shared/models/shipment/paginated.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { ProductRegistrationService } from '../../services/product-registration.service';
import { LocalStoreManager } from 'src/app/shared/services/local-store-manager.service';
import { SortFilter } from 'src/app/shared/models/shipment/sort-filter.enum';
import { ModuleName } from 'src/app/shared/models/enums/module-name.enum';
import { ListFilters } from 'src/app/shared/models/list-filters.model';
import { ProducFilters } from '../../models/produc-filters.model';
import { HelperService } from 'src/app/shared/services/helper.service';
import { ProductRegistrationColumnsService } from '../../services/product-registration-columns.service';
import { ProductRegistrationViewColumn } from '../../models/product-registration-view-column.model';
import { ProductRegistrationHelperService } from 'src/app/create-product-registration/service/product-registration-helper.service';
import { ProductRegistrationSearchCriteria } from '../../models/product-registration-search-criteria.model';
import { RegistrationTabs } from 'src/app/shared/models/enums/registration-tabs.enum';
import { CardViewEnum } from 'src/app/shipments/enums/card-view.enum';

@Component({
  selector: 'app-product-registration-list',
  templateUrl: './product-registration-list.component.html',
  styleUrls: ['./product-registration-list.component.scss']
})
export class ProductRegistrationListComponent implements OnInit {
  productsPaginated: Paginated;
  productSearchCriteria: ProductRegistrationSearchCriteria = {
    companyId: this.defaultCompanyId,
    pageIndex: 0,
    pageSize: 10,
    productsSortBy: SortFilter.DateCreatedNewest,
    viewTab: 'All'
  };
  productSearchSubscriber;
  registrationColumns: ProductRegistrationViewColumn[];
  moduleName = ModuleName;

  productsSortFilters: SortFilter[] = [
    SortFilter.MostRecentlyUpdated,
    SortFilter.DateCreatedNewest,
    SortFilter.DateCreatedOldest
  ];

  producFilters: ProducFilters;
  listFilters: ListFilters[];

  isAllSelected: boolean = false;

  tableViewColumns: string[];
  employeeSelectedColumns: ProductRegistrationViewColumn[];
  employeeSelectedColumnsNames: string[];
  fixedColumns: ProductRegistrationViewColumn[];
  registrationTabs = RegistrationTabs;
  selectedCardView: CardViewEnum = CardViewEnum.Table;

  constructor(
    private authService: AuthService,
    private activeRoute: ActivatedRoute,
    private localStorage: LocalStoreManager,
    private productRegistrationService: ProductRegistrationService,
    private helperService: HelperService,
    private productRegistrationColumnsService: ProductRegistrationColumnsService,
    private productRegistrationHelperService: ProductRegistrationHelperService
  ) {}

  ngOnDestroy(): void {
    this.productSearchSubscriber.unsubscribe();
  }

  ngOnInit(): void {
    this.activeRoute.data.subscribe(data => {
      this.productsPaginated = data['paginatedProducts'];
      this.employeeSelectedColumns =
        data['employeeSelectedColumns'] ||
        this.localStorage.getData('productRegistrationSelectedColumns');
      this.registrationColumns = data['registrationColumns'];
    });

    this.processColumns();

    this.initSearchCriteriaKeyword();
    this.getProductFilters();
  }

  ngAfterViewInit(): void {
    this.addSelectedFilters();
    this.productRegistrationService.productRegistrationSearch.emit(
      this.productSearchCriteria
    );
    this.initSubscribers();
  }

  initSubscribers() {
    this.productRegistrationColumnsService.proudctRegistrationDisplayedColumnsEmitter.subscribe(
      data => {
        this.employeeSelectedColumns = data.columns;
        this.employeeSelectedColumnsNames = this.employeeSelectedColumns.map(
          column => column.name
        );
        this.employeeSelectedColumns = this.fixedColumns?.concat(
          this.employeeSelectedColumns
        );
        this.employeeSelectedColumns = this.productRegistrationHelperService.sortColumns(
          this.employeeSelectedColumns
        );
      }
    );
  }

  initSearchCriteriaKeyword() {
    this.productSearchSubscriber = this.productRegistrationService.productRegistrationSearch.subscribe(
      productSearchCriteria => {
        this.productSearchCriteria = productSearchCriteria;
        this.localStorage.saveSyncedSessionData(
          this.productSearchCriteria,
          'productsFilters'
        );
        this.getProducts();
      }
    );
  }

  get defaultCompanyId(): string {
    return this.authService.currentUser?.defaultCustomerCompany;
  }

  getProducts(pageIndex?: number) {
    this.productSearchCriteria.pageIndex = pageIndex;
    this.productRegistrationService
      .getProducts(this.productSearchCriteria, this.defaultCompanyId)
      .subscribe(data => {
        this.productsPaginated = data;
      });
  }

  handlePageEvent(event: PageEvent): void {
    if (event?.pageIndex !== this.productsPaginated.pageIndex) {
      this.getProducts(event?.pageIndex);
    }
  }

  selectViewTabFilter(viewTabFilter: string): void {
    this.productSearchCriteria.viewTab = viewTabFilter;
    this.productRegistrationService.productRegistrationSearch.emit(
      this.productSearchCriteria
    );
  }

  addSelectedFilters() {
    let filters: ProductRegistrationSearchCriteria = this.localStorage.getData(
      'productsFilters'
    );
    if (filters) {
      this.productSearchCriteria.systemStatuses = filters.systemStatuses;
      this.productSearchCriteria.statuses = filters.statuses;
      this.productSearchCriteria.progresses = filters.progresses;
      this.productSearchCriteria.productsSortBy = filters.productsSortBy;
      this.productSearchCriteria.categories = filters.categories;
    }
  }

  getProductFilters() {
    this.helperService.getProductFilters().subscribe(filters => {
      this.producFilters = filters;
      this.initListFilters();
    });
  }

  initListFilters() {
    this.listFilters = [
      {
        entityName: 'state',
        controlName: 'systemStatuses',
        listItems: this.producFilters?.states
      },
      {
        entityName: 'progress',
        controlName: 'progresses',
        listItems: this.producFilters?.progresses
      },
      {
        entityName: 'status',
        controlName: 'statuses',
        listItems: this.producFilters?.statuses
      },
      {
        entityName: 'category Classification',
        controlName: 'categoryClassifications',
        listItems: this.producFilters?.categories
      }
    ];
  }

  onSelectAllChange(event) {
    this.isAllSelected = event;
    this.productRegistrationService.removeAllFromListOfSelectedProducts();
  }

  get employeeId(): string {
    return this.authService.currentUser?.employeeId;
  }

  processColumns() {
    this.tableViewColumns = this.registrationColumns
      .filter(x => x.isFixed != true)
      .map(column => column.name);

    this.fixedColumns = this.registrationColumns.filter(x => x.isFixed);
    let isFixedColumnsInSelectedColumns = this.employeeSelectedColumns?.some(
      column => column.name == this.fixedColumns[0]?.name
    );

    if (this.fixedColumns?.length > 0 && !isFixedColumnsInSelectedColumns) {
      this.employeeSelectedColumns =
        this.employeeSelectedColumns?.length > 0
          ? this.fixedColumns?.concat(this.employeeSelectedColumns)
          : [...this.fixedColumns];
    }
    if (this.employeeSelectedColumns?.length > 0) {
      this.employeeSelectedColumns = this.productRegistrationHelperService.sortColumns(
        this.employeeSelectedColumns
      );
      this.employeeSelectedColumnsNames = this.employeeSelectedColumns?.map(
        column => column.name
      );
    }
  }
}
