import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-deletion',
  templateUrl: './confirmation-deletion.component.html',
  styleUrls: ['./confirmation-deletion.component.scss']
})
export class ConfirmationDeletionComponent implements OnInit {
  moduleName: string;
  popUpTitle?: string;
  popUpDescription?: string;
  cancelButtonTitle?: string;
  constructor(
    public dialogRef: MatDialogRef<boolean>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.moduleName = this.data['moduleName'];
      this.popUpTitle = this.data['popUpTitle'];
      this.popUpDescription = this.data['popUpDescription'];
      this.cancelButtonTitle = this.data['cancelButtonTitle'];
    }
  }

  confirm(isDelete: boolean): void {
    this.dialogRef.close(isDelete);
  }

  get popUpTitleMessage(): string {
    return (
      this.popUpTitle ||
      `Are you sure you want to delete this ${this.moduleName}?`
    );
  }

  get popUpDescriptionMessage(): string {
    return (
      this.popUpDescription ||
      `All ${this.moduleName.toLowerCase()} information will be gone.`
    );
  }

  get cancelButtonTitleMessage(): string {
    return this.cancelButtonTitle || `Cancel`;
  }
}
