import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { fadeInOut } from '../../services/animations';
import { BootstrapTabDirective } from '../../directives/bootstrap-tab.directive';
import { AccountService } from '../../services/account.service';
import { Permission } from '../../models/permission.model';
import { UserPermissions } from '../../models/enums/user-permission.enum';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: [fadeInOut]
})
export class SettingsComponent implements OnInit, OnDestroy {
  isUsersActivated = false;
  isRolesActivated = false;
  isConfigActivated = false;

  fragmentSubscription: any;

  readonly usersTab = 'users';
  readonly rolesTab = 'roles';
  readonly configTab = 'config';

  @ViewChild('tab', { static: true })
  tab: BootstrapTabDirective;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.fragmentSubscription = this.route.fragment.subscribe(anchor =>
      this.showContent(anchor)
    );
  }

  ngOnDestroy() {
    this.fragmentSubscription.unsubscribe();
  }

  showContent(anchor: string) {
    if (anchor) {
      anchor = anchor.toLowerCase();
    }

    if (
      (this.isFragmentEquals(anchor, this.usersTab) && !this.canViewUsers) ||
      (this.isFragmentEquals(anchor, this.rolesTab) && !this.canViewRoles) ||
      (this.isFragmentEquals(anchor, this.configTab) && !this.canViewConfig)
    ) {
      return;
    }

    this.tab.show(`#${anchor}Tab`);
  }

  isFragmentEquals(fragment1: string, fragment2: string) {
    if (fragment1 == null) {
      fragment1 = '';
    }

    if (fragment2 == null) {
      fragment2 = '';
    }

    return fragment1.toLowerCase() === fragment2.toLowerCase();
  }

  onShowTab(event) {
    const activeTab = event.target.hash.split('#', 2).pop();

    this.isUsersActivated = activeTab === this.usersTab;
    this.isRolesActivated = activeTab === this.rolesTab;
    this.isConfigActivated = activeTab === this.configTab;

    this.router.navigate([], { fragment: activeTab });
  }

  get canViewUsers(): boolean {
    return this.authService.canViewUsers;
  }

  get canViewRoles(): boolean {
    return this.accountService.userHasPermissionTo(UserPermissions.ViewRole);
  }

  get canViewConfig() {
    return this.accountService.userHasPermission(
      Permission.viewConfigPermission
    );
  }
}
