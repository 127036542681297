import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UnauthorizedCompanyAccessGuard implements CanActivate {
  constructor(private authService: AuthService, private location: Location) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      (this.isCurrentModule(state, 'create-quote-proposal') &&
        !this.authService.canCreateQuoteProposal) ||
      (this.isCurrentModule(state, 'createquote') &&
        !this.authService.canCreateQuote) ||
      (this.isCurrentModule(state, 'quotes') && !this.authService.canViewQuotes)
    ) {
      return false;
    }

    const companyId = route?.params?.companyId;
    if (this.authService.canAccessCompanyShipments(companyId)) {
      if (companyId && companyId != this.defaultcompany) {
        this.authService.updateDefaultCompanyDataForCurrentUser(
          companyId,
          this.authService.currentIndustry,
          false
        );
      }

      return true;
    }

    this.navigateToBack();
    return false;
  }

  get defaultcompany() {
    return this.authService?.currentUser?.defaultCustomerCompany;
  }

  navigateToBack(): void {
    this.location.back();
  }

  isCurrentModule(state: RouterStateSnapshot, moduleName: string): boolean {
    return state.url?.includes(moduleName);
  }
}
