import { Component, OnInit, Input } from '@angular/core';
import { ProductBase } from '../../../shared/models/product-library/product-base.model';
import { ProductInformationSummary } from 'src/app/create-product/enums/product-information-summary';
import { ProductHelperService } from 'src/app/create-product/services/product-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MedicalComplianceInformationSummary } from 'src/app/create-product/enums/medical-compliance-information-summary';
import { NonMedicalComplianceInformationSummary } from 'src/app/create-product/enums/non-medical-compliance-information-summary';
import { ComplianceInformationSummary } from 'src/app/create-product/enums/compliance-information-summary';

@Component({
  selector: 'app-product-details-summary',
  templateUrl: './product-details-summary.component.html',
  styleUrls: ['./product-details-summary.component.scss']
})
export class ProductDetailsSummaryComponent implements OnInit {
  @Input() product: ProductBase;
  complianceInformationSummary = ComplianceInformationSummary;
  productInformationSummary = ProductInformationSummary;
  medicalComplianceInformationSummary = MedicalComplianceInformationSummary;
  nonMedicalComplianceInformationSummary = NonMedicalComplianceInformationSummary;

  constructor(
    private productHelperService: ProductHelperService,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  getProductInformationSummaryContent(
    columnName: ProductInformationSummary
  ): string {
    switch (columnName) {
      case ProductInformationSummary.ProductName:
        return this.productHelperService?.getProductName(this.product);

      case ProductInformationSummary.ProductCategory:
        return this.productHelperService?.getProductCategory(this.product);

      case ProductInformationSummary.SerialNumber:
        return this.productHelperService?.getSerialNumber(this.product);

      case ProductInformationSummary.ArticleNumber:
        return this.productHelperService?.getArticleNumber(this.product);

      case ProductInformationSummary.ISBN:
        return this.productHelperService?.getISBN(this.product);

      case ProductInformationSummary.UPC:
        return this.productHelperService?.getUPC(this.product);

      case ProductInformationSummary.GTIN:
        return this.productHelperService?.getGTIN(this.product);

      case ProductInformationSummary.HSCode:
        return this.productHelperService?.getHSCode(this.product);

      case ProductInformationSummary.ActualManufacturer:
        return this.productHelperService?.getActualManufacturer(this.product);

      case ProductInformationSummary.LegalManufacturer:
        return this.productHelperService?.getLegalManufacturer(this.product);

      case ProductInformationSummary.COO:
        return this.productHelperService?.getCOO(this.product);

      case ProductInformationSummary.DangerousGoods:
        return this.productHelperService?.getDangerousGoods(this.product);

      default:
        return '';
    }
  }

  getComplianceInformationSummaryContent(
    columnName: ComplianceInformationSummary
  ): string {
    switch (columnName) {
      case ComplianceInformationSummary.CategoryClassification:
        return this.productHelperService?.getCategoryClassification(
          this.product
        );

      case ComplianceInformationSummary.Entity:
        return this.productHelperService?.getEntity(this.product);

      case ComplianceInformationSummary.Sterility:
        return this.productHelperService?.getSterility(this.product);

      case ComplianceInformationSummary.Regulations:
        return this.productHelperService?.getRegulations(this.product);

      case ComplianceInformationSummary.ClassificationType:
        return this.productHelperService?.getClassificationType(this.product);

      case ComplianceInformationSummary.CertificationType:
        return this.productHelperService?.getCertificationType(this.product);

      case ComplianceInformationSummary.RequiredRegistration:
        return this.productHelperService?.getRequiredRegistration(this.product);

      case ComplianceInformationSummary.Tax:
        return this.productHelperService?.getImportTax(this.product);

      case ComplianceInformationSummary.VAT:
        return this.productHelperService?.getVAT(this.product);

      case ComplianceInformationSummary.RequiredApprovals:
        return this.productHelperService?.getRequiredApprovals(this.product);

      case ComplianceInformationSummary.RequiredInspections:
        return this.productHelperService?.getRequiredInspections(this.product);

      default:
        return '';
    }
  }

  getEnumKeys(enumName): string[] {
    return Object.keys(enumName);
  }

  getEnumKeysForCompliance(): string[] {
    return this.isMedicalIndsutry
      ? Object.keys(MedicalComplianceInformationSummary)
      : Object.keys(NonMedicalComplianceInformationSummary);
  }

  getEnumValue(enumName, key: string): string {
    return enumName[key];
  }

  get isEditRoute(): boolean {
    return this.router.url.includes('product-details');
  }

  get isMedicalIndsutry() {
    return this.authService.isMedicalOrPharmaceuticalIndustry;
  }

  get isEditProductFromApproval(): boolean {
    return this.router.url.includes('approval-product-details');
  }

  handleRouting(): void {
    if (this.isEditProductFromApproval) {
      this.router.navigate(['./edit-product'], { relativeTo: this.route });
    } else {
      this.router.navigate(
        ['../../create-product/', this.product?.id, 'edit-product', 'details'],
        { relativeTo: this.route }
      );
    }
  }
}
