import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Paginated } from 'src/app/shared/models/shipment/paginated.model';
import { environment } from 'src/environments/environment';
import { QuoteSearchCriteria } from '../models/quote-search-criteria.model';
import { StatusChangeRequest } from '../models/status-change-request';
import { QuoteRatesLookUp } from '../models/quote-rates-lookUp.model';
import { FreightRoute } from 'src/app/shared/quote/models/freight-route.model';
import { CustomsClearanceRate } from 'src/app/shared/quote/models/customs-clearance-rate.model';
import { Charge } from 'src/app/shared/models/charge.model';
import { IorEorRate } from 'src/app/shared/quote/models/ior-eor-rate';
import { QuoteComment } from '../models/quote-comment.model';
import { QuoteRates } from 'src/app/shared/quote/models/quote-rates.model';
import { Carrier } from 'src/app/shared/models/carrier.model';
import { TruckingRateLookup } from '../models/trucking-rate-lookup.model';
import { ContainerType } from 'src/app/shared/models/container-type.model';
import { CommercialTransactionParty } from 'src/app/company/models/commercial-transaction-party.model';
import { TruckingRateViewModel } from '../models/trucking-rate-view-model.model';
import { PortDto } from 'src/app/admin-portal-configs/models/portDto.model';
import { QuoteBase } from 'src/app/shared/quote/models/quote-base.model';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  baseURL = environment.apiUrl + 'Quote';

  @Output() quoteUpdated = new EventEmitter();
  @Output() onCommentsUpdate: EventEmitter<QuoteComment> = new EventEmitter();
  @Output() quoteSearch: EventEmitter<QuoteSearchCriteria> = new EventEmitter<
    QuoteSearchCriteria
  >();
  @Output() quoteTabChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() onQuoteScroll = new EventEmitter();

  constructor(private http: HttpClient) {}

  getQuotes(
    searchCriteria: QuoteSearchCriteria,
    companyId: string
  ): Observable<Paginated> {
    return this.http.post<Paginated>(
      this.baseURL + `/Filter/Company/${companyId}`,
      searchCriteria
    );
  }

  cancelAndDeleteQuote(
    statusChangeRequest: StatusChangeRequest
  ): Observable<any> {
    return this.http.post<any>(
      this.baseURL + '/CancelAndDelete',
      statusChangeRequest
    );
  }

  submitQuoteProposal(
    quoteId: string,
    quoteRates: QuoteRates
  ): Observable<any> {
    return this.http.post<any>(
      `${this.baseURL}/${quoteId}/SubmitQuoteProposal`,
      quoteRates
    );
  }

  updateQuoteStatus(quoteId: string, statusName: string) {
    return this.http.put<any>(
      this.baseURL + `/${quoteId}/status?statusName=${statusName}`,
      null
    );
  }

  createQuoteProposal(quoteId: string, companyId: number) {
    return this.http.put<any>(
      this.baseURL + `/${quoteId}/CreateQuoteProposal/Company/${companyId}`,
      null
    );
  }

  createQuoteProposalNewVersion(quoteId: string) {
    return this.http.put<any>(
      this.baseURL + `/${quoteId}/QuoteProposalNewVersion`,
      null
    );
  }

  toggleRateSelection(quoteId: string, rateName: string) {
    return this.http.post<any>(
      this.baseURL + `/${quoteId}/RateSelection/${rateName}`,
      null
    );
  }

  getQuoteRatesLookUpData(id: string): Observable<QuoteRatesLookUp> {
    return this.http.get<QuoteRatesLookUp>(
      this.baseURL + `/${id}/quoteRateLookUp`
    );
  }

  getFreightRateLookup(freightReference?: string): Observable<Carrier[]> {
    return this.http.get<Carrier[]>(
      this.baseURL + `/FreightLookup/${freightReference}`
    );
  }

  getTruckingRateLookup(id: string): Observable<TruckingRateLookup> {
    return this.http.get<TruckingRateLookup>(
      this.baseURL + `/${id}/TruckingLookup`
    );
  }

  getCustomsClearanceRateLookup(): Observable<ContainerType[]> {
    return this.http.get<ContainerType[]>(
      this.baseURL + `/CustomsClearanceLookup`
    );
  }

  getImportExportRateLookup(
    serviceName: string
  ): Observable<CommercialTransactionParty[]> {
    return this.http.get<CommercialTransactionParty[]>(
      this.baseURL + `/ImportExportLookup/${serviceName}`
    );
  }

  updateTruckingRate(quoteId: string, truckingRate: TruckingRateViewModel) {
    return this.http.put<any>(
      this.baseURL + `/${quoteId}/TruckingRate`,
      truckingRate
    );
  }

  updateDomesticTruckingRate(
    quoteId: string,
    truckingRate: TruckingRateViewModel
  ) {
    return this.http.put<QuoteBase>(
      this.baseURL + `/${quoteId}/DomesticTruckingRate`,
      truckingRate
    );
  }

  updateFreightRoutes(quoteId: string, freightRoutes: FreightRoute[]) {
    return this.http.put<any>(
      this.baseURL + `/${quoteId}/FreightRoute`,
      freightRoutes
    );
  }

  updateCustomsClearanceRates(
    quoteId: string,
    customsClearanceRates: CustomsClearanceRate[]
  ) {
    return this.http.put<any>(
      this.baseURL + `/${quoteId}/CustomsClearanceRate`,
      customsClearanceRates
    );
  }

  updateCharges(quoteId: string, charges: Charge[]) {
    return this.http.put<any>(this.baseURL + `/${quoteId}/Charge`, charges);
  }

  updateIorEorRate(quoteId: string, iorEorRate: IorEorRate) {
    return this.http.put<any>(
      this.baseURL + `/${quoteId}/IorEorRate`,
      iorEorRate
    );
  }

  addCommentToQuote(quoteComment: QuoteComment) {
    return this.http.post<QuoteComment>(
      `${this.baseURL}/Comment`,
      quoteComment
    );
  }

  getQuoteComments(quoteId: string): Observable<QuoteComment[]> {
    return this.http.get<QuoteComment[]>(
      `${this.baseURL}/Comment?quoteId=${quoteId}`
    );
  }

  approveQuoteProposal(quoteId: string): Observable<any> {
    return this.http.put<any>(
      `${this.baseURL}/${quoteId}/QuoteProposalApproval`,
      null
    );
  }

  rejectAndDeleteQuote(
    statusChangeRequest: StatusChangeRequest
  ): Observable<any> {
    return this.http.post<any>(
      this.baseURL + '/QuoteProposalRejection',
      statusChangeRequest
    );
  }

  removeDocument(quotetId: string, documentSqlId: number): Observable<any> {
    return this.http.delete(
      this.baseURL + `/Document/${documentSqlId}?quoteId=${quotetId}`
    );
  }

  markCommentPendingActionAsFulfilled(quoteId: string, commentId: number) {
    return this.http.post<any>(
      `${this.baseURL}/PendingAction/${quoteId}/Fulfill/${commentId}`,
      null
    );
  }

  addCommentPendingActionToQuote(quoteComment: QuoteComment) {
    return this.http.post<any>(
      `${this.baseURL}/Comment?isPendingAction=${true}`,
      quoteComment
    );
  }

  getTruckingRatePortsAsync(quoteId: string): Observable<PortDto[]> {
    return this.http.get<PortDto[]>(
      this.baseURL + `/${quoteId}/TruckingRatePort`
    );
  }
}
