<div class="product-registration-details-container">
  <app-product-registration-comment-section
    class="product-comments-section"
    [productRegistrationId]="productId"
    [commentWithActionActors]="productRegistrationCommentWithActionActors"
    [newCommentAdded]="newProductRegistrationCommentWithActionActor"
  >
  </app-product-registration-comment-section>

  <app-warning-badge
    *ngIf="isCustomer && hasPendingActions"
    class="warning-badge"
    [message]="
      'Account Manager Has Set Pending Comment, Please Fulfil the Requirements.'
    "
  ></app-warning-badge>
  <div class="tabs">
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="Overview">
        <ng-template matTabContent>
          <div class="title d-flex justify-content-between">
            <div>
              <svg-icon name="chargesSummary"></svg-icon> REGISTRATION DETAILS
            </div>

            <button
              [routerLink]="[
                '../../create-product-registration/',
                productId,
                'edit-product',
                'product-registration-details'
              ]"
              class="edit-section-button"
              [class]="isEditDetailsDisabled ? 'disabled' : ''"
              [disabled]="isEditDetailsDisabled"
            >
              <svg-icon name="editQuote"></svg-icon>
              Edit
            </button>
          </div>

          <app-product-data-summary
            [productRegistration]="
              productRegistrationDetails?.productRegistration
            "
            [productsDetails]="productRegistrationDetails?.productDetails"
          ></app-product-data-summary>
        </ng-template>
      </mat-tab>
      <mat-tab label="Activity Log">
        <ng-template matTabContent>
          <app-progress-point
            *ngFor="
              let progressAndActivityLogs of productRegistrationDetails.progressAndActivityLogs;
              let i = index
            "
            [isDisabled]="i > currentProgressPointIndex"
            [isCurrentProgress]="
              progressAndActivityLogs?.progress?.name ==
              currentProgressPointName
            "
            [progressAndActivityLogs]="progressAndActivityLogs"
            [isDisabletrackingLine]="
              i ==
              productRegistrationDetails?.progressAndActivityLogs?.length - 1
            "
            [productRegistrationComments]="
              productRegistrationCommentWithActionActors
            "
            [productId]="productId"
          ></app-progress-point>
        </ng-template>
      </mat-tab>
      <mat-tab label="Charges">
        <ng-template matTabContent> </ng-template>
      </mat-tab>
      <mat-tab label="Products">
        <ng-template matTabContent>
          <app-products-list-tab
            [moduleName]="productRegistrationModuleName"
            [products]="productRegistrationDetails?.productDetails"
            [parentId]="productRegistrationDetails?.productRegistration?.id"
          ></app-products-list-tab>
        </ng-template>
      </mat-tab>
      <mat-tab label="Lead Times">
        <ng-template matTabContent>
          <app-lead-times
            [productRegistrationLeadTimes]="
              productRegistrationDetails?.productLeadTimeLogs
            "
          ></app-lead-times>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
