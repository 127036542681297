<div class="container settings-page">
  <header class="pageHeader">
    <h3>
      <i class="fa fa-cog fa-lg page-caption" aria-hidden="true"></i>
      {{ "pageHeader.Settings" | translate }}
    </h3>
  </header>

  <div [@fadeInOut]>
    <div class="d-sm-flex flex-row">
      <ul
        appBootstrapTab
        #tab="bootstrap-tab"
        (showBSTab)="onShowTab($event)"
        class="nav nav-tabs nav-tabs--vertical nav-tabs--left"
        role="navigation"
      >
        <li [hidden]="!canViewUsers" class="nav-item">
          <a
            id="usersTab"
            [routerLink]="[]"
            fragment="users"
            data-target="#users"
            href="#users"
            class="nav-link"
            data-toggle="tab"
            role="tab"
            aria-controls="users"
          >
            <i class="fa fa-users fa-fw" aria-hidden="true"></i>
            {{ "settings.tab.Users" | translate }}
          </a>
        </li>
        <li [hidden]="!canViewRoles" class="nav-item">
          <a
            id="rolesTab"
            [routerLink]="[]"
            fragment="roles"
            data-target="#roles"
            href="#roles"
            class="nav-link"
            data-toggle="tab"
            role="tab"
            aria-controls="roles"
          >
            <i class="fa fa-shield fa-fw" aria-hidden="true"></i>
            {{ "settings.tab.Roles" | translate }}
          </a>
        </li>
        <li [hidden]="!canViewConfig" class="nav-item">
          <a
            id="configTab"
            [routerLink]="[]"
            fragment="config"
            data-target="#config"
            href="#config"
            class="nav-link"
            data-toggle="tab"
            role="tab"
            aria-controls="config"
          >
            <i class="fa fa-cog fa-fw" aria-hidden="true"></i>
            {{ "settings.tab.Config" | translate }}
          </a>
        </li>
      </ul>
      <div class="tab-content w-100">
        <div class="tab-pane" id="users" role="tabpanel">
          <h4>{{ "settings.header.UsersManagements" | translate }}</h4>
          <hr class="separator-hr" />
          <div
            [@fadeInOut]
            *ngIf="canViewUsers && isUsersActivated"
            class="content-container pl-lg-1"
          >
            <app-users-management></app-users-management>
          </div>
        </div>
        <div class="tab-pane" id="roles" role="tabpanel">
          <h4>{{ "settings.header.RolesManagement" | translate }}</h4>
          <hr class="separator-hr" />
          <div
            [@fadeInOut]
            *ngIf="canViewRoles && isRolesActivated"
            class="content-container pl-lg-1"
          >
            <app-roles-management></app-roles-management>
          </div>
        </div>
        <div class="tab-pane" id="config" role="tabpanel">
          <h4>{{ "settings.header.ConfigManagement" | translate }}</h4>
          <hr class="separator-hr" />
          <div
            [@fadeInOut]
            *ngIf="canViewConfig && isConfigActivated"
            class="content-container pl-lg-1"
          >
            <app-configs-list></app-configs-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
