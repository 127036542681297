import { EventEmitter, Injectable, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { QuoteStatus } from 'src/app/quote/enums/quote-status.enum';
import {
  airFreightExpressFactor,
  airFreightFactor,
  convertCCMToCBM,
  oceanLCLBBFactor,
  truckingLTLFactor
} from 'src/app/shared/constants/factors';
import { Charge } from 'src/app/shared/models/charge.model';
import { Currency } from 'src/app/shared/models/currency.model';
import { ContainerTitle } from 'src/app/shared/models/enums/container-title.enum';
import { OceanFreightOptionsEnum } from 'src/app/shared/models/enums/ocean-freight.enum';
import { ShipmentFreightType } from 'src/app/shared/models/enums/shipment-freight-type.enum';
import { TransactionType } from 'src/app/shared/models/enums/transaction-type.enum';
import { TruckingLoadType } from 'src/app/shared/models/enums/trucking-load-type.enum';
import { ServiceTypes } from 'src/app/shared/models/shipment/service-types.enum';
import { CustomsClearanceRate } from 'src/app/shared/quote/models/customs-clearance-rate.model';
import { FreightRoute } from 'src/app/shared/quote/models/freight-route.model';
import { QuoteBase } from 'src/app/shared/quote/models/quote-base.model';
import { QuoteCargoContainer } from 'src/app/shared/quote/models/quote-cargo-container.model';
import { QuoteProposalDetails } from 'src/app/shared/quote/models/quote-proposal-details.model';
import { QuoteRates } from 'src/app/shared/quote/models/quote-rates.model';
import { QuoteServices } from 'src/app/shared/quote/models/quote-services.model';
import { Utilities } from 'src/app/shared/services/utilities';
import { ContainerTypeEnum } from '../../shared/models/enums/container-type.enum';
import { PortDto } from 'src/app/admin-portal-configs/models/portDto.model';
import { District } from 'src/app/shared/models/district.models';
import { DomesticTruckingInformation } from 'src/app/shared/models/domestic-trucking-information.model';
import { QuoteDomesticTruckingAddress } from 'src/app/shared/quote/models/domestic-trucking-address.model';
import { RouteInformation } from 'src/app/shared/models/route-information.model';
import { TruckingRate } from 'src/app/shared/quote/models/trucking-rate.model';
import { QuoteRoutingDetails } from 'src/app/shared/quote/models/quote-routing-details.model';
import { LocalStoreManager } from 'src/app/shared/services/local-store-manager.service';
import { LandTrucking } from 'src/app/shared/models/land-trucking';
import { QuoteViewColumn } from 'src/app/quote/models/quote-view-column.model';

@Injectable({
  providedIn: 'root'
})
export class CreateQuoteHelperService {
  @Output() selectedColumnsChanged = new EventEmitter<QuoteViewColumn[]>();
  @Output() selectedQuotesChanged = new EventEmitter<string[]>();

  private selectedQuoteIds: string[] = [];

  airFreightFactor: number = airFreightFactor;
  oceanLCLBBFactor: number = oceanLCLBBFactor;
  truckingLTLFactor: number = truckingLTLFactor;
  convertCCMToCBM: number = convertCCMToCBM;
  airFreightExpressFactor: number = airFreightExpressFactor;

  constructor(private localStorage: LocalStoreManager) {}

  getSelectedServicesNames(services: QuoteServices) {
    var servicesNames: string[] = [];

    if (this.isSelectedTruckingService(services)) {
      if (services?.trucking?.truckingType == ServiceTypes.Domestic) {
        servicesNames.push(ServiceTypes.DomesticTrucking);
      } else {
        servicesNames.push(ServiceTypes.Trucking);
      }
    }

    if (this.isSelectedEORService(services)) {
      servicesNames.push(ServiceTypes.EOR);
    }

    if (this.isSelectedIORService(services)) {
      servicesNames.push(ServiceTypes.IOR);
    }

    if (this.isSelectedFreightService(services)) {
      servicesNames.push(ServiceTypes.Freight);
    }

    if (this.isSelectedCustomsClearanceService(services)) {
      servicesNames.push(ServiceTypes.CustomsClearance);
    }

    servicesNames.push(ServiceTypes.AccountManagement);

    return servicesNames;
  }

  isSelectedIORService(services: QuoteServices): boolean {
    return services?.importExport?.serviceType == ServiceTypes.IOR;
  }

  isSelectedEORService(services: QuoteServices): boolean {
    return services?.importExport?.serviceType == ServiceTypes.EOR;
  }

  isSelectedEORwithoutTruckingService(services: QuoteServices): boolean {
    return (
      this.isSelectedEORService(services) &&
      !this.isSelectedFreightService(services) &&
      !this.isSelectedIORService(services) &&
      !this.isSelectedCustomsClearanceService(services)
    );
  }

  getIorOrEorQuote(quote: QuoteBase): string {
    let isIorService =
      this.isImport(quote?.quoteServices) ||
      this.isImportTransactionType(quote);
    if (isIorService) {
      return ServiceTypes.IOR;
    }

    return ServiceTypes.EOR;
  }

  isSelectedTruckingService(services: QuoteServices) {
    return services?.trucking != null;
  }

  isSelectedDomesticService(services: QuoteServices): boolean {
    return services?.trucking?.truckingType == ServiceTypes.Domestic;
  }

  isSelectedImportExportService(services: QuoteServices) {
    return services?.importExport != null;
  }

  isSelectedCustomsClearanceService(services: QuoteServices) {
    return services?.customsClearance?.isSelected;
  }

  isSelectedFreightService(services: QuoteServices) {
    return services?.freight != null;
  }

  isSelectedPortToDoor(services: QuoteServices) {
    return services?.trucking?.truckingType == ServiceTypes.PortToDoor;
  }

  isSelectedDoorToPort(services: QuoteServices) {
    return services?.trucking?.truckingType == ServiceTypes.DoorToPort;
  }

  isSelectdTruckingStandalone(services: QuoteServices) {
    return (
      this.isSelectedTruckingService(services) &&
      !this.isSelectedFreightService(services) &&
      !this.isSelectedEORService(services) &&
      !this.isSelectedIORService(services) &&
      !this.isSelectedCustomsClearanceService(services)
    );
  }

  isSelectedDoorToPortOrPortToDoorStandAlone(services: QuoteServices): boolean {
    return (
      this.isSelectdTruckingStandalone(services) &&
      !this.isSelectedDomesticService(services)
    );
  }

  getImportExportValue(services: QuoteServices) {
    return services?.importExport?.serviceType;
  }

  getTruckingValue(services: QuoteServices) {
    return services?.trucking?.truckingType;
  }

  getFreightValue(services: QuoteServices) {
    return services?.freight?.isAir
      ? ShipmentFreightType.Air
      : services?.freight?.isOcean
      ? ShipmentFreightType.Ocean
      : null;
  }

  getCustomsClearanceValue(services: QuoteServices) {
    return services?.customsClearance;
  }

  getCustomsClearanceRates(quote: QuoteBase) {
    return quote?.quoteProposalDetails?.quoteRates?.customsClearanceRates;
  }

  getTruckingRate(quoteProposalDetails: QuoteProposalDetails) {
    return quoteProposalDetails?.quoteRates?.truckingRate;
  }

  getDomesticTruckingRate(
    quoteProposalDetails: QuoteProposalDetails
  ): TruckingRate {
    return quoteProposalDetails?.quoteRates?.domesticTruckingRate;
  }

  getIorOrEorRate(quoteProposalDetails: QuoteProposalDetails) {
    return quoteProposalDetails?.quoteRates?.iorEorRate;
  }

  getFreightValueNames(services: QuoteServices) {
    var result: string[] = [];

    if (this.isAirService(services)) {
      result.push(ShipmentFreightType.Air);
    }

    if (this.isOceanService(services)) {
      result.push(ShipmentFreightType.Ocean);
    }

    if (this.isLandService(services)) {
      result.push(ShipmentFreightType.Land);
    }

    return result;
  }

  getTransactionType(services: QuoteServices) {
    const importType = this.isImport(services);
    const exportType = this.isExport(services);

    return importType
      ? TransactionType.Import
      : exportType
      ? TransactionType.Export
      : null;
  }

  getTransactionTypeFromSavedQuote(quote: QuoteBase): string {
    return (
      quote?.quoteServices?.transactionType ||
      this.getTransactionType(quote?.quoteServices)
    );
  }

  getCustomsClearanceFCLRate(quote: QuoteBase): CustomsClearanceRate {
    let customsClearanceFCLRate = quote?.quoteProposalDetails?.quoteRates?.customsClearanceRates?.find(
      x => x.loadType == OceanFreightOptionsEnum.FCL
    );
    return customsClearanceFCLRate;
  }

  isImportTransactionType(quote: QuoteBase) {
    let transactionType = this.getTransactionTypeFromSavedQuote(quote);
    if (transactionType == TransactionType.Import) {
      return true;
    }
  }

  isAirService(services: QuoteServices) {
    return services?.freight?.isAir;
  }

  isOceanService(services: QuoteServices) {
    return services?.freight?.isOcean;
  }

  isLandService(services: QuoteServices): boolean {
    return services?.freight?.isLand;
  }

  isLandAirService(services: QuoteServices): boolean {
    return this.isLandService(services) && this.isAirService(services);
  }

  isLandOceanService(services: QuoteServices): boolean {
    return this.isLandService(services) && this.isOceanService(services);
  }

  isLandStandaloneService(services: QuoteServices): boolean {
    let freightServices = this.getFreightValueNames(services);

    return (
      freightServices?.length === 1 &&
      freightServices[0] === ShipmentFreightType.Land
    );
  }

  isImport(services: QuoteServices) {
    return (
      this.isSelectedPortToDoor(services) || this.isSelectedIORService(services)
    );
  }

  isExport(services: QuoteServices) {
    return (
      this.isSelectedDoorToPort(services) || this.isSelectedEORService(services)
    );
  }

  isSelectCustomsClearanceAndTruckingInForeignCountry(
    quote: QuoteBase
  ): boolean {
    let isImportTransactionType = this.isImportTransactionType(quote);

    if (isImportTransactionType) {
      return (
        quote?.routingDetails?.pickupLocationDetails?.isCustomClearance &&
        quote?.routingDetails?.pickupLocationDetails?.isTrucking
      );
    } else {
      return (
        quote?.routingDetails?.deliveryLocationDetails?.isCustomClearance &&
        quote?.routingDetails?.deliveryLocationDetails?.isTrucking
      );
    }
  }

  isAir(freightType: string): boolean {
    return freightType == ShipmentFreightType.Air;
  }

  isLand(freightType: string): boolean {
    return freightType == ShipmentFreightType.Land;
  }

  getFreightReference(quote: QuoteBase) {
    let freightType = this.getFreightType(quote);
    if (!freightType) {
      freightType = this.getFreightServiceOptions(quote);
    }
    return freightType;
  }

  getFreightServiceOptions(quote) {
    let isAir = this.isAirService(quote?.quoteServices);
    let isOcean = this.isOceanService(quote?.quoteServices);
    if (isAir && isOcean) {
      return ShipmentFreightType.Air + ShipmentFreightType.Ocean;
    } else if (isAir) {
      return ShipmentFreightType.Air;
    } else if (isOcean) {
      return ShipmentFreightType.Ocean;
    }
  }

  getFreightType(quote: QuoteBase): string {
    return quote?.routingDetails?.freightReference;
  }

  isAirAndOcean(freightType: string) {
    return freightType == ShipmentFreightType.Air + ShipmentFreightType.Ocean;
  }

  isOcean(freightType: string) {
    return freightType == ShipmentFreightType.Ocean;
  }

  isSelectdCCStandalone(services: QuoteServices) {
    return (
      this.isSelectedCustomsClearanceService(services) &&
      !this.isSelectedFreightService(services) &&
      !this.isSelectedEORService(services) &&
      !this.isSelectedIORService(services) &&
      !this.isSelectedTruckingService(services)
    );
  }

  hasAirFreightReference(quote: QuoteBase): boolean {
    let isAir =
      this.isAirService(quote?.quoteServices) ||
      this.isAir(quote?.routingDetails?.freightReference);
    return isAir;
  }

  isTruckingSelectedFromServicesOrRouting(quote: QuoteBase): boolean {
    let isTruckingSelectedInServices = this.isSelectedTruckingService(
      quote?.quoteServices
    );

    let isTruckingSelectedFromRouting =
      quote?.routingDetails?.pickupLocationDetails?.isTrucking ||
      quote?.routingDetails?.deliveryLocationDetails?.isTrucking;

    return isTruckingSelectedInServices || isTruckingSelectedFromRouting;
  }

  calculateChargeableWeight(quote: QuoteBase) {
    let totalShipmentVolume = this.calculateTotalShipmentVolume(quote);
    let isSelectedFreightService = this.isSelectedFreightService(
      quote?.quoteServices
    );
    let freightReference = this.getFreightReference(quote);
    let hasLTLTrucking = this.hasLTLTrucking(quote);
    let totalShipmentWeight = this.calculateTotalShipmentWeight(quote);
    let isExpressFreight = this.isExpressFreight(quote?.routingDetails);

    let totalVolumetricWeight = this.getTotalVolumetricWeight(
      totalShipmentVolume,
      isSelectedFreightService,
      freightReference,
      hasLTLTrucking,
      isExpressFreight
    );

    let chargeableWeight = Math.max(totalShipmentWeight, totalVolumetricWeight);

    return chargeableWeight;
  }

  calculatePackageTotalsChargeableWeight(quote: QuoteBase): number {
    let totalShipmentVolume = quote?.cargoDetails?.packagesTotals?.totalvolume;
    let isSelectedFreightService = this.isSelectedFreightService(
      quote.quoteServices
    );
    let freightReference = this.getFreightReference(quote);
    let hasLTLTrucking = this.hasLTLTrucking(quote);
    let totalShipmentWeight = this.calculateTotalShipmentWeight(quote);
    let isExpressFreight = this.isExpressFreight(quote?.routingDetails);

    let totalVolumetricWeight = this.getTotalVolumetricWeight(
      totalShipmentVolume,
      isSelectedFreightService,
      freightReference,
      hasLTLTrucking,
      isExpressFreight
    );

    let chargeableWeight = Math.max(totalShipmentWeight, totalVolumetricWeight);

    return chargeableWeight;
  }

  hasLTLTrucking(quot: QuoteBase) {
    return quot?.cargoDetails?.loadType == TruckingLoadType.LTL;
  }

  getTotalVolumetricWeight(
    totalShipmentVolume: number,
    isSelectedFreightService: boolean,
    freightReference?: string,
    hasLTLTrucking?: boolean,
    isExpressFreight?: boolean
  ) {
    let volume = 0.0;

    volume = totalShipmentVolume * this.convertCCMToCBM;

    if (
      freightReference === ShipmentFreightType.Air &&
      isSelectedFreightService
    ) {
      let divisionFactor = isExpressFreight
        ? this.airFreightExpressFactor
        : this.airFreightFactor;
      return volume / divisionFactor;
    }

    if (
      freightReference === ShipmentFreightType.Ocean &&
      isSelectedFreightService
    ) {
      return volume / this.oceanLCLBBFactor;
    }

    if (hasLTLTrucking) {
      return volume / this.truckingLTLFactor;
    }

    return null;
  }

  calculateTotalShipmentVolume(quote: QuoteBase): number {
    let totalVolume = 0.0;

    if (quote?.cargoDetails?.hasPackage) {
      let packages = quote?.cargoDetails?.packages;
      packages?.forEach(Package => {
        totalVolume += this.getPackageOrPalletVolume(
          Package.length,
          Package.width,
          Package.height,
          Package.quantity
        );
      });
    }

    let hasBreakbulkPackages = this.hasBreakbulkPackages(quote);
    if (hasBreakbulkPackages) {
      let breakbulkPackages = quote?.cargoDetails?.breakbulkPackages;
      breakbulkPackages?.forEach(Package => {
        totalVolume += this.getPackageOrPalletVolume(
          Package.length,
          Package.width,
          Package.height,
          Package.quantity
        );
      });
    }

    if (quote?.cargoDetails?.hasPallet) {
      quote?.cargoDetails?.pallets?.forEach(shipmentPallet => {
        totalVolume += this.getPackageOrPalletVolume(
          shipmentPallet.length,
          shipmentPallet.width,
          shipmentPallet.height,
          shipmentPallet.quantity
        );
      });
    }

    return totalVolume;
  }

  getPackageOrPalletVolume(
    length: number,
    width: number,
    height: number,
    quantity: number
  ): number {
    let volume = (length * width * height) / this.convertCCMToCBM;
    return volume * quantity;
  }

  calculateTotalShipmentWeight(quote: QuoteBase): number {
    let totalWeight = 0.0;

    if (this.isCargoPackagingTotals(quote)) {
      return quote?.cargoDetails?.packagesTotals?.totalWeight;
    }

    if (quote?.cargoDetails?.hasPackage) {
      quote?.cargoDetails?.packages?.forEach(Package => {
        totalWeight += Package.quantity * Package.weight;
      });
    }

    let hasBreakbulkPackages = this.hasBreakbulkPackages(quote);
    if (hasBreakbulkPackages) {
      quote?.cargoDetails?.breakbulkPackages?.forEach(Package => {
        totalWeight += Package.quantity * Package.weight;
      });
    }

    if (quote?.cargoDetails?.hasPallet) {
      quote?.cargoDetails?.pallets?.forEach(pallet => {
        totalWeight += pallet.quantity * pallet.weight;
      });
    }

    return totalWeight;
  }

  isLCLLoadType(quote: QuoteBase) {
    return quote?.cargoDetails?.loadType == OceanFreightOptionsEnum.LCL;
  }

  isFCLLoadType(quote: QuoteBase) {
    return quote?.cargoDetails?.loadType == OceanFreightOptionsEnum.FCL;
  }

  isFTLLoadType(quote: QuoteBase) {
    return quote?.cargoDetails?.loadType == TruckingLoadType.FTL;
  }

  hasBreakbulkPackages(quote: QuoteBase) {
    return quote?.cargoDetails?.breakbulkPackages?.length > 0;
  }

  getQuoteNumber(quote: QuoteBase): string {
    return quote.quoteNumber?.substring(5);
  }

  getStatusName(quote: QuoteBase): string {
    return quote?.status?.name;
  }

  getAdditionalCharges(quoteProposal: QuoteProposalDetails): string {
    let hasAdditionalCharges = quoteProposal?.charges?.length > 0;
    if (!hasAdditionalCharges) {
      return null;
    }
    let additionalCharges = this.sumDistinctTotalCharges(
      quoteProposal?.charges
    );
    if (!additionalCharges || additionalCharges?.length <= 0) {
      return null;
    }

    let charges: string = additionalCharges
      ?.map(charge => `${charge.amount} ${charge.currencyCode}`)
      .join(', ');
    return charges;
  }

  getCarriers(quoteProposalDetails: QuoteProposalDetails): string {
    let frightCarrier = this.getSingleFreightRouteCarrierName(
      quoteProposalDetails
    );
    let truckingCarrier = this.getTruckingCarrier(quoteProposalDetails);

    if (frightCarrier && truckingCarrier) {
      return frightCarrier + ' , ' + truckingCarrier;
    }
    return frightCarrier || truckingCarrier || null;
  }

  getTruckingCarrier(quoteProposalDetails: QuoteProposalDetails): string {
    if (!this.isSelectedTruckingRate(quoteProposalDetails)) {
      return null;
    }
    let truckingRate = this.getTruckingRate(quoteProposalDetails);
    let truckingCarrier = truckingRate?.carrier;
    return truckingCarrier;
  }

  getIncoterm(quote: QuoteBase): string {
    return quote?.routingDetails?.incoterm;
  }

  getProductDescription(quote: QuoteBase): string {
    return quote?.cargoDetails?.quoteCargoProduct?.productDescription;
  }

  getQuoteRoutingCountryId(isOrigin: boolean, quote: QuoteBase): number {
    let countryId = quote?.routingDetails?.pickupLocationDetails?.countryId;

    if (!isOrigin) {
      countryId = quote?.routingDetails?.deliveryLocationDetails?.countryId;
    }

    return countryId;
  }

  isFreightPortsHaveSameDestination(
    quoteProposalDetails: QuoteProposalDetails,
    freightReference: string
  ): boolean {
    let freightRoutes = this.getFreightRoutes(
      quoteProposalDetails,
      freightReference
    );
    return freightRoutes?.every(
      (val, i, arr) => val?.destinationPortId === arr[0]?.destinationPortId
    );
  }

  isFreightPortsHaveSamePickup(
    quoteProposalDetails: QuoteProposalDetails,
    freightReference: string
  ): boolean {
    let freightRoutes = this.getFreightRoutes(
      quoteProposalDetails,
      freightReference
    );
    return freightRoutes?.every(
      (val, i, arr) => val?.pickupPortId === arr[0]?.pickupPortId
    );
  }

  isCargoPackagingTotals(quote: QuoteBase): boolean {
    return quote?.cargoDetails?.isPackageTotals;
  }

  getQuoteContainersWithType(
    quote: QuoteBase,
    containerType: ContainerTypeEnum
  ): QuoteCargoContainer[] {
    if (containerType === ContainerTypeEnum.Container20) {
      let has20Container = this.hasQuoteContainerswithType(
        quote,
        ContainerTypeEnum.Container20
      );
      return has20Container ? quote?.cargoDetails?.containers_20 : null;
    }

    if (containerType === ContainerTypeEnum.Container40) {
      let has40Container = this.hasQuoteContainerswithType(
        quote,
        ContainerTypeEnum.Container40
      );
      return has40Container ? quote?.cargoDetails?.containers_40 : null;
    }

    if (containerType === ContainerTypeEnum.Container40hc) {
      let has40HCContainer = this.hasQuoteContainerswithType(
        quote,
        ContainerTypeEnum.Container40hc
      );
      return has40HCContainer ? quote?.cargoDetails?.containers_40_HC : null;
    }

    return null;
  }

  hasQuoteContainerswithType(
    quote: QuoteBase,
    containerType: ContainerTypeEnum
  ): boolean {
    if (containerType === ContainerTypeEnum.Container20) {
      return quote?.cargoDetails?.has20Container;
    }

    if (containerType === ContainerTypeEnum.Container40) {
      return quote?.cargoDetails?.has40Container;
    }

    if (containerType === ContainerTypeEnum.Container40hc) {
      return quote?.cargoDetails?.has40HCContainer;
    }

    return false;
  }

  getFreightRoutes(
    quoteProposalDetails: QuoteProposalDetails,
    freightReference?: string
  ): FreightRoute[] {
    if (freightReference) {
      return quoteProposalDetails?.quoteRates?.freightRoutes?.filter(
        r => r.freightReference === freightReference
      );
    }

    return quoteProposalDetails?.quoteRates?.freightRoutes;
  }

  getContainersNumbers(quote: QuoteBase): number {
    let containersNumbers: number = 0;
    let containers_20 = this.getQuoteContainersWithType(
      quote,
      ContainerTypeEnum.Container20
    );
    containersNumbers +=
      containers_20?.reduce((accumulator, object) => {
        return accumulator + object['quantity'];
      }, 0) || 0;

    let containers_40 = this.getQuoteContainersWithType(
      quote,
      ContainerTypeEnum.Container40
    );
    containersNumbers +=
      containers_40?.reduce((accumulator, object) => {
        return accumulator + object['quantity'];
      }, 0) || 0;

    let containers_HC_40 = this.getQuoteContainersWithType(
      quote,
      ContainerTypeEnum.Container40hc
    );
    containersNumbers +=
      containers_HC_40?.reduce((accumulator, object) => {
        return accumulator + object['quantity'];
      }, 0) || 0;

    return containersNumbers;
  }

  getEachServiceTotalValue(
    quote: QuoteBase,
    quoteProposalDetails: QuoteProposalDetails
  ): string {
    let ratesCharges = this.calculateRatesTotalCharge(
      quote,
      quoteProposalDetails
    );

    let charges: string = ratesCharges
      .map(
        charge =>
          `${charge.serviceName} ${charge.amount} ${charge.currencyCode}`
      )
      .join(', ');
    return charges;
  }

  calculateRatesTotalCharge(
    quote: QuoteBase,
    quoteProposalDetails: QuoteProposalDetails
  ): Charge[] {
    let accountManagementRateTotalCharge = this.calculateAccountManagementRateTotalCharge(
      quote
    );

    let charges = [];

    if (quoteProposalDetails?.quoteRates?.isSelectedTruckingRate) {
      let truckingTotalCharge = this.calculateTruckingRateTotalCharge(
        quoteProposalDetails
      );

      if (truckingTotalCharge?.amount > 0) {
        charges.push(truckingTotalCharge);
      }
    }
    if (quoteProposalDetails?.quoteRates?.isSelectedDomesticTruckingRate) {
      let domesticTruckingTotalCharge = this.calculateDomesticTruckingRateTotalCharge(
        quoteProposalDetails
      );

      if (domesticTruckingTotalCharge?.amount > 0) {
        charges.push(domesticTruckingTotalCharge);
      }
    }
    if (quoteProposalDetails?.quoteRates?.isSelectedIorEorRateRate) {
      let iorOrEorTotalCharge = this.calculateIorOrEorTotalCharge(
        quote,
        quoteProposalDetails
      );

      if (iorOrEorTotalCharge?.amount > 0) {
        charges.push(iorOrEorTotalCharge);
      }
    }
    if (quoteProposalDetails?.quoteRates?.isSelectedCustomsClearanceRate) {
      let customsClearanceTotalCharge = this.calculateCustomsClearanceTotalCharge(
        quote,
        quoteProposalDetails
      );

      if (customsClearanceTotalCharge?.amount > 0) {
        charges.push(customsClearanceTotalCharge);
      }
    }

    if (quoteProposalDetails?.quoteRates?.isSelectedFreightRate) {
      let feightRouteTotalCharge = this.calculateFreightRouteTotalCharge(
        quote,
        quoteProposalDetails
      );

      if (feightRouteTotalCharge?.amount > 0) {
        charges.push(feightRouteTotalCharge);
      }
    }

    if (accountManagementRateTotalCharge?.amount > 0) {
      accountManagementRateTotalCharge.serviceName =
        ServiceTypes.AccountManagement;
      charges.push(accountManagementRateTotalCharge);
    }
    return charges;
  }

  calculateTruckingRateTotalCharge(
    quoteProposalDetails: QuoteProposalDetails
  ): Charge {
    let truckingRate = quoteProposalDetails?.quoteRates?.truckingRate;
    let truckingTotalCharge = 0;
    if (truckingRate) {
      truckingTotalCharge =
        truckingRate?.truckRate * truckingRate?.transitQuantity;
    }
    return {
      amount: truckingTotalCharge,
      currencyCode: truckingRate?.truckRateCurrency,
      serviceName: ServiceTypes.Trucking
    };
  }

  calculateDomesticTruckingRateTotalCharge(
    quoteProposalDetails: QuoteProposalDetails
  ): Charge {
    let domesticTruckingRate = this.getDomesticTruckingRate(
      quoteProposalDetails
    );
    let truckingTotalCharge = 0;
    if (domesticTruckingRate) {
      truckingTotalCharge =
        domesticTruckingRate?.truckRate * domesticTruckingRate?.transitQuantity;
    }
    return {
      amount: truckingTotalCharge,
      currencyCode: domesticTruckingRate?.truckRateCurrency,
      serviceName: ServiceTypes.Domestic
    };
  }

  calculateIorOrEorTotalCharge(
    quote: QuoteBase,
    quoteProposalDetails: QuoteProposalDetails
  ): Charge {
    let iorOrEorRate = quoteProposalDetails?.quoteRates?.iorEorRate;
    let iorOrEorTotalCharge = 0;
    if (iorOrEorRate) {
      let totalShipmentValue =
        quote?.cargoDetails?.quoteCargoProduct?.totalShipmentValue;
      iorOrEorTotalCharge = (iorOrEorRate?.rate * totalShipmentValue) / 100;
    }
    return {
      amount: iorOrEorTotalCharge,
      currencyCode: iorOrEorRate?.currencyCode,
      serviceName: iorOrEorRate?.type
    };
  }

  calculateAccountManagementRateTotalCharge(quote: QuoteBase): Charge {
    let totalCharge = 0;
    let currecnyCode = quote?.cargoDetails?.quoteCargoProduct?.currencyCode;
    let accountManagementRate =
      quote?.quoteProposalDetails?.quoteRates?.accountManagementRate;
    if (accountManagementRate) {
      let isFixedRate = accountManagementRate.isFixedRate;
      let isContractualRates = accountManagementRate.isHasContractualRates;
      let isFreeOfChargeRate = !(isContractualRates || isFixedRate);

      if (isFreeOfChargeRate) {
        return;
      }
      if (isFixedRate) {
        totalCharge = accountManagementRate?.fixedAmount;
        currecnyCode = accountManagementRate?.currency;
      }
      if (isContractualRates) {
        totalCharge =
          (accountManagementRate?.rate *
            quote?.cargoDetails?.quoteCargoProduct?.totalShipmentValue) /
          100;
      }

      return {
        amount: totalCharge,
        currencyCode: currecnyCode
      };
    }
  }

  calculateCustomsClearanceTotalCharge(
    quote: QuoteBase,
    quoteProposalDetails: QuoteProposalDetails
  ): Charge {
    let customsClearanceRates =
      quoteProposalDetails?.quoteRates?.customsClearanceRates;
    let isFCLLoadType = this.isFCLLoadType(quote);
    let customsClearanceFCLRate = quoteProposalDetails?.quoteRates?.customsClearanceRates?.find(
      x => x.loadType == OceanFreightOptionsEnum.FCL
    );

    if (!customsClearanceRates || customsClearanceRates?.length != 1) {
      return;
    }
    if (isFCLLoadType && customsClearanceFCLRate) {
      return this.calculateCustomsClearanceFCLTotalCharge(
        quote,
        customsClearanceFCLRate
      );
    } else {
      return this.calculateCustomsClearanceNotFCLTotalCharge(quote);
    }
  }

  calculateCustomsClearanceNotFCLTotalCharge(quote: QuoteBase): Charge {
    let customsClearanceNotFclRate = quote?.quoteProposalDetails?.quoteRates?.customsClearanceRates?.filter(
      x => x.loadType != OceanFreightOptionsEnum.FCL
    );

    if (customsClearanceNotFclRate?.length === 1) {
      return {
        amount: this.calculateCustomsClearanceNotFCLTotalServiceCharge(
          customsClearanceNotFclRate[0],
          quote
        ),
        currencyCode: customsClearanceNotFclRate[0]?.currencyCode,
        serviceName: ServiceTypes.CustomsClearance
      };
    }
  }

  calculateCustomsClearanceNotFCLTotalServiceCharge(
    customsClearanceNotFclRate: CustomsClearanceRate,
    quote: QuoteBase
  ): number {
    let tierOne = customsClearanceNotFclRate?.threeTiersRate?.tierOne;
    let tierOneRate = customsClearanceNotFclRate?.threeTiersRate?.tierOneRate;
    let totalShipmentWeight = this.calculateTotalShipmentWeight(quote);

    if (!+tierOne || !+tierOneRate || !+totalShipmentWeight) {
      return 0;
    }

    let tierTwoCeil = customsClearanceNotFclRate?.threeTiersRate?.tierTwoCeil;
    let tierTwoRate = customsClearanceNotFclRate?.threeTiersRate?.tierTwoRate;
    let tierTwoBaseUnit =
      customsClearanceNotFclRate?.threeTiersRate?.tierTwoBaseUnit;

    let tierThreeRate =
      customsClearanceNotFclRate?.threeTiersRate?.tierThreeRate;
    let tierThreeBaseUnit =
      customsClearanceNotFclRate?.threeTiersRate?.tierThreeBaseUnit;

    let totalWeight = +totalShipmentWeight - +tierOne;
    let currentCalculatedWeight = +tierOne;
    let totalRate = +tierOneRate;

    while (
      currentCalculatedWeight < +tierTwoCeil &&
      totalWeight > 0 &&
      +tierTwoBaseUnit &&
      +tierTwoRate &&
      +tierTwoCeil
    ) {
      currentCalculatedWeight += +tierTwoBaseUnit;
      totalWeight -= +tierTwoBaseUnit;
      totalRate += +tierTwoRate;
    }

    while (totalWeight > 0 && +tierThreeBaseUnit && +tierThreeRate) {
      currentCalculatedWeight += +tierThreeBaseUnit;
      totalWeight -= +tierThreeBaseUnit;
      totalRate += +tierThreeRate;
    }

    return totalRate;
  }

  calculateCustomsClearanceFCLTotalCharge(
    quote: QuoteBase,
    customsClearanceRate: CustomsClearanceRate
  ): Charge {
    let totalCharge = 0;

    if (customsClearanceRate) {
      let firstContainerRate =
        customsClearanceRate?.containerRate?.firstContainerRate;
      let everyAdditionalContainerRate: number =
        customsClearanceRate?.containerRate?.everyAdditionalContainerRate;
      let containersNumber: number = this.getContainersNumbers(quote) - 1;
      if (firstContainerRate && everyAdditionalContainerRate) {
        totalCharge =
          +firstContainerRate +
          +everyAdditionalContainerRate * containersNumber;
      }
    }
    return {
      amount: totalCharge,
      currencyCode: customsClearanceRate?.currencyCode
    };
  }

  sumDistinctTotalCharges(charges: Charge[]): Charge[] {
    const result = Object.values(
      charges.reduce((acc, charge) => {
        const { currencyCode, amount } = charge;
        if (acc[currencyCode]) {
          acc[currencyCode].amount += amount;
        } else {
          acc[currencyCode] = { currencyCode, amount };
        }
        return acc;
      }, {})
    );
    return result;
  }

  calculateFreightRouteTotalCharge(
    quote: QuoteBase,
    quoteProposalDetails: QuoteProposalDetails,
    freightRouteIndex: number = 0
  ): Charge {
    let freightRoutes = quoteProposalDetails?.quoteRates?.freightRoutes;
    let isFCLLoadType = this.isFCLLoadType(quote);
    let serviceTotalCharge = 0;

    if (freightRoutes == null || freightRoutes.length == 0) {
      return;
    }
    let selectedFreightRoute = freightRoutes[freightRouteIndex];

    if (
      this.hasMultipleFreightOrCustomcClearanceRoutes(quoteProposalDetails) &&
      this.isFreightRouteSelected(quoteProposalDetails)
    ) {
      selectedFreightRoute = this.getSelectedFreightRoute(quoteProposalDetails);
    }

    let isAir = this.isAir(selectedFreightRoute?.freightReference);
    if (isFCLLoadType && !isAir) {
      serviceTotalCharge = this.calculateServiceTotalChargeForOceanFCL(
        freightRouteIndex,
        quote,
        quoteProposalDetails
      );
    } else {
      serviceTotalCharge = this.calculateFreightRouteTotalChargeForNotFCL(
        freightRouteIndex,
        quote,
        quoteProposalDetails
      );
    }

    return {
      amount: serviceTotalCharge,
      currencyCode: selectedFreightRoute?.currencyCode,
      serviceName: ServiceTypes.Freight
    };
  }

  calculateForSingleFreightRouteTotalCharge(
    quote: QuoteBase,
    frightRoute: FreightRoute,
    quoteProposalDetails: QuoteProposalDetails
  ) {
    let index = this.getFreightRoutes(quoteProposalDetails)?.findIndex(
      r => r == frightRoute
    );
    let isFCLLoadType = this.isFCLLoadType(quote);
    let serviceTotalCharge = 0;

    let isAir = this.isAir(frightRoute?.freightReference);
    if (isFCLLoadType && !isAir) {
      serviceTotalCharge = this.calculateServiceTotalChargeForOceanFCL(
        index,
        quote,
        quoteProposalDetails
      );
    } else {
      serviceTotalCharge = this.calculateFreightRouteTotalChargeForNotFCL(
        index,
        quote,
        quoteProposalDetails
      );
    }

    return {
      amount: serviceTotalCharge,
      currencyCode: frightRoute?.currencyCode
    };
  }

  calculateServiceTotalChargeForOceanFCL(
    index: number,
    quote: QuoteBase,
    quoteProposalDetails: QuoteProposalDetails
  ): number {
    let container20Qunatity = this.getContainerQunatity(
      quote,
      ContainerTypeEnum.Container20
    );
    let container40Qunatity = this.getContainerQunatity(
      quote,
      ContainerTypeEnum.Container40
    );
    let container40HcQunatity = this.getContainerQunatity(
      quote,
      ContainerTypeEnum.Container40hc
    );
    let serviceTotalCharge = 0;

    let freightRoutes = quoteProposalDetails?.quoteRates?.freightRoutes;
    let container20Rate = freightRoutes[index]?.container20Rate;
    let container40Rate = freightRoutes[index]?.container40Rate;
    let container40HcRate = freightRoutes[index]?.container40HcRate;

    if (container20Rate) {
      serviceTotalCharge += container20Rate * container20Qunatity;
    }

    if (container40Rate) {
      serviceTotalCharge += container40Rate * container40Qunatity;
    }

    if (container40HcRate) {
      serviceTotalCharge += container40HcRate * container40HcQunatity;
    }

    return serviceTotalCharge;
  }

  calculateFreightRouteTotalChargeForNotFCL(
    index: number,
    quote: QuoteBase,
    quoteProposalDetails: QuoteProposalDetails
  ): number {
    let chargeableWeight = +this.getChargeableWeightForRateDetails(quote);

    let rate =
      quoteProposalDetails?.quoteRates?.freightRoutes[index]?.rate || 0;

    let serviceTotalCharge = chargeableWeight * rate;
    serviceTotalCharge = +serviceTotalCharge?.toFixed(2);

    return serviceTotalCharge;
  }

  getContainerQunatity(
    quote: QuoteBase,
    containerType: ContainerTypeEnum
  ): number {
    let containerQunatity = 0;
    let selectedContainers = this.getQuoteContainersWithType(
      quote,
      containerType
    );
    selectedContainers?.forEach(container => {
      containerQunatity += container?.quantity;
    });

    return containerQunatity;
  }

  getCustomsClearanceNotFCLRate(
    quoteProposalDetails: QuoteProposalDetails,
    freightReference: string
  ): CustomsClearanceRate {
    let customsClearanceNotFCLRate = quoteProposalDetails?.quoteRates?.customsClearanceRates?.find(
      x =>
        x.loadType != OceanFreightOptionsEnum.FCL &&
        x.freightReference === freightReference
    );
    return customsClearanceNotFCLRate;
  }

  hasMultipleFreightRoutes(
    quoteProposalDetails: QuoteProposalDetails
  ): boolean {
    let quoteRates = quoteProposalDetails?.quoteRates;
    return quoteRates?.freightRoutes?.length > 1;
  }

  hassMultipleCustomsClearanceRates(
    quoteProposalDetails: QuoteProposalDetails
  ): boolean {
    let quoteRates = quoteProposalDetails?.quoteRates;
    return quoteRates?.customsClearanceRates?.length > 1;
  }

  hasTruckingRateLoadType(quote: QuoteBase): boolean {
    let domesticTruckingRate = this.getDomesticTruckingRate(
      quote.quoteProposalDetails
    );
    let truckingRate = this.getTruckingRate(quote.quoteProposalDetails);
    return truckingRate?.truckLoadId > 0 || !!domesticTruckingRate?.truckLoad;
  }

  isPendingApprovalQuoteStatus(quote: QuoteBase): boolean {
    return quote?.status?.name == QuoteStatus.PendingApproval;
  }

  getFreightDescription(quote: QuoteBase): string {
    let freightDescription = '';

    let selectedFreightValueNames = this.getFreightValueNames(
      quote?.quoteServices
    );

    if (selectedFreightValueNames?.includes(ShipmentFreightType.Air)) {
      freightDescription += ShipmentFreightType.Air;
    }

    if (selectedFreightValueNames?.length > 1) {
      freightDescription += ' or ';
    }

    if (selectedFreightValueNames?.includes(ShipmentFreightType.Ocean)) {
      freightDescription +=
        ShipmentFreightType.Ocean + ' (' + quote?.cargoDetails?.loadType + ')';
    }

    return freightDescription;
  }

  getFreightDescriptionAfterSelection(
    quote: QuoteBase,
    freightReference: string
  ): string {
    let freightDescription = '';
    freightDescription += freightReference;
    if (freightReference == ShipmentFreightType.Ocean) {
      freightDescription += ' (' + quote?.cargoDetails?.loadType + ')';
    }
    return freightDescription;
  }

  getSingleFreightRouteCarrierName(
    quoteProposalDetails: QuoteProposalDetails
  ): string {
    let freightRoutes = this.getFreightRoutes(quoteProposalDetails);
    let hasMultipleFreightRoutes = this.hasMultipleFreightRoutes(
      quoteProposalDetails
    );

    if (
      hasMultipleFreightRoutes ||
      !freightRoutes ||
      freightRoutes.length <= 0
    ) {
      return null;
    }

    return freightRoutes[0]?.carrier;
  }

  getFreightRouteRateDetails(
    quote: QuoteBase,
    quoteProposalDetails: QuoteProposalDetails
  ): string {
    let hasMultipleFreightRoutes = this.hasMultipleFreightRoutes(
      quoteProposalDetails
    );
    if (hasMultipleFreightRoutes) {
      return 'Depends on freight selection';
    } else {
      let frightRoutes = this.getFreightRoutes(quoteProposalDetails);

      let details = this.getFrightRouteRate(quote, frightRoutes[0]);

      return details;
    }
  }

  getFrightRouteRate(quote: QuoteBase, frightRoute: FreightRoute): string {
    let isFCLLoadType = this.isFCLLoadType(quote);
    let details = '';
    //handle FCL case
    let Container20Quantity = this.getContainerQunatity(
      quote,
      ContainerTypeEnum.Container20
    );

    let Container40Quantity = this.getContainerQunatity(
      quote,
      ContainerTypeEnum.Container40
    );

    let Container40HcQuantity = this.getContainerQunatity(
      quote,
      ContainerTypeEnum.Container40hc
    );

    if (Container20Quantity) {
      details +=
        ContainerTitle.container20Title +
        ' = ' +
        frightRoute?.container20Rate +
        frightRoute?.currencyCode +
        ' x ' +
        Container20Quantity;
    }

    if (Container20Quantity && Container40Quantity) {
      details += '\n';
    }

    if (Container40Quantity) {
      details +=
        ContainerTitle.container40Title +
        ' = ' +
        frightRoute?.container40Rate +
        frightRoute?.currencyCode +
        ' x ' +
        Container40Quantity;
    }

    if (Container40HcQuantity && (Container40Quantity || Container20Quantity)) {
      details += '\n';
    }

    if (Container40HcQuantity) {
      details +=
        ContainerTitle.container40HcTitle +
        ' = ' +
        frightRoute?.container40HcRate +
        frightRoute?.currencyCode +
        ' x ' +
        Container40HcQuantity;
    }

    //handle air, LCL and BB

    if (!isFCLLoadType || this.isAir(frightRoute?.freightReference)) {
      let weightUnit =
        frightRoute?.freightReference === ShipmentFreightType.Air
          ? 'Kg'
          : 'CBM';
      let chargeableWeight = this.getChargeableWeightForRateDetails(quote);
      details =
        frightRoute.rate +
        ' ' +
        frightRoute?.currencyCode +
        '/' +
        weightUnit +
        ' x ' +
        chargeableWeight;
    }
    return details;
  }

  serviceSubtotal(
    quote: QuoteBase,
    quoteProposalDetails: QuoteProposalDetails
  ): Charge[] {
    let charges = this.calculateRatesTotalCharge(quote, quoteProposalDetails);

    return this.sumDistinctTotalCharges(charges);
  }

  hasAdditionalCharges(quote: QuoteBase): boolean {
    return quote?.quoteProposalDetails?.charges?.length > 0;
  }

  quoteTotalValue(
    quote: QuoteBase,
    quoteProposalDetails: QuoteProposalDetails
  ): Charge[] {
    let charges = quote?.quoteProposalDetails?.charges;
    let subtotalCharges = this.serviceSubtotal(quote, quoteProposalDetails);
    let totalCharges = [...subtotalCharges];
    if (this.hasAdditionalCharges(quote)) {
      totalCharges = [...subtotalCharges, ...charges];
    }
    return this.sumDistinctTotalCharges(totalCharges);
  }

  hasMultipleFreightOrCustomcClearanceRoutes(
    quoteProposalDetails: QuoteProposalDetails
  ): boolean {
    let hasMultipleFreightRoutes = this.hasMultipleFreightRoutes(
      quoteProposalDetails
    );

    let hasCustomsClearanceRates = this.hassMultipleCustomsClearanceRates(
      quoteProposalDetails
    );

    return hasMultipleFreightRoutes || hasCustomsClearanceRates;
  }

  isQuoteProposalExpired(
    quote: QuoteBase,
    isNewVersion: boolean = true
  ): boolean {
    let quoteProposalDetails = this.getLatestQuotePropsalSubmitted(
      quote,
      isNewVersion
    );
    let expiryDate = this.getExpiryDate(quoteProposalDetails);
    if (!expiryDate) {
      return false;
    }
    const expiryDateObject = Utilities.parseDate(expiryDate);

    return this.isEXceedToday(expiryDateObject);
  }

  isQuoteProposalAboutToExpire(
    quote: QuoteBase,
    isNewVersion: boolean = true
  ): boolean {
    let quoteProposalDetails = this.getLatestQuotePropsalSubmitted(
      quote,
      isNewVersion
    );
    let expiryDate = this.getExpiryDate(quoteProposalDetails);
    if (!expiryDate) {
      return false;
    }
    const expiryDateObject = Utilities.parseDate(expiryDate);

    return this.hasThreeDaysOrLessLeft(expiryDateObject);
  }

  getExpiryDate(quoteProposalDetails: QuoteProposalDetails): Date {
    const submissionDate =
      quoteProposalDetails?.proposalSubmissionDetails?.submittedAt;
    if (!submissionDate) {
      return null;
    }
    const expiredAfterSubmission = this.addDaysToDate(submissionDate, 5);

    const cutoffDate = this.getFreightCutoffDate(quoteProposalDetails);
    const cutoffDateObj = new Date(cutoffDate);

    if (cutoffDate && cutoffDateObj <= expiredAfterSubmission) {
      return cutoffDate;
    }

    if (this.hasMultipleFreightRoutes(quoteProposalDetails) && !cutoffDate) {
      return null;
    }

    return expiredAfterSubmission;
  }

  isEXceedToday(orignalDate: Date): boolean {
    const expirationDate = new Date(orignalDate);
    expirationDate.setDate(orignalDate.getDate() + 5);
    let startDate = new Date();
    let expiration = Utilities.expirationDate(startDate, expirationDate);
    return expiration.hoursRemaining <= 0;
  }

  hasThreeDaysOrLessLeft(orignalDate: Date): boolean {
    let startDate = new Date();
    let expiration = Utilities.expirationDate(startDate, orignalDate);

    return expiration.daysRemaining <= 3 && expiration.hoursRemaining > 0;
  }

  getFreightCutoffDate(quoteProposalDetails: QuoteProposalDetails): Date {
    return quoteProposalDetails?.quoteRates?.freightCutoffDate;
  }

  addDaysToDate(orignalDate: Date, numberOfDays) {
    const date = new Date(orignalDate);
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + numberOfDays);
    return newDate;
  }

  getFreightRouteRateDetailsForSingleRoute(
    frightRoute: FreightRoute,
    quote: QuoteBase
  ): string {
    let details = this.getFrightRouteRate(quote, frightRoute);

    return details;
  }

  isSelectedTruckingRate(quoteProposalDetails: QuoteProposalDetails): boolean {
    return quoteProposalDetails?.quoteRates?.isSelectedTruckingRate;
  }

  isSelectedDomesticTruckingRate(
    quoteProposalDetails: QuoteProposalDetails
  ): boolean {
    return quoteProposalDetails?.quoteRates?.isSelectedDomesticTruckingRate;
  }

  isSelectedCustomsClearanceRate(
    quoteProposalDetails: QuoteProposalDetails
  ): boolean {
    return quoteProposalDetails?.quoteRates?.isSelectedCustomsClearanceRate;
  }

  isSelectedIorEorRate(quoteProposalDetails: QuoteProposalDetails): boolean {
    return quoteProposalDetails?.quoteRates?.isSelectedIorEorRateRate;
  }

  isSelectedFreightRate(quoteProposalDetails: QuoteProposalDetails): boolean {
    return quoteProposalDetails?.quoteRates?.isSelectedFreightRate;
  }

  getLatestQuotePropsalSubmitted(
    quote: QuoteBase,
    isNewVersion: boolean = true
  ): QuoteProposalDetails {
    if (isNewVersion) {
      return quote?.quoteProposalDetails;
    } else if (this.isNewVersionInPropgress(quote)) {
      let olderVersionLength = quote?.olderVersionsProposalDetails?.length;
      return quote?.olderVersionsProposalDetails[olderVersionLength - 1];
    }
  }

  isNewVersionInPropgress(quote: QuoteBase): boolean {
    return (
      this.isProposalInProgressQuoteStatus(quote) &&
      quote?.olderVersionsProposalDetails?.length > 0
    );
  }

  isProposalInProgressQuoteStatus(quote: QuoteBase): boolean {
    return quote?.status?.name == QuoteStatus.ProposalInProgress;
  }

  hasProposalSubmissionDetails(quote: QuoteBase): boolean {
    return quote?.quoteProposalDetails?.proposalSubmissionDetails != null;
  }

  getOlderVersionsProposalDetailsLength(quote: QuoteBase): number {
    return quote?.olderVersionsProposalDetails?.length;
  }

  getCustomsClearanceNotFclRateDetails(
    customClearanceRate: CustomsClearanceRate
  ): string {
    let customClearanceRates = customClearanceRate?.threeTiersRate;
    let currencyCode = customClearanceRate?.currencyCode;
    return (
      'Up to ' +
      customClearanceRates?.tierOne +
      ' Kg' +
      ' = ' +
      customClearanceRates?.tierOneRate +
      ' ' +
      currencyCode +
      '\n Every Extra ' +
      customClearanceRates?.tierTwoBaseUnit +
      ' Kg up to ' +
      customClearanceRates?.tierTwoCeil +
      ' Kg ' +
      '= ' +
      customClearanceRates?.tierTwoRate +
      ' ' +
      currencyCode +
      '\n Every Extra ' +
      customClearanceRates?.tierThreeBaseUnit +
      ' Kg Unlimited = ' +
      customClearanceRates?.tierThreeRate +
      ' ' +
      currencyCode
    );
  }

  getCustomsClearanceFclRateDetails(
    quote: QuoteBase,
    customClearanceRate: CustomsClearanceRate
  ): string {
    let customClearanceRates = customClearanceRate?.containerRate;
    let currencyCode = customClearanceRate?.currencyCode;
    let numOfContainers: number = this.getContainersNumbers(quote) - 1;
    return (
      'First Container = ' +
      customClearanceRates?.firstContainerRate +
      ' ' +
      currencyCode +
      '\n Extra Container(s) = ' +
      customClearanceRates?.everyAdditionalContainerRate +
      ' ' +
      currencyCode +
      ' x ' +
      numOfContainers
    );
  }

  getCustomsClearanceRouteRate(
    quoteProposalDetails: QuoteProposalDetails
  ): CustomsClearanceRate {
    if (
      !quoteProposalDetails?.quoteRates?.customsClearanceRates ||
      quoteProposalDetails?.quoteRates?.customsClearanceRates?.length <= 0
    ) {
      return;
    }

    if (!this.hasMultipleFreightRoutes(quoteProposalDetails))
      return quoteProposalDetails?.quoteRates?.customsClearanceRates[0];
    else {
      return quoteProposalDetails?.quoteRates?.customsClearanceRates.find(
        x => x.isSelected == true
      );
    }
  }

  getQuoteRates(quote: QuoteBase): QuoteRates {
    return quote?.quoteProposalDetails?.quoteRates;
  }

  getCountryName(quoteRouting: QuoteRoutingDetails, type: string): string {
    return type == 'pickup'
      ? quoteRouting.pickupLocationDetails?.country
      : quoteRouting.deliveryLocationDetails?.country;
  }

  getQuoteTransactionType(quote: QuoteBase, egyptId: number): string {
    let isServiceselectedImport = this.isImport(quote?.quoteServices);
    let isDeliveryCountryEgypt =
      quote?.routingDetails?.deliveryLocationDetails?.countryId === egyptId;

    let isSelectdTruckingStandalone = this.isSelectdTruckingStandalone(
      quote?.quoteServices
    );

    if (isSelectdTruckingStandalone) {
      return quote?.quoteServices?.transactionType;
    }

    return isServiceselectedImport || isDeliveryCountryEgypt
      ? TransactionType.Import
      : TransactionType.Export;
  }

  getQuoteLoadType(quote: QuoteBase): string {
    return quote?.cargoDetails?.loadType;
  }

  getSelectedFreightRoute(
    quoteProposalDetails: QuoteProposalDetails
  ): FreightRoute {
    if (quoteProposalDetails?.quoteRates?.freightRoutes?.length === 1) {
      return quoteProposalDetails?.quoteRates?.freightRoutes[0];
    }

    return quoteProposalDetails?.quoteRates?.freightRoutes?.find(
      r => r.isSelected === true
    );
  }

  getSelectedFreightRouteRates(
    quoteProposalDetails: QuoteProposalDetails
  ): { rate: number; containerType?: string }[] {
    let rates: { rate: number; containerType?: string }[] = [];
    let selectedFreightRoute = this.getSelectedFreightRoute(
      quoteProposalDetails
    );

    if (selectedFreightRoute?.container20Rate) {
      rates.push({
        rate: selectedFreightRoute?.container20Rate,
        containerType: ContainerTypeEnum.Container20
      });
    }

    if (selectedFreightRoute?.container40Rate) {
      rates.push({
        rate: selectedFreightRoute?.container40Rate,
        containerType: ContainerTypeEnum.Container40
      });
    }

    if (selectedFreightRoute?.container40HcRate) {
      rates.push({
        rate: selectedFreightRoute?.container40HcRate,
        containerType: ContainerTypeEnum.Container40hc
      });
    }

    if (selectedFreightRoute?.rate) {
      rates.push({ rate: selectedFreightRoute?.rate });
    }

    return rates;
  }

  getChargeableWeightForRateDetails(quote: QuoteBase): string {
    let chargeableWeight = 0.0;
    if (this.isCargoPackagingTotals(quote)) {
      chargeableWeight = this.calculatePackageTotalsChargeableWeight(quote);
    } else {
      chargeableWeight = this.calculateChargeableWeight(quote);
    }
    return chargeableWeight.toFixed(2);
  }
  isRejectedQuoteStatus(quote: QuoteBase): boolean {
    return quote?.status?.name == QuoteStatus.Rejected;
  }

  getRejectedDate(quote: QuoteBase): Date {
    return quote?.quoteRejectionDetails?.rejectedAt;
  }

  getFastestRouteIndex(quoteProposalDetails: QuoteProposalDetails): number {
    let allFreightRoutes = this.getFreightRoutes(quoteProposalDetails);
    let fastest: { index: number; value: Date } = {
      index: -1,
      value: new Date('9999-12-31T23:59:59')
    };
    for (let i = 0; i < allFreightRoutes.length; i++) {
      const arrivalDate = this.getArrivalDate(
        allFreightRoutes[i].estimatedDeparture,
        allFreightRoutes[i].transitDuration
      );

      if (arrivalDate.getTime() == fastest.value.getTime()) {
        fastest = {
          index: -1,
          value: arrivalDate
        };
      } else if (arrivalDate.getTime() < fastest.value.getTime()) {
        fastest = {
          index: i,
          value: arrivalDate
        };
      }
    }

    return fastest.index;
  }

  getCheapestRouteIndex(
    quote: QuoteBase,
    allCurrencies: Currency[],
    quoteProposalDetails: QuoteProposalDetails
  ): number {
    let allFreightRoutes = this.getFreightRoutes(quoteProposalDetails);
    let cheapest: { index: number; value: number } = {
      index: -1,
      value: Number.MAX_VALUE
    };
    for (let i = 0; i < allFreightRoutes.length; i++) {
      const chargeBeforeConversion: number = this.calculateForSingleFreightRouteTotalCharge(
        quote,
        allFreightRoutes[i],
        quoteProposalDetails
      ).amount;

      const chargeAfterConversion: number = this.normalizeCurrencyToEgyptRate(
        chargeBeforeConversion,
        allFreightRoutes[i].currencyCode,
        allCurrencies
      );

      if (chargeAfterConversion == cheapest.value) {
        cheapest = {
          index: -1,
          value: chargeAfterConversion
        };
      } else if (chargeAfterConversion < cheapest.value) {
        cheapest = {
          index: i,
          value: chargeAfterConversion
        };
      }
    }

    return cheapest.index;
  }

  getArrivalDate(departureDate: Date, transit: number): Date {
    const arrivaleDate = new Date(departureDate);
    arrivaleDate.setDate(arrivaleDate.getDate() + transit);
    return arrivaleDate;
  }

  normalizeCurrencyToEgyptRate(
    chargeBeforeConversion: number,
    currency: string,
    allCurrencies: Currency[]
  ): number {
    const egpExchangeRate = allCurrencies.find(item => {
      return item.code == currency;
    })?.egpExchangeRate;
    return egpExchangeRate * chargeBeforeConversion;
  }

  isApprovedQuoteStatus(quote: QuoteBase): boolean {
    return quote?.status?.name == QuoteStatus.Approved;
  }

  getApprovedDate(quote: QuoteBase): Date {
    return quote?.proposalApprovalDetails.submittedAt;
  }

  getSelectedQuotePropsalDetails(
    quote: QuoteBase,
    quoteProposalDetailsIndex: number,
    isSubmittedNewVersion: boolean,
    isOlderProposalVersionsView: boolean
  ) {
    if (
      isOlderProposalVersionsView &&
      quoteProposalDetailsIndex < quote?.olderVersionsProposalDetails?.length
    ) {
      return quote?.olderVersionsProposalDetails[quoteProposalDetailsIndex];
    } else {
      return this.getLatestQuotePropsalSubmitted(quote, isSubmittedNewVersion);
    }
  }

  isFreightRouteSelected(quoteProposalDetails: QuoteProposalDetails): boolean {
    return quoteProposalDetails?.quoteRates?.freightRoutes?.some(
      f => f.isSelected
    );
  }

  isSelectedFreightOrClearanceOnly(services: QuoteServices): boolean {
    return (
      (this.isSelectedFreightService(services) ||
        this.isSelectedCustomsClearanceService(services)) &&
      !this.isSelectedEORService(services) &&
      !this.isSelectedIORService(services) &&
      !this.isSelectedTruckingService(services)
    );
  }

  private selectedCountrySubject = new Subject<void>();
  selectedCountry$ = this.selectedCountrySubject.asObservable();
  updateSelectedCountry(): void {
    this.selectedCountrySubject.next();
  }

  isSelectTruckingAndImportOrExportServicesOnly(quote: QuoteBase): boolean {
    return (
      this.isSelectedTruckingService(quote?.quoteServices) &&
      this.isSelectedImportExportService(quote?.quoteServices) &&
      !this.isSelectedCustomsClearanceService(quote?.quoteServices) &&
      !this.isSelectedFreightService(quote?.quoteServices)
    );
  }

  getQuotePort(quote: QuoteBase, isOrigin: boolean): PortDto {
    let port = {
      id: isOrigin
        ? quote?.routingDetails?.pickupLocationDetails?.portId
        : quote?.routingDetails?.deliveryLocationDetails?.portId,
      name: isOrigin
        ? quote?.routingDetails?.pickupLocationDetails?.portName
        : quote?.routingDetails?.deliveryLocationDetails?.portName,
      code: isOrigin
        ? quote?.routingDetails?.pickupLocationDetails?.portCode
        : quote?.routingDetails?.deliveryLocationDetails?.portCode
    } as PortDto;
    return port;
  }

  getQuoteRatePort(quote: QuoteBase): PortDto {
    let port = {
      id: quote?.quoteProposalDetails?.quoteRates?.portId,
      name: quote?.quoteProposalDetails?.quoteRates?.portName,
      code: quote?.quoteProposalDetails?.quoteRates?.portCode
    } as PortDto;
    return port;
  }

  getQuoteRateDistrict(quote: QuoteBase): District {
    let district = {
      id: quote?.quoteProposalDetails?.quoteRates?.districtId,
      name: quote?.quoteProposalDetails?.quoteRates?.districtName
    } as District;
    return district;
  }

  getDomesticTruckingInformation(
    quote: QuoteBase
  ): DomesticTruckingInformation {
    return quote?.cargoDetails?.domesticTruckingInformation;
  }

  getDomesticTruckingAddressPickup(
    quote: QuoteBase
  ): QuoteDomesticTruckingAddress {
    return quote?.routingDetails?.pickupLocationDetails
      ?.domesticTruckingAddress;
  }

  getDomesticTruckingAddressDelivery(
    quote: QuoteBase
  ): QuoteDomesticTruckingAddress {
    return quote?.routingDetails?.deliveryLocationDetails
      ?.domesticTruckingAddress;
  }

  getRouteInformation(quote: QuoteBase): RouteInformation {
    let routeInformation: RouteInformation =
      quote.routingDetails.freightReference == ShipmentFreightType.Land
        ? {
            pickupCity: quote.routingDetails.pickupLocationDetails?.country,
            pickupDistrict: quote.routingDetails.pickupLocationDetails?.city,
            deliveryCity:
              quote.routingDetails?.deliveryLocationDetails?.country,
            deliveryDistrict:
              quote.routingDetails?.deliveryLocationDetails?.city
          }
        : {
            pickupCity:
              quote.routingDetails.pickupLocationDetails
                ?.domesticTruckingAddress?.cityName,
            pickupDistrict:
              quote.routingDetails.pickupLocationDetails
                ?.domesticTruckingAddress?.districtName,
            deliveryCity:
              quote.routingDetails.deliveryLocationDetails
                ?.domesticTruckingAddress?.cityName,
            deliveryDistrict:
              quote.routingDetails.deliveryLocationDetails
                ?.domesticTruckingAddress?.districtName
          };
    return routeInformation;
  }

  getSubmittedAt(quote: QuoteBase): Date {
    return quote?.submittedDatetime;
  }

  getPickupDate(quote: QuoteBase): Date {
    return quote?.routingDetails?.pickupLocationDetails?.date;
  }

  getDeliveryDate(quote: QuoteBase): Date {
    return quote?.routingDetails?.deliveryLocationDetails?.date;
  }

  getIssuedDate(quote: QuoteBase): Date {
    return quote?.quoteProposalDetails?.proposalSubmissionDetails?.submittedAt;
  }

  getApprovalDate(quote: QuoteBase): Date {
    return quote?.proposalApprovalDetails?.submittedAt;
  }

  getRejectionDate(quote: QuoteBase): Date {
    return quote?.quoteRejectionDetails?.rejectedAt;
  }

  getStatusColor(quote: QuoteBase): string {
    return quote?.status?.colorCode;
  }

  getOriginCountry(quote: QuoteBase): string {
    return quote?.routingDetails?.pickupLocationDetails?.country;
  }

  getOriginCity(quote: QuoteBase): string {
    return quote?.routingDetails?.pickupLocationDetails?.city;
  }

  getDestinationCountry(quote: QuoteBase): string {
    return quote?.routingDetails?.deliveryLocationDetails?.country;
  }

  getDestinationCity(quote: QuoteBase): string {
    return quote?.routingDetails?.deliveryLocationDetails?.city;
  }

  getFullQuoteNumber(quote: QuoteBase): string {
    return quote?.quoteNumber;
  }

  getLandTrucking(quote: QuoteBase): LandTrucking {
    return quote?.cargoDetails?.landTrucking;
  }

  isExpressFreight(routingDetails: QuoteRoutingDetails): boolean {
    return routingDetails?.isExpress;
  }

  isExpressAirFreight(quote: QuoteBase): boolean {
    return (
      this.isAirService(quote?.quoteServices) &&
      this.isExpressFreight(quote?.routingDetails)
    );
  }

  clearEmployeeSelectedQuoteView() {
    this.localStorage.deleteData('selectedQuoteListView');
  }

  getQuoteLandTruckingDetails(quote: QuoteBase): LandTrucking {
    return quote?.cargoDetails?.landTrucking;
  }

  getEmployeeSelectedColumns(): QuoteViewColumn[] {
    return this.localStorage.getData('quoteSelectedColumns');
  }

  setEmployeeSelectedColumns(selectedColumns: QuoteViewColumn[]) {
    this.localStorage.saveSyncedSessionData(
      selectedColumns,
      'quoteSelectedColumns'
    );
  }

  clearEmployeeSelectedColumns() {
    this.localStorage.deleteData('quoteSelectedColumns');
  }

  getQuoteCustomerName(quote: QuoteBase): string {
    return quote?.createdBy?.DefaultCustomerCompanyName;
  }

  addToSelectedQuoteList(quoteId: string) {
    this.selectedQuoteIds.push(quoteId);
    this.selectedQuotesChanged.emit(this.selectedQuoteIds);
  }

  removeFromSelectedQuotesList(quoteId: string) {
    this.selectedQuoteIds = this.selectedQuoteIds?.filter(item => {
      return item != quoteId;
    });
    this.selectedQuotesChanged.emit(this.selectedQuoteIds);
  }
  isQuoteInSelectedList(id: string): boolean {
    let searchIndex = this.selectedQuoteIds.indexOf(id);
    return searchIndex != -1;
  }
  removeAllFromSelectedQuotesList(): void {
    this.selectedQuoteIds = [];
    this.selectedQuotesChanged.emit(this.selectedQuoteIds);
  }
}
