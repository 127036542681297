import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CompanyFreePlService } from '../models/company-free-pl-service.model';
import { Company } from '../models/company.model';
import { Employee } from '../models/employee.model';
import { Document } from 'src/app/shared/models/document.model';

import { Route } from '../models/route.model';
import { CustomsClearanceRate } from '../models/customs-clearance-rate.model';
import { CompanyRatesDto } from '../../shared/models/company-rates-dto.model';
import { CommissionTieredRate } from '../models/commission-tiered-rate.model';
import { CompanyLeadTime } from '../models/company-lead-time.model';
import { UpdateCompanyLeadTimeRequest } from '../models/update-company-lead-time-request.model';
import { ServiceBundle } from '../models/service-bundle.model';
import { FreePLEmployee } from 'src/app/shared/models/freePL-employee.model';
import { CompanyIdAndName } from 'src/app/shared/models/company-id-and-name.model';
import { CustomerCompanyDetails } from 'src/app/shared/models/customer-company-details.model';
import { QuoteBase } from 'src/app/shared/quote/models/quote-base.model';
import { CompanyProductLeadTime } from 'src/app/product-registration/models/company-product-lead-time.model';
import { CompanyProductLeadTimeViewModel } from 'src/app/company/models/company-product-lead-time-view-model.model';
import { EmployeeDetails } from '../models/employee-details.model';
import { Paginated } from 'src/app/shared/models/shipment/paginated.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  @Output() customerCompanySwitched: EventEmitter<{}> = new EventEmitter<{}>();

  @Output() companyUpdatedEmitter: EventEmitter<{
    updatedCompany: Company;
  }> = new EventEmitter<{ updatedCompany: Company }>();

  @Output() employeeAddedOrUpdatedEmitter: EventEmitter<{
    employee: Employee;
    isNew: boolean;
  }> = new EventEmitter<{ employee: Employee; isNew: boolean }>();

  baseURL = environment.apiUrl + 'companies';
  egyptId: number;
  constructor(private http: HttpClient) {}

  getCompanies(): Observable<any> {
    return this.http.get<Company[]>(this.baseURL);
  }

  getFreePlCompanies(): Observable<Company[]> {
    return this.http.get<Company[]>(this.baseURL + '/FreePLCompany');
  }

  getCompany(companyId: number): Observable<any> {
    return this.http.get<Company>(this.baseURL + `/${companyId}`);
  }

  addNewCompany(company: Company): Observable<any> {
    return this.http.post<Company>(this.baseURL, company);
  }

  updateCompany(company: Company): Observable<any> {
    return this.http.put<Company>(this.baseURL + `/${company.id}`, company);
  }

  deleteCompany(companyId: number): Observable<any> {
    return this.http.delete<any>(this.baseURL + `/${companyId}`);
  }

  changeCompanyStatus(companyId: number, isActive: boolean): Observable<any> {
    return this.http.put<any>(
      this.baseURL + `/${companyId}/Activate/${isActive}`,
      {}
    );
  }

  getAccountManagers(companyId: number): Observable<any> {
    return this.http.get<Employee[]>(
      this.baseURL + `/${companyId}/AccountManagers`
    );
  }

  getEmployees(companyId: number): Observable<any> {
    return this.http.get<Employee[]>(this.baseURL + `/${companyId}/Employee`);
  }

  getEmployee(
    employeeId: number,
    isInMyprofile: boolean
  ): Observable<EmployeeDetails> {
    return this.http.get<EmployeeDetails>(
      this.baseURL + `/Employee/${employeeId}?isInMyprofile=${isInMyprofile}`
    );
  }

  addNewEmployee(companyId: number, employee: Employee): Observable<any> {
    return this.http.post<any>(
      this.baseURL + `/${companyId}/Employee`,
      employee
    );
  }

  updateEmployee(employee: Employee): Observable<any> {
    return this.http.put<any>(
      this.baseURL + `/${employee.companyId}/Employee/${employee.id}`,
      employee
    );
  }

  updateEmployeeProfile(employee: Employee): Observable<any> {
    return this.http.put<any>(
      this.baseURL + `/${employee.companyId}/Employee/${employee.id}/Profile`,
      employee
    );
  }

  changeEmployeeStatus(employeeId: number, isActive: boolean): Observable<any> {
    return this.http.put<any>(
      this.baseURL + `/Employee/${employeeId}/Activate/${isActive}`,
      {}
    );
  }

  addNewService(
    companyId: number,
    service: CompanyFreePlService
  ): Observable<any> {
    return this.http.post<CompanyFreePlService>(
      this.baseURL + `/${companyId}/FreePlService`,
      service
    );
  }

  updateService(service: CompanyFreePlService): Observable<any> {
    return this.http.put<CompanyFreePlService>(
      this.baseURL +
        `/${service.companyId}/FreePlService/${service.freePlServiceId}`,
      service
    );
  }

  getCompanyFreePlService(id: number, companyId: number): Observable<any> {
    return this.http.get<Employee>(
      this.baseURL + `/${companyId}/FreePlService/${id}`
    );
  }

  deleteAccountManager(
    companyId: number,
    accountManagerId: number
  ): Observable<any> {
    return this.http.delete<any>(
      this.baseURL + `/${companyId}/AccountManager/${accountManagerId}`
    );
  }

  getCustomerCompaniesForAccountManager(employeeId: string): Observable<any> {
    return this.http.get<any>(
      this.baseURL + `/FreePLEmployeeCustomerCompanies/${employeeId}`
    );
  }

  setDefaultCustomerCompanyForFreePLEmployee(
    employeeId: string,
    companyId: string
  ): Observable<any> {
    return this.http.put<any>(
      this.baseURL +
        `/${companyId}/FreePLEmployee/${employeeId}/DefaultCustomerCompany`,
      {}
    );
  }

  addAccountManager(
    companyId: number,
    accountManagerId: number,
    isPrimary: boolean
  ): Observable<any> {
    return this.http.post<any>(
      this.baseURL +
        `/${companyId}/AccountManager/${accountManagerId}?isPrimary=${isPrimary}`,
      {}
    );
  }

  togglePrimaryAccountManager(
    companyId: number,
    accountManagerId: number,
    isPrimary: boolean
  ): Observable<any> {
    return this.http.post<any>(
      this.baseURL +
        `/${companyId}/AccountManager/${accountManagerId}/TogglePrimary?isPrimary=${isPrimary}`,
      {}
    );
  }

  getDocuments(companyId: number): Observable<any> {
    return this.http.get<Document[]>(this.baseURL + `/${companyId}/Documents`);
  }

  getCompanyServices(companyId: number): Observable<any> {
    return this.http.get<CompanyFreePlService[]>(
      this.baseURL + `/${companyId}/Services`
    );
  }

  getRoutes(companyId: number): Observable<Route[]> {
    return this.http.get<Route[]>(this.baseURL + `/${companyId}/Routes`);
  }

  getRoute(companyId: number, routeId: number): Observable<Route> {
    return this.http.get<Route>(
      this.baseURL + `/${companyId}/Route/${routeId}`
    );
  }

  addRoute(companyId: number, route: Route): Observable<Route> {
    return this.http.post<Route>(this.baseURL + `/${companyId}/Route`, route);
  }

  updateRoute(
    companyId: number,
    routeId: number,
    route: Route
  ): Observable<Route> {
    return this.http.put<Route>(
      this.baseURL + `/${companyId}/Route/${routeId}`,
      route
    );
  }

  getCompanyRates(
    companyId: number,
    quoteId?: string
  ): Observable<{ contracts: CompanyRatesDto; quote: QuoteBase }> {
    let url = this.baseURL + `/${companyId}/LightContract`;

    if (quoteId) {
      url += `?quoteId=${quoteId}`;
    }

    return this.http.get<{ contracts: CompanyRatesDto; quote: QuoteBase }>(url);
  }

  getEgyptId(): Observable<number> {
    return this.http.get<number>(environment.apiUrl + 'Companies/EgyptId');
  }

  getCustomsClearanceRates(id: number): Observable<CustomsClearanceRate[]> {
    return this.http.get<CustomsClearanceRate[]>(
      environment.apiUrl + `CustomsClearanceRate/${id}`
    );
  }
  getCompanyCustomsClearanceRates(
    companyId: number
  ): Observable<CustomsClearanceRate[]> {
    return this.http.get<CustomsClearanceRate[]>(
      environment.apiUrl + `CustomsClearanceRate/company/${companyId}`
    );
  }

  getCustomsClearanceRate(
    customsClearanceRateId: number
  ): Observable<CustomsClearanceRate> {
    return this.http.get<CustomsClearanceRate>(
      environment.apiUrl + `CustomsClearanceRate/${customsClearanceRateId}`
    );
  }

  addCustomsClearanceRate(
    customsClearanceRate: CustomsClearanceRate
  ): Observable<CustomsClearanceRate> {
    return this.http.post<CustomsClearanceRate>(
      environment.apiUrl + `CustomsClearanceRate`,
      customsClearanceRate
    );
  }

  updateCustomsClearanceRate(
    customsClearanceRateId: number,
    customsClearanceRate: CustomsClearanceRate
  ): Observable<CustomsClearanceRate> {
    return this.http.put<CustomsClearanceRate>(
      environment.apiUrl + `CustomsClearanceRate/${customsClearanceRateId}`,
      customsClearanceRate
    );
  }

  deleteCustomsClearanceRate(customsClearanceRateId: number): Observable<any> {
    return this.http.delete<CustomsClearanceRate>(
      environment.apiUrl + `CustomsClearanceRate/${customsClearanceRateId}`
    );
  }

  getCommissionTieredRate(
    companyId: number,
    service: string
  ): Observable<CommissionTieredRate> {
    return this.http.get<CommissionTieredRate>(
      this.baseURL + `/${companyId}/CommissionTieredRate/${service}`
    );
  }
  addOrEditCommissionTieredRate(
    companyId: number,
    service: string,
    rate: any
  ): Observable<CommissionTieredRate> {
    return this.http.post<CommissionTieredRate>(
      this.baseURL + `/${companyId}/CommissionTieredRate/${service}`,
      rate
    );
  }
  getLightCustomsClearanceRates(
    companyId: number
  ): Observable<CustomsClearanceRate[]> {
    return this.http.get<CustomsClearanceRate[]>(
      this.baseURL + `/${companyId}/CustomsClearanceRates`
    );
  }

  getLeadTimes(companyId: number): Observable<CompanyLeadTime[]> {
    return this.http.get<CompanyLeadTime[]>(
      this.baseURL + `/${companyId}/LeadTime`
    );
  }

  getLeadTime(companyId, id): Observable<any> {
    return this.http.get<CompanyLeadTime>(
      this.baseURL + `/${companyId}/LeadTime/${id}`
    );
  }

  updateLeadTime(
    companyId,
    id,
    request: UpdateCompanyLeadTimeRequest
  ): Observable<any> {
    return this.http.put<UpdateCompanyLeadTimeRequest>(
      this.baseURL + `/${companyId}/LeadTime/${id}`,
      request
    );
  }

  getServiceBundle(companyId: number): Observable<ServiceBundle> {
    return this.http.get<ServiceBundle>(
      this.baseURL + `/${companyId}/ServiceBundle`
    );
  }
  addOrEditServiceBundle(
    companyId: number,
    bundle: any
  ): Observable<ServiceBundle> {
    return this.http.post<ServiceBundle>(
      this.baseURL + `/${companyId}/ServiceBundle`,
      bundle
    );
  }

  getFreePLEmployeeCustomerCompanies(
    employeeId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<any> {
    return this.http.get<any>(
      this.baseURL +
        `/FreePLEmployeeCustomerCompanies/${employeeId}?pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
  }

  getAccountManagersById(
    accountManagersIds?: number[]
  ): Observable<FreePLEmployee[]> {
    let requestUrl = `/AccountManager`;

    for (let index = 0; index < accountManagersIds?.length; index++) {
      if (index == 0) {
        requestUrl += `?accountManagersIds=`;
      } else {
        requestUrl += `&accountManagersIds=`;
      }

      requestUrl += `${accountManagersIds[index]}`;
    }

    return this.http.get<FreePLEmployee[]>(this.baseURL + requestUrl);
  }

  getCustomersById(customersIds?: number[]): Observable<CompanyIdAndName[]> {
    let requestUrl = `/Customer`;

    for (let index = 0; index < customersIds?.length; index++) {
      if (index == 0) {
        requestUrl += `?customersIds=`;
      } else {
        requestUrl += `&customersIds=`;
      }

      requestUrl += `${customersIds[index]}`;
    }

    return this.http.get<CompanyIdAndName[]>(this.baseURL + requestUrl);
  }

  geShipmentSubmittersById(
    shipmentSubmittersIds?: number[]
  ): Observable<FreePLEmployee[]> {
    let requestUrl = `/ShipmentSubmitter`;

    for (let index = 0; index < shipmentSubmittersIds?.length; index++) {
      if (index == 0) {
        requestUrl += `?shipmentSubmittersIds=`;
      } else {
        requestUrl += `&shipmentSubmittersIds=`;
      }

      requestUrl += `${shipmentSubmittersIds[index]}`;
    }

    return this.http.get<FreePLEmployee[]>(this.baseURL + requestUrl);
  }

  getCustomerCompanyDetails(id: number): Observable<CustomerCompanyDetails> {
    return this.http.get<CustomerCompanyDetails>(
      this.baseURL + `/customerCompanyDetails/${id}`
    );
  }

  getCompanyProductLeadTimes(id: number): Observable<CompanyProductLeadTime[]> {
    return this.http.get<CompanyProductLeadTime[]>(
      this.baseURL + `/${id}/CompanyProductRegistrationLeadTime`
    );
  }

  getCompanyProductLeadTimeById(
    companyId: number,
    id: number
  ): Observable<CompanyProductLeadTime> {
    return this.http.get<CompanyProductLeadTime>(
      this.baseURL + `/${companyId}/CompanyProductRegistrationLeadTime/${id}`
    );
  }

  updateCompanyProductLeadTime(
    companyId: number,
    productLeadTimeId: number,
    companyProductLeadTimeViewModel: CompanyProductLeadTimeViewModel
  ): Observable<CompanyProductLeadTime> {
    return this.http.put<CompanyProductLeadTime>(
      this.baseURL +
        `/${companyId}/CompanyProductRegistrationLeadTime/Edit/${productLeadTimeId}`,
      companyProductLeadTimeViewModel
    );
  }

  getCompanyDetails(companyId: number): Observable<Company> {
    return this.http.get<Company>(
      this.baseURL + `/CompanyDetails/${companyId}`
    );
  }

  getFreePlCompaniesPaginated(
    pageIndex: number = 0,
    pageSize: number = 6
  ): Observable<Paginated> {
    return this.http.get<Paginated>(
      this.baseURL +
        `/FreePLCompaniesPaginated?pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
  }

  getCompaniesByName(companyName: string): Observable<Company[]> {
    return this.http.get<Company[]>(
      this.baseURL + `CompaniesByName/${companyName}`
    );
  }
}
