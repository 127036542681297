<div class="section-container">
  <div class="product-information-container">
    <div class="title-section">
      <div class="title-container">
        <svg-icon name="shipmentProducts"></svg-icon>
        <span class="title">PRODUCT DETAILS</span>
      </div>
      <button
        class="edit-section-button"
        (click)="handleProductInformationRouting()"
      >
        <svg-icon name="editQuote"></svg-icon>
        Edit
      </button>
    </div>
    <app-product-information-summary
      [product]="product"
    ></app-product-information-summary>
  </div>

  <div class="compliance-details-container">
    <div class="title-section">
      <div class="title-container">
        <svg-icon name="shipmentProducts"></svg-icon>
        <span class="title">COMPLIANCE DETAILS</span>
      </div>
      <button
        class="edit-section-button"
        (click)="handleComplianceInformationRouting()"
      >
        <svg-icon name="editQuote"></svg-icon>
        Edit
      </button>
    </div>
    <app-product-compliance-details-summary
      [product]="product"
    ></app-product-compliance-details-summary>
  </div>

  <div class="compliance-details-container" *ngIf="isEditRoute">
    <div class="title-section">
      <div class="title-container">
        <svg-icon name="shipmentProducts"></svg-icon>
        <span class="title">Approvals</span>
      </div>
    </div>
    <app-product-details-approvals-summary
      [productDetailsApprovelList]="productReference?.productDetailsApproval"
    ></app-product-details-approvals-summary>
  </div>

  <div class="compliance-details-container" *ngIf="isEditRoute">
    <div class="title-section">
      <div class="title-container">
        <svg-icon name="shipmentProducts"></svg-icon>
        <span class="title">Product Registration </span>
      </div>
    </div>
    <app-product-details-registration-summary
      [productDetailsProductRegistrationList]="
        productReference?.productDetailsProductRegistration
      "
    ></app-product-details-registration-summary>
  </div>
</div>
