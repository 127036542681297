<div class="summary-container">
  <div class="container">
    <ng-container
      *ngIf="
        productDetailsApprovelList && productDetailsApprovelList.length > 0;
        else noData
      "
    >
      <div
        *ngFor="
          let approvalDetails of productDetailsApprovelList;
          let i = index
        "
      >
        <div class="row">
          <div class="section-title adapt-width col-3">
            <div
              class="cursor-pointer"
              (click)="onApprovalNumberClick(approvalDetails.id)"
            >
              {{ approvalDetails?.freePLApprovalNumberString }}
            </div>
          </div>
          <div class="product-data adapt-width col-3">
            <div class="product-title">Approval Type</div>
            <div class="product-content">
              {{ approvalDetails?.approvalType?.name }}
            </div>
          </div>
          <div
            class="product-data adapt-width col-3"
            *ngIf="approvalDetails?.invoiceDocument"
          >
            <div class="product-title">Approval Document</div>
            <div
              class="cursor-pointer section-title truncated-text"
              (click)="viewFile(approvalDetails?.invoiceDocument?.path)"
              [ngbPopover]="infoTemplate"
              [autoClose]="'outside'"
              triggers="mouseenter:mouseleave"
            >
              <span>{{ approvalDetails?.invoiceDocument?.fileName }}</span>

              <ng-template #infoTemplate>
                <div>
                  {{ approvalDetails?.invoiceDocument?.fileName }}
                </div>
              </ng-template>
            </div>
          </div>
          <div class="product-data adapt-width col-3">
            <div class="product-title">Valid Until</div>
            <div class="product-content">
              {{
                isDocumentExpired(approvalDetails?.invoiceDocument?.validToDate)
                  ? "Expired"
                  : (approvalDetails?.invoiceDocument?.validToDate
                    | date: "dd/MM/yyyy")
              }}
            </div>
          </div>
        </div>
        <div
          class="divider"
          *ngIf="i < productDetailsApprovelList.length - 1"
        ></div>
      </div>
    </ng-container>
    <ng-template #noData>
      <div class="no-data-message">
        Approvals containing this product will be displayed here.
      </div>
    </ng-template>
  </div>
</div>
