import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProductBase } from '../../shared/models/product-library/product-base.model';
import { Observable } from 'rxjs';
import { ProductDetailsUpdate } from 'src/app/create-product/models/product-details-update.model';
import { ProductUpdateDocumentNote } from 'src/app/shared/models/product-update-document-notes.model';
import { ProductLookup } from '../models/product-lookup.model';
import { ProductWithSimilarDocumentType } from 'src/app/shared/models/product-library/product-with-similar-document-type.model';

@Injectable({
  providedIn: 'root'
})
export class CreateProductService {
  baseUrl = environment.apiUrl + 'Product';
  product: ProductBase;

  constructor(private http: HttpClient) {}

  getProductById(id: string): Observable<ProductBase> {
    return this.http.get<ProductBase>(this.baseUrl + `/${id}`);
  }

  updateProductDetails(
    companyId: string,
    productDetailsUpdate: ProductDetailsUpdate
  ): Observable<ProductBase> {
    return this.http.post<ProductBase>(
      this.baseUrl + `/${companyId}`,
      productDetailsUpdate
    );
  }

  updateProductComplianceDetails(
    companyId: string,
    productDetailsUpdate: ProductDetailsUpdate
  ): Observable<ProductBase> {
    return this.http.post<ProductBase>(
      this.baseUrl + `/${companyId}/ComplianceInformation`,
      productDetailsUpdate
    );
  }

  submitProduct(productId: string): Observable<ProductBase> {
    return this.http.put<ProductBase>(
      this.baseUrl + `/${productId}/ProductSubmission`,
      null
    );
  }
  updateProductDocumentNote(
    productId: string,
    notes: ProductUpdateDocumentNote
  ): Observable<boolean> {
    return this.http.put<boolean>(`${this.baseUrl}/${productId}/Note`, notes);
  }

  deleteDocumet(productId: string, documentSqlId: number) {
    return this.http.delete<any>(
      `${this.baseUrl}/${productId}/Document/${documentSqlId}`
    );
  }

  getProductLookups(companyId: number): Observable<ProductLookup> {
    return this.http.get<ProductLookup>(
      `${this.baseUrl}/lookup/company/${companyId}`
    );
  }

  getProductsWithRecentlyUploadedDocumentType(
    documentType: string
  ): Observable<ProductWithSimilarDocumentType[]> {
    return this.http.get<ProductWithSimilarDocumentType[]>(
      `${this.baseUrl}/ProductWithRecentlyDocumentType/${documentType}`
    );
  }

  getProductsWithDocumentTypeAndPartNumber(
    documentType: string,
    partNumber?: string
  ): Observable<ProductWithSimilarDocumentType[]> {
    return this.http.get<ProductWithSimilarDocumentType[]>(
      `${this.baseUrl}/ProductWithDocumentType/${documentType}?partNumber=${partNumber}`
    );
  }
}
